import { filter } from 'rxjs';
import { AllNotificationsModel } from './../../../../../../usorta-library/src/lib/models/NotificationModels/allNotifications';
import { NotificationItem } from 'projects/usorta-library/src/lib/models/NotificationModels/notificationItem';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.css']
})
export class AllNotificationsComponent implements OnInit {

  constructor(private notificationService:NotificationService) { }
  notifications:AllNotificationsModel;
  urgentNotifications:NotificationItem[];
  otherNotifications:NotificationItem[];
  pages:number[] = [];
  ngOnInit(): void {
    this.getNotifications(1);
  }
  getNotifications(page=1){
    this.notificationService.getAllNotifications(page).subscribe(response=>{
      this.notifications = response;
      this.getPages();
      this.getUrgentNotifications();
      this.getOtherNotifications();
    })
  }
  getUrgentNotifications(){
    this.urgentNotifications = this.notifications.items.filter(x=>x.urgent == true);
  }
  getOtherNotifications(){
    this.otherNotifications = this.notifications.items.filter(x=>x.urgent == false);
  }
  getPages(){
    this.pages=[];
    for(let i=1;i<=this.notifications.pageCount;i++){
      this.pages.push(i);

      console.log(this.pages);
    }
  }
}
