import { Component, Input, OnInit } from '@angular/core';
import { CourseDocumentModel } from '../../../models/courseDocuments/courseDocumentModel';

@Component({
  selector: 'app-course-documents',
  templateUrl: './course-documents.component.html',
  styleUrls: ['./course-documents.component.css']
})
export class CourseDocumentsComponent implements OnInit {
  @Input() courseDocuments:CourseDocumentModel[];
  constructor() { }

  ngOnInit(): void {
  }
  routeLink(courseDocument:CourseDocumentModel){
    let downloadLink = courseDocument.fileUrl;
    if(downloadLink.startsWith('https://') || downloadLink.startsWith('http://')){

    }else{
      window.location.href = downloadLink;
    }
  }

}
