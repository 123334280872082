<div class="formMain form-default-border form-maroon-border">
    <div class="form-title-div">
        <font class="form-title-content">{{'vminstancetypes.createFormTitle' | translate}}</font>
    </div>
    <form *ngIf="ramList != null && cpuList != null" [formGroup]="createVmInstanceForm">
        <div class="mb-3">
            <label for="typeId" class="form-label">{{'vminstancetypes.typeId' | translate}}</label>
            <input formControlName="typeId" type="text" class="form-control" id="typeId">
        </div>
        <div class="mb-3">
            <label for="name" class="form-label">{{'vminstancetypes.name' | translate}}</label>
            <input formControlName="name" type="text" class="form-control" id="name">
        </div>
        <div class="mb-3">
            <label for="description" class="form-label">{{'vminstancetypes.description' | translate}}</label>
            <input formControlName="description" type="text" class="form-control" id="description">
        </div>
        <div class="mb-3">
            <label for="tags" class="form-label">{{'vminstancetypes.tags' |translate}}</label>
            <input formControlName="tags" type="text" class="form-control" id="tags">
        </div>
        <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="" id="active" formControlName="active">
            <label class="form-check-label" for="active">
              Active
            </label>
          </div>
        <div class="mb-3">
            <label for="ram" class="form-label">{{'vminstancetypes.ram' | translate}}</label>
            <select formControlName="ram" class="form-select" id="ram" aria-label="">
                <option *ngFor="let ram of ramList" [value]="ram.value">{{ram.label}}</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="cpuCount" class="form-label">{{'vminstancetypes.cpuCount' | translate}}</label>
            <select type="number" formControlName="cpuCount" class="form-select" id="cpuCount" aria-label="">

                <option *ngFor="let cpu of cpuList" [value]="cpu.value">{{cpu.label}}</option>

                </select>
        </div>
        <button class="btn btn-primary" (click)="createInstanceType()">{{'btn.add' | translate}}</button>
    </form>
</div>