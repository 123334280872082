<div class="coursesComponent">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <app-course-catalog-filter *ngIf="queryUrl" courseCatalogTitle="{{'allCourses' | translate}}" [queryUrl]="queryUrl" courseCatalogDescription="{{'allCoursesDescription' | translate}}"></app-course-catalog-filter>
        </div>
      </div>
    </div>
  </div>
</div>

