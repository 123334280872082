import { Router } from '@angular/router';
import { StudentOutlineModel } from './../../../models/outline/studentOutlineModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-landing-outline',
  templateUrl: './course-landing-outline.component.html',
  styleUrls: ['./course-landing-outline.component.css']
})
export class CourseLandingOutlineComponent implements OnInit {
  @Input() outlineModel?:StudentOutlineModel[] = undefined;
  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  route(outline:StudentOutlineModel){
    if(outline.sectionLink != undefined){
      if(outline.sectionLink.startsWith('http://') || outline.sectionLink.startsWith('https://')){
        window.location.href = outline.sectionLink;
      }else{
        this.router.navigate([outline.sectionLink]);
      }
    }
  }

}
