import { Injectable } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable } from 'rxjs';
import { TerminalHelpModel } from '../../models/SessionSocketModels/terminalHelpModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class SessionSocketService {
  private hubConnection:SignalR.HubConnection;
  session = ""
  constructor(private toastrService:ToastrService,private httpClient:HttpClient) { }
  public ConnectSocket(websocketUrl:string,group:string,mainRoom:boolean=false):Promise<boolean>{
    return new Promise((resolve,reject)=>{
      let token = "";
      if(sessionStorage.getItem("token")){
        token = sessionStorage.getItem("token") ?? "";
      }else if(localStorage.getItem("token")){
        token = localStorage.getItem("token") ?? "";
      }
      if(token != ""){
        this.hubConnection = new SignalR.HubConnectionBuilder().withUrl(websocketUrl,{ accessTokenFactory: () => token}).build()

        this.session = group;
        resolve(this.startSocket(mainRoom));
      }
    })
  }
  public getUserId(){
    this.hubConnection.invoke("GetUserId");
  }
  userIdListener = new Observable<string>((observer)=>{
    this.hubConnection.on("UserInfo.Id",(data:string)=>{
      observer.next(data);
    })
  })
  private startSocket(mainRoom:boolean=false):Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.hubConnection.start().then(()=>{
        try {
          setTimeout(()=>{
          this.hubConnection.invoke("JoinSession",this.session,mainRoom).then(()=>{
              this.toastrService.success("Oturum bağlantısı kuruldu");
              resolve(true);
            }).catch(()=>{
              this.toastrService.error("Oturum bağlantısı kurulurken bir sorun oluştu");
              resolve(false);
            });
          },250)
        }catch{
          this.toastrService.error("Oturum bağlantısı kurulurken bir sorun oluştu");
          resolve(false);
        }
      }).catch(()=>{
        this.toastrService.error("Oturum bağlantısı kurulurken bir sorun oluştu");
        resolve(false);
      })
    })

  }
  pollListener = new Observable((observer)=>{
    this.hubConnection.on("MiniSurvey.Poll",(data)=>{
      observer.next(data);
    })
  })
  createPoll(question:string){
    this.hubConnection.invoke("MiniSurveyPoll",this.session,question);
  }
  voteListener = new Observable<any>((observer)=>{
    this.hubConnection.on("MiniSurvey.Vote",(data:any)=>{
      observer.next(data);
    })
  })
  terminalAccessListener = new Observable<any>((observer)=>{
    this.hubConnection.on("Terminal.AccessChanged",(data)=>{
      observer.next(data);
    })
  })
  terminalJoinListener = new Observable<any>((observer)=>{
    this.hubConnection.on("Terminal.Shared.Join",(data)=>{
      observer.next(data);
    })
    this.hubConnection.on("Terminal.Join",(data)=>{
      observer.next(data);
    })
  })
  terminalDisconnectListener = new Observable<any>((observer)=>{
    this.hubConnection.on("Terminal.Shared.Disconnect",(data)=>{
      observer.next(data);
    })
    this.hubConnection.on("Terminal.Disconnect",(data)=>{
      observer.next(data);
    })
  })

  askForHelp(sessionId:string,terminalId:string){
    this.hubConnection.invoke("TerminalAskHelp",sessionId,terminalId);
  }
  getConnectionId():string {
    return this.hubConnection.connectionId ?? "";
  }
  userStatusChangeListener = new Observable<any>((observer)=>{
    this.hubConnection.on("Session.MemberStatusChange",(data)=>{
      observer.next(data);
    })
  })
  setUserState(sessionId:string,status:number) {
    this.hubConnection.invoke("MemberStatusChange",sessionId,status);
  }
  disconnect(){
    this.hubConnection.stop();
  }
  sessionStatusListener = new Observable<number>((observer)=>{
    this.hubConnection.on("Session.CurrentStatus",(data:number)=>{
      observer.next(data);
      console.log(data);
    })
  })
  askForHelpListener = new Observable<TerminalHelpModel>((observer)=>{
    this.hubConnection.on("Terminal.Session.AskForHelp",(data:TerminalHelpModel)=>{
      observer.next(data);
    })
  })
  helpReceivedNotificationListener = new Observable<any>((observer)=>{
    this.hubConnection.on("Terminal.Session.ReceivedHelpRequest",((data)=>{
      observer.next(data);
    }))
  })
  otherJoinInfo = new Observable<{connectionId:string,sessionId:string}>((observer)=>{
    this.hubConnection.on("Session.Joined",(connectionId,sessionId)=>{
      console.log("----------- other join -----")
      let data = {connectionId:connectionId,sessionId:sessionId};
      observer.next(data);
    })
  })
  terminalSessionSharedJoin(sessionId:string,userId:string,terminalId:string){

    this.hubConnection.invoke("SharedConnectionJoin",sessionId,userId,terminalId);
  }
  terminalSessionSharedDisconnect(sessionId:string,userId:string,terminalId:string){
    this.hubConnection.invoke("SharedConnectionDisconnect",sessionId,userId,terminalId);
  }

  changeTerminalAccess(userId:string,terminalId:string,access:boolean){
    this.hubConnection.invoke("TerminalManageAccess",userId,terminalId,access);
  }
  startPingSender(timeInterval:number=10){
    if(this.session != "" && this.session !== null){
      this.hubConnection.invoke("RTCPing",this.session);
    }
    setTimeout(()=>{
      this.startPingSender();
    },timeInterval*1000);
  }
  sessionConnectionListener = new Observable<any>((observer)=>{
    this.hubConnection.on("Session.JoinedRoom",(data)=>{
      observer.next(data);
    })
  })
  memberJoinListener = new Observable<any>((observer)=>{
    this.hubConnection.on("Session.MemberJoined",(data)=>{
      observer.next(data);
    })
  })
  memberLeftListener = new Observable<any>((observer)=>{
    this.hubConnection.on("Session.MemberLeft",(data)=>{
      observer.next(data);
    })
  })
  sendRaiseHand(raise:boolean){
    this.hubConnection.invoke("RTCRaiseHand",this.session,raise);
  }
  raiseHandListener = new Observable<any>((observer)=>{
    this.hubConnection.on("RTC.RaiseHand",(data:any)=>{
      observer.next(data);
    })
  })
  miniSurveyVoteYes(pollId:string){
    this.hubConnection.invoke("MiniSurveyVote",this.session,pollId,true);
  }
  miniSurveyVoteNo(pollId:string){
    this.hubConnection.invoke("MiniSurveyVote",this.session,pollId,false);
  }
  getHubConnection(){
    return this.hubConnection;
  }



}
