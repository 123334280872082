import { Component, Input, OnInit } from '@angular/core';
import { AnnouncementsModel } from '../../../models/announcements/announcementsModel';

@Component({
  selector: 'app-course-announcement-item',
  templateUrl: './course-announcement-item.component.html',
  styleUrls: ['./course-announcement-item.component.css']
})
export class CourseAnnouncementItemComponent implements OnInit {

  constructor() { }
  @Input() announcement:AnnouncementsModel
  @Input() lineLimit:boolean = true;
  ngOnInit(): void {
  }

}
