<div style="text-align: center; min-height: 99vh;">
  <div style="max-width: 600px; display: inline-block;">
  <lib-custom-block blockKey="content1"></lib-custom-block>
  </div><br/>
  <div style="display:inline-block; max-width:500px; margin-bottom: 30px; background-color: rgb(248, 248, 248); padding: 30px; box-shadow: 1px 1px 8px 1px #7d7d7d; border-radius: 10px;">
    <img *ngIf="tenantInfoModel" src="{{tenantInfoModel.logo}}" class="registerImage" style="width: 100%;">
    <div class="customForm">
      <lib-custom-form formId="register"></lib-custom-form>
    </div>

  </div>

</div>

