<!-- Senkron Eğitim -->
<lib-session-loader [startSocket]="startSocket" *ngIf="socketConnected == false || sessionStatus != 1"
  [error]="[socketConnectedError]" [status]="[sessionStatus]"></lib-session-loader>
<div [class.displayNone]="socketConnected==false || sessionStatus != 1" class="row learnMain" style="margin-bottom:100px"
  *ngIf="learnContent">
  <div class="outline col-2" id="outline" *ngIf="learnContent.courseOutline">
    <h5>Kurs Bölümleri</h5>
    <lib-course-outline [courseOutline]="learnContent.courseOutline"></lib-course-outline>
  </div>
  <div class="learnContent col-6" style="white-space: normal;" id="learnContent">
    <div class="courseTitle">
      <h5>{{learnContent.courseInfo.title}}</h5>
    </div>
    <ul class="nav nav-tabs" style="margin-bottom:5px;">
      <li class="nav-item">
        <a class="nav-link" [class.active]="selectedTool(0)" (click)="switchLearnTool(0)">{{'tools.courseContent'
          |translate}} <i *ngIf="selectedLearnTool == 0 && mainPageWindow == courseContentWindow" (click)="popOut(selectedLearnTool)"
            class="bi bi-window-plus popOutIcon"></i></a>
      </li>
      <li class="nav-item" *ngIf="learnContent.courseInfo.features.whiteboard.enabled">
        <a class="nav-link" [class.active]="selectedTool(1)" (click)="switchLearnTool(1)">{{'tools.whiteboard'
          |translate}} <i *ngIf="selectedLearnTool == 1 && mainPageWindow == whiteboardWindow" (click)="popOut(selectedLearnTool)"
            class="bi bi-window-plus popOutIcon"></i></a>
      </li>
      <li class="nav-item" *ngIf="learnContent.courseInfo.features.textchat.enabled">
        <a class="nav-link" [class.active]="selectedTool(2)" (click)="switchLearnTool(2)">{{'tools.chat' |translate}} <i
            *ngIf="newMessageReceived" class="bi bi-dot messageNotificationDot"
            style="font-size:30px; position:relative;"></i> <i *ngIf="selectedLearnTool == 2 && chatWindow[0] == mainPageWindow"
            (click)="popOut(selectedLearnTool)" class="bi bi-window-plus popOutIcon"></i> </a>
      </li>
      <li class="nav-item" *ngIf="learnContent.courseInfo.features.notes.enabled">
        <a class="nav-link" [class.active]="selectedTool(3)" (click)="switchLearnTool(3)">{{'tools.notebook'
          |translate}} <i *ngIf="selectedLearnTool == 3 && mainPageWindow == noteWindow" (click)="popOut(selectedLearnTool)"
            class="bi bi-window-plus popOutIcon"></i></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="selectedTool(4)" (click)="switchLearnTool(4)">{{'tools.fileAttachments'
          |translate}} <i *ngIf="selectedLearnTool == 4 && mainPageWindow == fileAttachmentWindow" (click)="popOut(selectedLearnTool)"
            class="bi bi-window-plus popOutIcon"></i></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="selectedTool(5)" (click)="switchLearnTool(5)">{{'tools.stream' |translate}}
          <i *ngIf="selectedLearnTool == 5 && mainPageWindow == streamWindow" (click)="popOut(selectedLearnTool)"
            class="bi bi-window-plus popOutIcon"></i></a>
      </li>
      <form class="d-flex">
        <a class="nav-link" *ngIf="fullscreen == false" (click)="requestFullscreen()"><i class="bi bi-fullscreen"
            title="İçeriği tam ekrana al"></i></a>
        <a class="nav-link" *ngIf="fullscreen == true" (click)="closeFullscreen()"><i
            class="bi bi-fullscreen-exit"></i></a>
      </form>
    </ul>
    <div class="popOutMain">
    </div>
    <div *ngIf="learnContent.currentContent" [hidden]="selectedTool(0) == false"
      class="courseContent courseContentTextMain contentDiv_0">
      <lib-popout-message *ngIf="courseContentWindow != mainPageWindow" [mainWindow]="mainPageWindow"
        [popOutWindow]="courseContentWindow"></lib-popout-message>
      <div class="content_0">
        <div class="content courseContentText" *ngFor="let contentScheme of learnContent.currentContent.content">
          <div *ngIf="contentScheme.format == 0" class="plainText" [innerText]="contentScheme.content"></div>
          <div *ngIf="contentScheme.format == 1" class="html" [innerHTML]="contentScheme.content"></div>
          <markdown *ngIf="contentScheme.format == 2" [data]="contentScheme.content" mermaid katex></markdown>
        </div>
      </div>
    </div>
    <div *ngIf="learnContent.courseInfo.features.whiteboard.enabled" [hidden]="selectedTool(1) == false"
      class="courseContent whiteboardMainDiv contentDiv_1">
      <lib-popout-message *ngIf="whiteboardWindow != mainPageWindow" [mainWindow]="mainPageWindow"
        [popOutWindow]="whiteboardWindow"></lib-popout-message>
      <div *ngIf="false" class="whiteboardContent">

      </div>
      <div class="whiteboardModule content_1" style="overflow-x: scroll; white-space: nowrap;">
        <lib-canvas [imageMainPath]="'/uploads/'" [imageUploadEndpoint]="'/api/v2/whiteboard/draw/addimage'"
          [requestedRoom]="learnContent.courseInfo.features.whiteboard.room"
          [apiUrl]="learnContent.courseInfo.features.whiteboard.apiUrl"
          [websocketURL]="learnContent.courseInfo.features.whiteboard.websocketUrl"></lib-canvas>
      </div>
    </div>
    <div *ngIf="learnContent.courseInfo.features.textchat.enabled" [hidden]="selectedTool(2) == false"
      class="courseContent contentDiv_2">
      <lib-popout-message *ngIf="chatWindow[0] != mainPageWindow" [mainWindow]="mainPageWindow"
        [popOutWindow]="chatWindow[0]"></lib-popout-message>
      <div>
        <lib-chat class="content_2" [inputWindow]="chatWindow" (newMessageOutput)="messageToast($event)"
          [chatRoomId]="learnContent.courseInfo.features.textchat.room"
          [apiUrl]="learnContent.courseInfo.features.textchat.apiUrl"
          [websocketUrl]="learnContent.courseInfo.features.textchat.websocketUrl"></lib-chat>
      </div>
    </div>
    <div *ngIf="learnContent.courseInfo.features.notes.enabled" [hidden]="selectedTool(3) == false"
      class="courseContent contentDiv_3">
      <lib-popout-message *ngIf="noteWindow != mainPageWindow" [mainWindow]="mainPageWindow"
        [popOutWindow]="noteWindow"></lib-popout-message>
      <div class="content_3">
       <!-- <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item" *ngFor="let note of learnContent.notes">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseNote_'+note.id" aria-expanded="false"
                [attr.aria-controls]="'collapseNote_'+note.id">
                {{note.title ?? "Başlıksız Not"}}
              </button>
            </h2>
            <div [id]="'collapseNote_'+note.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body" [innerHTML]="note.noteContent">

              </div>
              <div class="accordion-footer" style="text-align: right; padding: 5px;">
                <button class="btn btn-sm btn-danger me-1" (click)="deleteNote(courseId,note.id??'')"><i
                    class="bi bi-trash-fill"></i> {{'btn.delete' |translate}}</button>
                <button class="btn btn-sm btn-primary" [disabled]="editMode" (click)="editNote(note)"><i
                    class="bi bi-pencil-fill"></i> {{'btn.update' |translate}}</button>
              </div>
            </div>
          </div>
        </div>

        <div style="text-align: right; margin-top: 5px;">
          <button class="btn btn-secondary btn-sm" (click)="newNote()">{{'notebook.addNewNote' | translate}}</button>
        </div>
        <div style="padding:20px 0px;">
          <input [hidden]="editMode == false" id="editNoteTitle" type="text" class="form-control"
            placeholder="{{'noteTitle' | translate}}">
          <hr *ngIf="editMode" />
          <div [hidden]="editMode == false" id="editNoteContent"></div>
          <button *ngIf="editMode && noteEditId != undefined && noteEditId != ''" class="btn btn-sm btn-primary"
            (click)="saveNote()">{{'notebook.save' | translate}}</button>
          <button *ngIf="editMode && (noteEditId == undefined || noteEditId == '')" class="btn btn-sm btn-primary"
            (click)="createNote()">{{'notebook.add' | translate}}</button>
          <button *ngIf="editMode" class="btn btn-sm btn-danger ms-1" (click)="cancelNote()">{{'notebook.cancel' |
            translate}}</button>
        </div>-->
        <lib-note-edit [noteWindow]="noteWindow" [notes]="learnContent.notes" [courseId]="courseId" [contentId]="learnContent.currentContent.id"></lib-note-edit>
      </div>
    </div>
    <div [hidden]="selectedTool(4) == false" class="courseContent contentDiv_4">
      <lib-popout-message *ngIf="fileAttachmentWindow != mainPageWindow" [mainWindow]="mainPageWindow"
        [popOutWindow]="fileAttachmentWindow"></lib-popout-message>
      <div class="content_4">
        <app-course-documents [courseDocuments]="learnContent.files"></app-course-documents>
      </div>
    </div>
    <div [hidden]="selectedTool(5) == false" class="courseContent contentDiv_5">
      <lib-popout-message *ngIf="streamWindow != mainPageWindow" [mainWindow]="mainPageWindow"
        [popOutWindow]="streamWindow"></lib-popout-message>
      <div class="content_5">
        <div class="alert alert-warning" role="alert" *ngIf="liveStreams.length < 1">
          <i class="bi bi-broadcast"></i> Karşıdan gelen bir yayın yok.
        </div>
        <div class="liveVideo">
          <div id="miniStreamList"></div>
          <video [hidden]="selectedLiveStream == ''" id="liveVideoStream" autoplay></video>
        </div>
      </div>
    </div>
  </div>
  <div class="objectives col-2" style="white-space: normal;">
    <div class="objectivesTitle">
      {{"objectives" | translate}}
    </div>
    <div class="objectivesList">
      <div class="objectiveItemMain" *ngFor="let objective of learnContent.objectives">
        <div class="objectiveItem">
          <div class="objectiveStatus" style="vertical-align: middle;">
            <i *ngIf="objective.status != null && objective.status == true" class="bi bi-check2"
              style="color: rgb(0, 182, 0); font-size: 20px;"></i>
            <i *ngIf="objective.status != null && objective.status == false" class="bi bi-x"
              style="color: rgb(210, 0, 0); font-size: 20px;"></i>
            <i *ngIf="objective.status ==null || objective.status == undefined" class="bi bi-question"
              style="color: rgb(178, 163, 0); font-size: 20px;"></i>
          </div>
          <div class="objectiveTitle">
            {{objective.title}}
          </div>
        </div>
        <div class="objectiveButtons">
          <i class="bi bi-info-circle-fill" style="color: rgb(0, 142, 0); font-size: 20px; margin-right: 5px;"></i>
          <i class="bi bi-check-circle-fill" style="color: rgb(0, 128, 0); font-size: 20px;"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2" style="text-align: center;">
    <div class="userMenu">

      <div class="userArea">
        <div class="userCircle">
          <i class="bi bi-person-circle personCircle"></i>
          <font class="userFirstname">{{firstname}}</font>
        </div>
        <!--<div class="userStatus">
          <span class="badge rounded-pill bg-secondary"><div class="userAreaStatus" [class.active]="userStatusNumber==1" [class.idle]="userStatusNumber==3" [class.away]="userStatusNumber==2" [class.dnd]="userStatusNumber==4"></div> 
            <font *ngIf="userStatusNumber==1"> Çevrimiçi</font>
            <font *ngIf="userStatusNumber==2"> Uzakta</font>
            <font *ngIf="userStatusNumber==3"> Boşta</font>
            <font *ngIf="userStatusNumber==4"> Rahatsız Etmeyin</font>
  
          </span>
        </div>-->
        <div class="" style="flex:1;" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUserProfile" aria-controls="offcanvasUserProfile">
          <i style="font-size: 20px;" class="bi bi-list"></i>
        </div>
  
      </div>
    </div>
    <div class="rtcVideo">
      <!--<video id="remoteVideo"></video>-->
      <div style="position:relative">
        <lib-rtc-video (closedStream)="handleClosedStream($event)" *ngIf="socketConnected && sessionId != ''"
          [sessionHubObject]="sessionHub" [sessionName]="sessionId"
          (secondaryStream)="getLiveStream($event)"></lib-rtc-video>
      </div>

      <div class="row">
        <div class="col-4" (click)="raiseHand()"><i class="bi bi-hand-index-fill raiseHandIcon"
            [class.active]="isHandRaised" style="font-size:25px;"></i></div>
        <div class="col-4" (click)="voteYes()"><i class="bi bi-check-lg checkIcon" style="font-size:25px;"></i></div>
        <div class="col-4" (click)="voteNo()"><i class="bi bi-x-lg xIcon" style="font-size:25px;"></i></div>
      </div>
      <div class="row" *ngIf="lastPoll">
        <div class="col-12" style="text-align: left;">
          <font><b>{{'miniPoll' | translate}}</b></font><br />
          <font>{{lastPoll.pollQuestion}}</font>
        </div>
      </div>

    </div>
    <div class="learnTools">
      <div class="terminalList">
        <!--  Terminal bağlantısı işlemleri buraya eklenecek-->
        <div class="machine" *ngFor="let guacMachine of guacMachines" style="text-align: left;">
          <i class="bi bi-pc-display" style="vertical-align: middle;"></i>
          <font style="vertical-align: middle;"> <b style="vertical-align: middle;"> {{guacMachine}} </b></font>
          <i class="bi bi-play-fill virtualLabConnect" (click)="connect(guacMachine)" title="Bağlan">
          </i>
          <i class="bi bi-window-plus virtualLabConnectNewWindow" (click)="connect(guacMachine,true)" title="Yeni pencerede aç">
          </i>
        </div>
      </div>
    </div>
  </div>

</div>



<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasUserProfile" aria-labelledby="offcanvasUserProfileLabel">
  <div class="offcanvas-header">
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="userArea">
      <div class="userCircle">
        <i class="bi bi-person-circle personCircle"></i>
        <font class="userFirstname">{{firstname}}</font>
      </div>
      <div class="userStatus">
        <span class="badge rounded-pill bg-secondary"><div class="userAreaStatus" [class.active]="userStatusNumber==1" [class.idle]="userStatusNumber==3" [class.away]="userStatusNumber==2" [class.dnd]="userStatusNumber==4"></div> 
          <font *ngIf="userStatusNumber==1"> Çevrimiçi</font>
          <font *ngIf="userStatusNumber==2"> Uzakta</font>
          <font *ngIf="userStatusNumber==3"> Boşta</font>
          <font *ngIf="userStatusNumber==4"> Rahatsız Etmeyin</font>

        </span>
      </div>

    </div>

    <div class="statusChangeDiv">
      <div class="statusItems">
        <span (click)="changeStatusForMe(1)" class="badge rounded-pill bg-secondary statusBadge"><div class="userAreaStatus active"></div> 
          <font> Çevrimiçi</font>
        </span>
        <span (click)="changeStatusForMe(2)" class="badge rounded-pill bg-secondary statusBadge"><div class="userAreaStatus away"></div> 

          <font> Uzakta</font>

        </span>
        <span (click)="changeStatusForMe(4)" class="badge rounded-pill bg-secondary statusBadge"><div class="userAreaStatus dnd"></div> 
          <font> Rahatsız Etmeyin</font>

        </span>

      </div>
    </div>
    <div class="chatSettingsDiv">
      <div class="chatToast">
        <hr>
        <label><input id="notifyMessageCheck" (change)="messageNotificationChanged($event)" type="checkbox"/>Odaya mesaj gönderildiğinde bildirim alınsın.</label>
        <label><input id="notifyPrivateMessageCheck" (change)="privateMessageNotificationChanged($event)" type="checkbox"/>Özel mesaj gönderildiğinde bildirim alınsın.</label>
      </div>
    </div>
  </div>
</div>