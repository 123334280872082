import { CourseService } from 'projects/usorta-library/src/lib/services/course/course.service';
import { CourseItemModel } from './../../../../../../../usorta-library/src/lib/models/courseItemModel';
import { CourseDetails } from './../../../../../../../usorta-library/src/lib/models/courseDetails';
import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-course-list',
  templateUrl: './horizontal-course-list.component.html',
  styleUrls: ['./horizontal-course-list.component.css']
})
export class HorizontalCourseListComponent implements OnInit {
  @Input() queryUrl:string;
  @Input() headerTitle:string;
  @Input() description:string;
  @Input() pageSize:number = 1;
  @Input() pageScroll:boolean = false;
  @Input() itemType:string = "horizontal";
  @Input() autoScroll:boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setWidth();
  }
  currentScrollSize:number = 1;
  scrollIdentifier:string;
  courses: CourseItemModel[];
  autoScrollInterval:any;
  allowAutoScroll:boolean = true;
  constructor(private courseService:CourseService) {
    this.scrollIdentifier = this.random();
  }
  ngOnInit(): void {
    this.setWidth();
    this.courseService.getCoursesByQueryUrl(this.queryUrl).subscribe(response=>{
      this.courses = response.content;
      this.autoScrollService();
    });
  }
  autoScrollService(){
    let scrollTime = 30;
    if(this.autoScroll){
      this.autoScrollInterval = setInterval(()=>{
        if(this.allowAutoScroll){
          if(scrollTime > 1){
            scrollTime-=1;
          }else{
            this.scrollRight();
            scrollTime = 30;
          }
        }
      },1000);
    }
  }
  startAutoScroll(){

    this.allowAutoScroll = true;
  }
  stopAutoScroll(){
    this.allowAutoScroll = false;
  }
  random(){
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  getCourseCardScrollWidth(){
    let card = document.getElementsByClassName("card_"+this.scrollIdentifier)[0] as HTMLElement;
    console.log((this.currentScrollSize * card.clientWidth) + (this.currentScrollSize * 10))
    if(card){
        return (this.currentScrollSize * card.clientWidth) + (this.currentScrollSize * 10);
    }
    return 0;

  }
  setWidth(){
      setTimeout(()=>{
        let s = false;
        let courseListMain = document.getElementById("courseListMain_"+this.scrollIdentifier);
        let courseList = document.getElementById("courseList_"+this.scrollIdentifier);
        let card = document.getElementsByClassName("card_"+this.scrollIdentifier)[0] as HTMLElement;
        let reqWidth;
        if(courseList && card && courseListMain){
          for(let i = this.pageSize; i > 0; i--){
            reqWidth = ((card.clientWidth * i) + (i * 10))
            if(reqWidth <= (courseListMain.clientWidth-60)){
              if(this.pageScroll){
                this.currentScrollSize = i;
              }
              s = true;
              courseList.style.width = reqWidth + "px";
              courseList.style.display = "inline-block"
              break;
            }else{
              if(s == false){
                this.currentScrollSize = 1;
              }
            }
          }
          //courseList.style.display = "inline-block";
        }else{
          this.setWidth();
        }
      },100)



  }
  scrollLeft(){
    let element = document.getElementById("courseList_"+this.scrollIdentifier);
    if(element){
      element.scrollLeft -= this.getCourseCardScrollWidth();
    }

  }
  scrollRight(){
    let element = document.getElementById("courseList_"+this.scrollIdentifier);
    if(element){
      element.scrollLeft += this.getCourseCardScrollWidth();
    }
  }

}
