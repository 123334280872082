<div class="row">
  <div class="courseFile col-md-6 col-lg-4 col-sm-12 col-xs-12" *ngFor="let document of courseDocuments">
    <div class="courseFileItem">
      <div>
        <i [class]="document.fileIcon" style="font-size:15px;"></i>
        <font class="fileName" style="font-size:14px">{{document.filename}}</font>
        <a [download]="document.filename" [href]="document.fileUrl" target="_blank"><button style="float: right;" class="btn btn-sm btn-success"><b style="font-size: 12px;">İndir</b></button></a>
      </div>
      <hr/>
      <div>
        <font class="fileDescription" style="font-size:14px;">{{document.fileDescription}}</font>
      </div>
    </div>
  </div>
</div>
