<lib-preloader *ngIf="showTenantLoader"></lib-preloader>
<lib-translate-mode-helper *ngIf="showTenantLoader == false && languageLoaded == true"></lib-translate-mode-helper>
<lib-custom-navbar *ngIf="showTenantLoader == false && hideNavbar == false" [menuItems]="menuItems"></lib-custom-navbar>
<div class="cookiePreferencesSelector" *ngIf="showTenantLoader == false">
  <div class="cookiePreferencesBox">
    <lib-cookie-preferences *ngIf="showCookiePreferencesBox"></lib-cookie-preferences>
  </div>

</div>
<div *ngIf="showTenantLoader == false" class="container-fluid containerProperties" style="min-height: calc(99vh - 160px);">
  <router-outlet></router-outlet>
</div>

<lib-footer *ngIf="showTenantLoader == false && showFooter == true"></lib-footer>
<div class="ref" #modalRef></div>