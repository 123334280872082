import { FormResponseObject } from './../../models/CustomFormModels/formResponseObject';
import { CustomForm } from './../../models/CustomFormModels/customForm';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RandomGeneratorService } from './../../services/randomGenerator/random-generator.service';
import { delay, Observable, of, pipe, switchMap, timer } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomFormService } from './../../services/customForm/custom-form.service';
import { AbstractControl, AsyncValidatorFn, Form, FormArray, FormBuilder, FormControl, FormGroup, MinLengthValidator, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Component, Directive, ElementRef, Input, OnInit, ViewChild, Pipe, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormElement } from '../../models/CustomFormModels/formElement';
import { FormPage } from '../../models/CustomFormModels/formPage';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
@Component({
  selector: 'lib-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.css']
})
export class CustomFormComponent implements OnInit {

  constructor(private formBuilder:FormBuilder,private customFormService:CustomFormService,private httpClient:HttpClient,private randomService:RandomGeneratorService,private toastrService:ToastrService,private translateService:TranslateService) { }
  @Input() formId:string = ""; //CMS den çekilecek olan formun Id si bu değişkene atanacak.
  @Input() externalFormData:CustomForm | null = null;
  @Input() externalAPIUrl:string = "";
  @Input() actionApiUrl:string|null = null; //Api url nin bilinmediği durumlarda arayüz tarafından {API_URL} i replace edeceğim.
  @Output() GetCsForm = new EventEmitter<FormGroup>();
  @Output() GetFormGroup = new EventEmitter<FormGroup>();
  @Output() FormInputChanged = new EventEmitter<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>();
  @Output() GetSendingObjects = new EventEmitter<any>();
  @Input() getFormInput:boolean[] = [false];
  csForm:FormGroup;
  customForm:CustomForm;
  pageNumber:number = 0;
  totalPageCount:number;
  formData:any = new FormData();
  perPageSubmit:boolean;
  pageNavigation = "bottom";
  submitList:string[] = [];
  requireMatchs:string[] = [];
  submitting:boolean = false;
  formLoading:boolean = false;
  @Input() searchForm:boolean = false;
  @Input() successRedirectUrl?:string = undefined;
  @Input() successRedirectTitle?:string = undefined;
  @Input() formTopElement:HTMLElement |undefined = undefined;
  @Input() formBottomElement:HTMLElement | undefined = undefined;
  @Input() showSuccessIcon:boolean = true;
  @Input() formResponse:FormResponseObject;
  @Output() formResponseOut = new EventEmitter<FormResponseObject>();
  ngOnChanges(change:SimpleChanges){
    console.log("Veri değişti");
    console.log(change);
    //this.getForm();
    if(this.getFormInput[0] == true){
      this.getFormInput = [false];
      this.emitFormGroup();
      console.log("test");
    }

  }
  ngOnInit(): void {
    if(this.actionApiUrl == null){
      this.actionApiUrl = environment.apiUrl;
    }
    console.log("getform calisti")
    this.getForm();
  }
  emitFormGroup(){
    this.GetFormGroup.emit(this.csForm);
  }
  inputChanged(event:any){
    this.FormInputChanged.emit(event.target);
  }
  getForm(){
    this.formLoading = true;
    this.submitList = [];
    if(this.externalAPIUrl != "" && this.externalAPIUrl != undefined){
      this.customFormService.externalUrl = this.externalAPIUrl;
    }else{
      this.customFormService.externalUrl = "";
    }
    if(this.externalFormData != null){
      this.prepareReceivedCustomFormData(this.externalFormData);
      this.formLoading = false;
    }else{
      this.customFormService.getForm(this.formId).subscribe((result)=>{
        var res = result as any;
        console.log(res);
        this.formLoading = false;
        if(res['content'] != undefined){
          this.prepareReceivedCustomFormData(res['content']);
        }else{
          this.prepareReceivedCustomFormData(result);
        }
        
      })
    }

  }
  prepareReceivedCustomFormData(result:CustomForm){
    var res = result as any;
    console.log(res);
    if(res['content'] != undefined){
      result = res['content'];
    }
    let customFormData = result;
    console.log(result);
    //api url ayarlaması
    if(customFormData.action != null){
      customFormData.action = customFormData.action.replace("{API_URL}",this.actionApiUrl ?? environment.apiUrl);
    }

    customFormData.pages.forEach(x=>{
      if(x.action != null){
        x.action = x.action.replace("{API_URL}",this.actionApiUrl ?? environment.apiUrl);
      }
      console.log(x);
    })
    //end
    this.totalPageCount = result.pages.length;
    customFormData.pages.forEach(page=>{
      page.elements.forEach(element=>{
        if(!element.id){
          element.id = element.name+"_"+this.randomService.random();
        }
        element.options?.forEach(option=>{
          option.id+"_"+this.randomService.random();
        })
      })
    })
    this.customForm = customFormData;
    this.perPageSubmit = result.submitPerPage;
    this.pageNavigation = result.pageNavigation == 'hidden' || result.pageNavigation == 'top' ? result.pageNavigation : "bottom";
    this.addControllers();
    this.createSubmitList();
    this.addCustomElement();
  }
  getPageCount(){
    return this.customForm.pages.length;
  }
  addCustomElement(){


    setTimeout(()=>{
      if(this.formTopElement !== undefined){
        var formTopEl = document.getElementById("formTop") as HTMLElement;
        formTopEl.innerHTML = "";
        document.getElementById("formTop")?.append(this.formTopElement);
        this.formTopElement = undefined;
      }
      if(this.formBottomElement !== undefined){
        var bottomEl = document.getElementById("formBottom") as HTMLElement;
        bottomEl.innerHTML = "";
        document.getElementById("formBottom")?.append(this.formBottomElement);
        this.formBottomElement = undefined;
      }
    },250)

  }
  getButtonText(key:string){
    let btn = this.customForm.buttons?.find(x=>x.button == key);
    if(btn?.localizationKey){
      return btn.localizationKey;
    }
    return "formbtn."+key;
  }
  getCustomErrorMessage(fieldName:string,errorName:string){
    let customMessage = null;
      let pageElements = this.getPage().elements;
      //console.log(fieldName,errorName);
      let err = pageElements.find(x=>x.name == fieldName)?.validationRules?.find(x=>x.key==errorName);
      if(err && err.message && err.message != ""){
        customMessage = err.message;
      }
    return customMessage;
  }
  getElementByFieldName(fieldName:string):FormElement|null{
    let element:FormElement | null = null;
    let pages = this.customForm.pages.forEach(page=>{
      page.elements.forEach(el=>{
        if(el.name == fieldName){
          element = el;
        }
      })
    })
    return element;
  }

  createSubmitList(){
    let pages = this.customForm.pages.forEach(page=>{
      if(page.submitList && page.submitList.length > 0){
        page.submitList?.forEach(item=>{
          this.submitList.push(item);
        })
      }else{
        page.elements.forEach(el=>{
          this.submitList.push(el.name);
        })
      }

    })
    console.log("Gönderilecek Öğeler")
    console.log(this.submitList);
  }
  getErrors(name:string){
    let err = this.csForm.get(name)?.errors;
    let result:any = [];
    let errors:string[] = [];
    if(err){
      let controlErrors: ValidationErrors = err;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': name,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
      for (let index = 0; index < result.length; index++) {

        const e = result[index];
        //console.log(e.value);
        let errorMessage = "";
        let customErrorMessage = this.getCustomErrorMessage(e.control,e.error);
        //console.log(customErrorMessage);
        let fieldLabel:string | undefined = this.getElementByFieldName(e.control)?.label
        let sendValues = Object.assign({fieldName:e.control,fieldLabel:fieldLabel},e.value);
        errorMessage = customErrorMessage != null ? customErrorMessage : this.translateService.instant("validationError."+e.error,sendValues);
        if("validationError."+e.error == errorMessage){
          errorMessage = this.translateService.instant("validationError.others");
        }
        errors.push(errorMessage);
      }
      return errors;
    }else{
      return null;
    }
    //
  }
  formDataImageCheck(field:string){
    if(this.formData.get(field)){
      return true;
    }else{
      return false;
    }
  }
  addControllers(){
    let builder = this.formBuilder.group({},{updateOn: 'change'} );
    this.csForm = builder;
    let formPages:FormPage[] = [];
    if(this.perPageSubmit){
      formPages[0] = this.getPage();
    }else{
      formPages = this.customForm.pages;
    }
    formPages.forEach((page)=>{
      page.elements.forEach(res=>{
        if(res.type != 'htmlContent'){
          if(res.type == "checkbox"){
            builder.addControl(res.name,new FormArray([]))
            //console.log(res.name + " "+ res.type);
          }else if(res.type == 'radio' || (res.type == 'select' && (res.defaultValue == "" || res.defaultValue == null || res.defaultValue.startsWith("[")))){
            builder.addControl(res.name,new FormControl([]))
          }else if(res.type == "select" && !res.defaultValue.startsWith("[")){
            if(res.attributes !== undefined && res.attributes.find(x=>x.key == "multiple") !== undefined){
              builder.addControl(res.name,new FormControl([]))
            }
            else if(res.options){
              console.log(res.options.find(x=>x.selected == true)?.value);
              builder.addControl(res.name,new FormControl(res.options.find(x=>x.selected == true)?.value));
            }else{
              builder.addControl(res.name,new FormControl([]))
            }
          }else if(res.type == 'singlecheck'){
            builder.addControl(res.name,new FormControl(res.defaultValue == 'checked' ? true : false));
          }else{
            builder.addControl(res.name,new FormControl(res.defaultValue))
          }
        }
      })
      this.csForm = builder;
      page.elements.forEach(res=>{
        if(res.validationRules && res.type != 'htmlContent'){
          res.validationRules.forEach(attr=>{
            if(res.disabled){
              this.csForm.get(res.name)?.disable();
            }
            switch(attr.key){
              case "required":
                this.csForm.get(res.name)?.addValidators(Validators.required);
                break;
              case "minLength":
                this.csForm.get(res.name)?.addValidators(Validators.minLength(parseInt(attr.value)));
                break;
              case "maxLength":
                this.csForm.get(res.name)?.addValidators(Validators.maxLength(parseInt(attr.value)));
                break;
              case "email":
                this.csForm.get(res.name)?.addValidators(Validators.email);
                break;
              case "regexPattern":
                this.csForm.get(res.name)?.addValidators(Validators.pattern(new RegExp(attr.value)));
                break;
              case "pattern":
                console.log("regex patt")
                console.log(attr.value);
                this.csForm.get(res.name)?.addValidators(Validators.pattern(attr.value));
                break;
              case "min":
                this.csForm.get(res.name)?.addValidators(Validators.min(parseInt(attr.value)));
                break;
              case "max":
                this.csForm.get(res.name)?.addValidators(Validators.max(parseInt(attr.value)));
                break;
              case "phone":
                this.csForm.get(res.name)?.addValidators(this.phoneControl());
                break;
              case "mustEqual":
                if(this.requireMatchs.findIndex(x=>x == res.name) == -1){
                  this.requireMatchs.push(res.name);
                }

                this.csForm.get(res.name)?.addValidators(this.requireMatch(attr.value))
                break;
            }
          })
        }
      })

    })
    this.autoSelected();
  }
  getChecked(type:string,name:string,value:string){
    if(type == "checkbox"){
      const checkList = this.csForm.get(name) as FormArray;
      let index =  checkList.controls.findIndex(val=>val.value == value);
      if(index != -1){
        return true;
      }
    return false
    }
    if(type == 'radio'){
      let getRadioControl = this.csForm.controls[name];
      if(getRadioControl.value == value){
        return true;
      }
      return false;
    }
    return false;
  }
  previousPage(){
    if(this.pageNumber > 0){
      this.pageNumber--;
    }
  }
  previousPageAvailabe(){
    if(this.pageNumber > 0){
      return true;
    }
    return false;
  }
  getValue(reqid:string){
    return this.csForm.get(reqid)?.value;
  }
  requireMatch(reqid:string):ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      if(!value){
        return null;
      }
      let el2 = document.getElementById(reqid) as HTMLInputElement;
      const value2 = el2.value;
      const isMatch = value == value2 ? true : false;
      return !isMatch ? {fieldsNotMatch:true}: null;
  }
  }
  /*updateValidators(){
    console.log("sdasdad")
    this.requireMatchs.forEach(control=>{
      console.log(control);

      control.updateValueAndValidity();
    })
  }*/


  phoneControl():ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      let ctrl = RegExp('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$').test(value);
      return !ctrl ? {phone:true} : null
    }
  }

  nextPage(){
    if(this.pageNumber < this.customForm.pages.length-1){
      this.pageNumber++;
      if(this.perPageSubmit){
        this.addControllers();
      }
    }
  }
  requirePageValidation(){
    return this.getPage().requireValidation;
  }
  pageValidation(){
    let isvalid:boolean = true;
    this.getPage().elements.forEach(el=>{
      let err = this.csForm.get(el.name)?.errors;
      if(err){
        isvalid = false;
      }

    })
    return isvalid;
  }
  nextPageAvailabe(){
    if(this.pageNumber < this.customForm.pages.length-1){
      return true;
    }
    return false;
  }
  getPage(){
    return this.customForm.pages[this.pageNumber];
  }
  isValid(){
    if(this.csForm.valid){
      return true;
    }else{
      return false
    }
  }
  emitCSForm(){
    this.GetCsForm.emit(this.csForm);
  }
  submitForm(){
    console.log("submit btn tetiklendi")
    if(this.submitting == true){
      console.log("iptal edild")
      return;
    }
    
    let submitAction:string = this.customForm.action;
    let submitMethod:string = this.customForm.method;
    let submitEnctype:string = this.customForm.enctype;
    if(this.customForm.submitPerPage){
      let pageAction = this.getPage().action;
      let pageMethod = this.getPage().method;
      let pageEnctype = this.getPage().enctype;
      submitAction = pageAction != undefined ? pageAction : submitAction;
      submitMethod = pageMethod != undefined ? pageMethod : submitMethod;
      submitEnctype = pageEnctype != undefined ? pageEnctype : submitEnctype;
    }
    this.requireMatchs.forEach(x=>{
      this.csForm.get(x)?.updateValueAndValidity();
    })
    if(this.csForm.valid){
      this.submitting = true;
      this.emitCSForm();

      if(submitMethod == "post"){
        let sendHeaders = new HttpHeaders().set('Content-Type', submitEnctype);
        /*let options = {
          headers:new HttpHeaders().set('Content-Type', submitEnctype)
        } */
        let response:Observable<FormResponseObject> = new Observable;
        switch(submitEnctype){
          case 'multipart/form-data':
            for (const field in this.csForm.controls) {
              if(this.submitList.find(x=>x == field)){
                this.formData.append(field,this.csForm.get(field)?.value);
                console.log(this.formData);
                //console.log(field + ": "+this.csForm.get(field)?.value );
              }
            }
            this.GetSendingObjects.emit(this.formData);
            if(this.customForm.action == "#"){
              this.submitting = false;
              return;
            }

            response = this.customFormService.formPostRequest(submitAction,this.formData,null);
            break;
          case 'application/x-www-form-urlencoded':
            const payload = new HttpParams();
            for (const field in this.csForm.controls) {
              if(this.submitList.find(x=>x == field)){
              payload.append(field,this.csForm.get(field)?.value);
              }
            }
            this.GetSendingObjects.emit(payload);
            if(this.customForm.action == "#"){
              this.submitting = false;
              return;
            }
            response = this.customFormService.formPostRequest(submitAction,payload,sendHeaders);
            console.log(response);
            break;
          case 'application/json':

            let x = Object.entries(this.csForm.value)
            console.log(x);
            x.forEach((val,ind,arr)=>{
              if(parseInt(val[1] as string) != undefined){
                if(val[1] != "" && (this.valueTypeControl(val[0]) == "number" || this.valueTypeControl(val[0]) == "number|null")){
                  if(this.elementTypeControl(val[0]) == "checkbox"){
                    var fieldNumbers:number[] = []
                    var stringList = (val[1] as string[])
                    stringList.forEach(str=>{
                      fieldNumbers.push(parseInt(str));
                    })
                    val[1] = fieldNumbers;
                  }else{
                    val[1] = parseInt(val[1] as string);
                  }

                }
              }
            })
            x.forEach((val,ind,arr)=>{
              if(val[1] == ""){
                if(this.valueTypeControl(val[0]) == "string|null" ||this.valueTypeControl(val[0]) == "number|null"){
                  val[1] = null;
                }
              }
            })
            let filtered = x.filter(x=>this.submitList.findIndex(s=>s == x[0]) != -1);
            let sendObjects = Object.fromEntries(filtered);
            
            //console.log("sending...")
            //console.log(sendObjects);
            this.GetSendingObjects.emit(sendObjects);
            console.log("test")
            if(this.customForm.action == "#"){
              this.submitting = false;
              return;
            }
            response = this.customFormService.formPostRequest(submitAction,sendObjects,null);
        }

        response.subscribe((res)=>{
          this.formResponse = res;
          console.log(res);
          this.formResponseOut.emit(res);
          if(res.success){
            this.toastrService.success("Başarılı");
                          console.log("***--112313123123")
              console.log(res);
              var resContent = res as any;
            if(resContent != null && typeof(resContent.content) == "string"){

              this.updateSuccessUrl(resContent.content);
            }
            if(this.perPageSubmit){
              this.nextPage();
            }
          }else{
            console.log("hata");
            let showErr:boolean = false;
            res.errors?.forEach(err=>{

              if(err.message){
                this.toastrService.error(err.message);
                showErr = true;
              }else if(err.localizationKey){
                this.toastrService.error(this.translateService.instant(err.localizationKey,{fieldName:err.fieldName}),"",{timeOut:0});
                showErr = true;
              }
            })
            if(!showErr){
              this.toastrService.error(this.translateService.instant("formError.unknownError"));
            }

          }
          this.submitting = false;
        },(error)=>{
          console.log("hata");
          let showErr:boolean = false;
          if(error.error.errors != null){
            var keys = Object.keys(error.error.errors)
            console.log(keys);
            
            keys.forEach((errKey:any)=>{
              var err = error.error.errors[errKey]
              console.log("errrr")
              console.log(err);
              if(err){
                this.toastrService.error(err,undefined,{timeOut:4000,extendedTimeOut:4000});
                showErr = true;
              }else if(err.localizationKey){
                this.toastrService.error(this.translateService.instant(err.localizationKey,{fieldName:err.fieldName}),"",{timeOut:0});
                showErr = true;
              }
            })
          }else{
            error.errors?.forEach((err:any)=>{
              if(err.message){
                this.toastrService.error(err.message);
                showErr = true;
              }else if(err.localizationKey){
                this.toastrService.error(this.translateService.instant(err.localizationKey,{fieldName:err.fieldName}),"",{timeOut:0});
                showErr = true;
              }
            })
          }

          if(!showErr){
            this.toastrService.error(this.translateService.instant("formError.unknownError"));
          }
          this.submitting = false;
        })

      }else if(submitMethod == "get"){
        let params = "?"
        for (const field in this.csForm.controls) {
          params = params != "?" ? params+"&" : params;
          params+=field+"="+this.csForm.get(field)?.value;
          //console.log(field + ": "+this.csForm.get(field)?.value );
        }
        //console.log(params);
        this.httpClient.get<FormResponseObject>(submitAction+params).subscribe(res=>{
          this.formResponse = res;
          //console.log(res);
          this.formResponseOut.emit(res);
          if(res.success){
            this.toastrService.success("Başarılı");
          }else{
            this.toastrService.error("Form gönderilirken bir hata oluştu");
          }
          this.submitting = false;
        },err=>{
          console.log("hata")
          console.log(err);
          this.submitting = false;
        });
      }
    }else{
      this.toastrService.error("Formu kontrol edin");
    }
  }
  updateSuccessUrl(id:string){
    if(this.successRedirectUrl != null || this.successRedirectUrl != ""){
      this.successRedirectUrl = this.successRedirectUrl?.replace("{id}",id);
    }
  }
  valueTypeControl(name:string){
    var formPageList = this.customForm.pages;
    var selectedElement:any = null;
    formPageList.forEach((page)=>{
      page.elements.forEach(res=>{
        if(res.name == name){
          selectedElement = res;
        }
      
      })
    })
    if(selectedElement != null && selectedElement.valueType != null){
      return selectedElement.valueType;
    }
    return "";
  }
  elementTypeControl(name:string){
    var formPageList = this.customForm.pages;
    var selectedElement:any = null;
    formPageList.forEach((page)=>{
      page.elements.forEach(res=>{
        if(res.name == name){
          selectedElement = res;
        }
      
      })
    })
    if(selectedElement != null && selectedElement.type != null){
      return selectedElement.type;
    }
    return "";
  }
  changeEvent(event:Event) {
    if((event.target as HTMLInputElement).tagName == "SELECT"){
      this.FormInputChanged.emit(event.target as any);
    }else{
      this.FormInputChanged.emit(event as any);
    }

    console.log((event.target as HTMLInputElement).type);
    if(event.target != null){
      console.log("change event...")
      let inputElement = (event.target as HTMLInputElement);
      if(inputElement.type == "file"){
        let getFile = null;
        if(inputElement.files){
          getFile = inputElement.files[0];
        }
        let inputname = inputElement.name;
        this.formData.append(inputname,getFile);
      }else if(inputElement.type == "checkbox"){
        //console.log(inputElement)
        const checkList = this.csForm.get(inputElement.name) as FormArray;
        //console.log("element kontrol ediliyor "+ inputElement.name);
        //console.log(checkList);
        if(inputElement.checked){
          checkList.push(new FormControl(inputElement.value));
        }else{
          const index = checkList.controls.findIndex(val=>val.value === inputElement.value);
          checkList.removeAt(index);
        }
      }else if(inputElement.type == "radio"){
        let getRadioControl = this.csForm.controls[inputElement.name];
        if(getRadioControl){
          getRadioControl.setValue(inputElement.value);
        }
      }else if(inputElement.type == "select-multiple"){

        var selectEl = event.target as HTMLSelectElement;
        var selectedOptions = selectEl.options;
        var selectedItemCount = selectEl.options.length;
        var items:string[] = [];
        for (let index = 0; index < selectedItemCount; index++) {
          const element = selectedOptions.item(index);
          if(element !== null && element.selected == true){
            console.log(element.value.split(" ")[1]);
            items.push(element.value.split(" ")[1]);
          }

        }
        let getSelectValue = this.csForm.controls[inputElement.name];
        if(getSelectValue){
          console.log(items);
            getSelectValue.setValue(items);
        }
      }
    }
  }
  autoSelected(){
    this.customForm.pages.forEach(page=>{
      page.elements.forEach(el=>{
        el.options?.forEach(opt=>{
          if(opt.selected){
            if(el.type == 'checkbox'){
              const checkList = this.csForm.get(el.name) as FormArray;
              if(checkList){
                checkList.push(new FormControl(opt.value));
              }

            }else if(el.type == 'radio'){
              let getRadioControl = this.csForm.controls[el.name];
              if(getRadioControl){
                getRadioControl.setValue(opt.value);
              }
            }
          }
        })
      })
    })

  }
  radioClick(event:Event){
    let input = event.target as HTMLInputElement;
    let getRadioControl = this.csForm.controls[input.name];
    if(getRadioControl.value == input.value){
      getRadioControl.setValue([]);
      input.checked = false;
    }
  }
}
