import { NgChartsModule } from 'ng2-charts';
import { CustomChartComponent } from './../../components/custom-chart/custom-chart.component';
import { CustomLayoutComponent } from './../../components/custom-layout/custom-layout.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './../../components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { SanitizedHtmlPipe } from './../../../pipes/sanitized-html.pipe';
import { CookiePreferencesComponent } from './../../components/cookie-preferences/cookie-preferences.component';
import { LocalTranslatePipe } from './../../../pipes/local-translate.pipe';
import { PreloaderComponent } from './../../components/preloader/preloader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './../../components/login/login.component';
import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { CustomBlockComponent } from '../../components/static-content/custom-block/custom-block.component';
import { StaticContentComponent } from '../../components/static-content/static-content.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { CustomTableComponent } from '../../components/custom-table/custom-table.component';
import { CustomNavbarComponent } from '../../components/custom-navbar/custom-navbar.component';
import { LandingMainComponent } from '../../components/courseLandingPage/landing-main/landing-main.component';
import { CourseOutlineComponent } from '../../components/courseLandingPage/course-outline/course-outline.component';
import { CustomFormComponent } from '../../components/custom-form/custom-form.component';
import { AddAttributesDirective } from '../../directives/addAttribute/add-attributes.directive';
import { CustomTableEditorComponent } from '../../components/custom-table-editor/custom-table-editor.component';
import { CustomLayoutBlockComponent } from '../../components/custom-layout/custom-layout-block/custom-layout-block.component';
import { CustomLayoutRegionComponent } from '../../components/custom-layout/custom-layout-region/custom-layout-region.component';
import { CustomMenuComponent } from '../../components/custom-menu/custom-menu.component';
import { PieChartComponent } from '../../components/custom-charts/pie-chart/pie-chart.component';
import { BarChartComponent } from '../../components/custom-charts/bar-chart/bar-chart.component';
import { AreaChartComponent } from '../../components/custom-charts/area-chart/area-chart.component';
import { ScatterChartComponent } from '../../components/custom-charts/scatter-chart/scatter-chart.component';
import { LineChartComponent } from '../../components/custom-charts/line-chart/line-chart.component';
import { RadarChartComponent } from '../../components/custom-charts/radar-chart/radar-chart.component';
import { PolarAreaChartComponent } from '../../components/custom-charts/polar-area-chart/polar-area-chart.component';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { GuacClientComponent, GuacLibraryModule, GuacMultiscreenComponent } from '@usorta-packages/guac-library';
import { TerminalChatSenderComponent } from '../../components/terminal-chat-sender/terminal-chat-sender.component';
import { RtcVideoComponent } from '../../components/rtc-video/rtc-video.component';
import { ConnectedFromOtherComponent } from '../../components/connected-from-other/connected-from-other.component';
import { SessionLoaderComponent } from '../../components/session-loader/session-loader.component';
import { PopoutComponent } from '../../components/popout/popout.component';
import { PopoutMessageComponent } from '../../components/popout-message/popout-message.component';
import { BasicTableComponent } from '../../components/basic-table/basic-table.component';
import { GlobalModalComponent } from '../../components/global-modal/global-modal.component';
import { NoteEditComponent } from '../../components/learnComponents/note-edit/note-edit.component';
import { DynamicFormToJsonComponent } from '../../components/dynamic-form-to-json/dynamic-form-to-json.component';
import { SessionManagementPageComponent } from '../../components/sessionManagementPanel/session-management-page/session-management-page.component';
import { SessionManagementAddFeatureComponent } from '../../components/sessionManagementPanel/session-management-add-feature/session-management-add-feature.component';
import { SessionManagementAddItemComponent } from '../../components/sessionManagementPanel/session-management-add-item/session-management-add-item.component';
import { ImportFeaturesComponent } from '../../components/sessionManagementPanel/import-features/import-features.component';
import { SessionManagementDeleteFeatureComponent } from '../../components/sessionManagementPanel/session-management-delete-feature/session-management-delete-feature.component';
import { SessionManagementListFeaturesComponent } from '../../components/sessionManagementPanel/session-management-list-features/session-management-list-features.component';
import { SessionManagementListItemsComponent } from '../../components/sessionManagementPanel/session-management-list-items/session-management-list-items.component';
import { SessionManagementDeleteItemComponent } from '../../components/sessionManagementPanel/session-management-delete-item/session-management-delete-item.component';
import { SessionManagementUpdateSessionComponent } from '../../components/sessionManagementPanel/session-management-update-session/session-management-update-session.component';
import { SessionManagementSessionMembersComponent } from '../../components/sessionManagementPanel/session-management-session-members/session-management-session-members.component';
import { SessionManagementRemoveMemberFromSessionComponent } from '../../components/sessionManagementPanel/session-management-remove-member-from-session/session-management-remove-member-from-session.component';
import { SessionManagementAddMemberToSessionComponent } from '../../components/sessionManagementPanel/session-management-add-member-to-session/session-management-add-member-to-session.component';
import { NewEducationComponent } from '../../components/educationManagement/new-education/new-education.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UpdateEducationComponent } from '../../components/educationManagement/update-education/update-education.component';
import { AddEducationCategoryComponent } from '../../components/educationManagement/add-education-category/add-education-category.component';
import { EducationDetailsComponent } from '../../components/educationManagement/education-details/education-details.component';
import { EducationDetailsBasicComponent } from '../../components/educationManagement/education-details/education-details-basic/education-details-basic.component';
import { EducationDetailsOutlineListComponent } from '../../components/educationManagement/education-details/education-details-outline-list/education-details-outline-list.component';
import { EducationDetailsDetailAreaComponent } from '../../components/educationManagement/education-details/education-details-detail-area/education-details-detail-area.component';
import { AddSectionToEducationComponent } from '../../components/educationManagement/add-section-to-education/add-section-to-education.component';
import { AddSubsectionToSectionComponent } from '../../components/educationManagement/add-subsection-to-section/add-subsection-to-section.component';
import { AddOutlineToEducationComponent } from '../../components/educationManagement/add-outline-to-education/add-outline-to-education.component';
import { UpdateEducationSectionComponent } from '../../components/educationManagement/update-education-section/update-education-section.component';
import { UpdateEducationSubsectionComponent } from '../../components/educationManagement/update-education-subsection/update-education-subsection.component';
import { UpdateEducationCategoryComponent } from '../../components/educationManagement/update-education-category/update-education-category.component';
import { EducationCategoryDetailsComponent } from '../../components/educationManagement/education-category-details/education-category-details.component';
import { DeleteEducationCategoryComponent } from '../../components/educationManagement/delete-education-category/delete-education-category.component';
import { CreateEducationThemeComponent } from '../../components/educationThemes/create-education-theme/create-education-theme.component';
import { DeleteEducationThemeComponent } from '../../components/educationThemes/delete-education-theme/delete-education-theme.component';
import { UpdateEducationThemeComponent } from '../../components/educationThemes/update-education-theme/update-education-theme.component';
import { AddCourseContentComponent } from '../../components/courseContent/add-course-content/add-course-content.component';
import { EducationSectionDetailsComponent } from '../../components/educationManagement/education-section-details/education-section-details.component';
import { AddNewVersionComponent } from '../../components/educationManagement/add-new-version/add-new-version.component';
import { VersionDetailsComponent } from '../../components/educationManagement/version-details/version-details.component';
import { VersionDetailsSectionsComponent } from '../../components/educationManagement/version-details/version-details-sections/version-details-sections.component';
import { VersionDetailsBasicComponent } from '../../components/educationManagement/version-details/version-details-basic/version-details-basic.component';
import { CreateQuestionSetComponent } from '../../components/questionManagement/create-question-set/create-question-set.component';
import { CreateQuestionComponent } from '../../components/questionManagement/create-question/create-question.component';
import { CreateQuestionOptionComponent } from '../../components/questionManagement/create-question-option/create-question-option.component';
import { QuestionDetailsComponent } from '../../components/questionManagement/question-details/question-details.component';
import { UpdateQuestionSetComponent } from '../../components/questionManagement/update-question-set/update-question-set.component';
import { UpdateQuestionComponent } from '../../components/questionManagement/update-question/update-question.component';
import { QuestionSetListComponent } from '../../components/questionManagement/question-set-list/question-set-list.component';
import { QuestionSetDetailsComponent } from '../../components/questionManagement/question-set-details/question-set-details.component';
import { ShowQuestionComponent } from '../../components/questionManagement/show-question/show-question.component';
import { UpdateQuestionOptionComponent } from '../../components/questionManagement/update-question-option/update-question-option.component';
import { AddExamComponent } from '../../components/examManagement/add-exam/add-exam.component';
import { CreateVmEnvironmentComponent } from '../../components/virtualLab/create-vm-environment/create-vm-environment.component';
import { UpdateVmEnvironmentComponent } from '../../components/virtualLab/update-vm-environment/update-vm-environment.component';
import { DeleteVmEnvironmentComponent } from '../../components/virtualLab/delete-vm-environment/delete-vm-environment.component';
import { AddVirtualMachineComponent } from '../../components/virtualLab/add-virtual-machine/add-virtual-machine.component';
import { UpdateVirtualMachineComponent } from '../../components/virtualLab/update-virtual-machine/update-virtual-machine.component';
import { VirtualMachineDetailsComponent } from '../../components/virtualLab/virtual-machine-details/virtual-machine-details.component';
import { CustomizeSetRootPasswordComponent } from '../../components/virtualLab/vm-customization/customize-set-root-password/customize-set-root-password.component';
import { CustomizeAddNewUserComponent } from '../../components/virtualLab/vm-customization/customize-add-new-user/customize-add-new-user.component';
import { CustomizeChangePasswordComponent } from '../../components/virtualLab/vm-customization/customize-change-password/customize-change-password.component';
import { CustomizeInstallPackagesComponent } from '../../components/virtualLab/vm-customization/customize-install-packages/customize-install-packages.component';
import { CustomizeCopyFilesComponent } from '../../components/virtualLab/vm-customization/customize-copy-files/customize-copy-files.component';
import { CustomizeRunScriptsComponent } from '../../components/virtualLab/vm-customization/customize-run-scripts/customize-run-scripts.component';
import { CreateLabComponent } from '../../components/virtualLab/create-lab/create-lab.component';
import { ManageLabComponent } from '../../components/virtualLab/manage-lab/manage-lab.component';
import { AddVmToLabComponent } from '../../components/virtualLab/manage-lab/add-vm-to-lab/add-vm-to-lab.component';
import { AddControlCriteriaComponent } from '../../components/virtualLab/manage-lab/add-control-criteria/add-control-criteria.component';
import { UpdateLabComponent } from '../../components/virtualLab/update-lab/update-lab.component';
import { UpdateVmInLabComponent } from '../../components/virtualLab/manage-lab/update-vm-in-lab/update-vm-in-lab.component';
import { UpdateControlCriteriaComponent } from '../../components/virtualLab/manage-lab/update-control-criteria/update-control-criteria.component';
import { AddCategoryTreeComponent } from '../../components/educationManagement/categoryTree/add-category-tree/add-category-tree.component';
import { DeleteCategoryTreeComponent } from '../../components/educationManagement/categoryTree/delete-category-tree/delete-category-tree.component';
import { UpdateCategoryTreeComponent } from '../../components/educationManagement/categoryTree/update-category-tree/update-category-tree.component';
import { EducationCategoryListComponent } from '../../components/educationManagement/education-category-list/education-category-list.component';
import { CategoryTreeListComponent } from '../../components/educationManagement/categoryTree/category-tree-list/category-tree-list.component';
import { CoursesTableComponent } from '../../components/educationManagement/courses-table/courses-table.component';
import { EducationThemesTableComponent } from '../../components/educationThemes/education-themes-table/education-themes-table.component';
import { NewInstallerImageComponent } from '../../components/new-installer-image/new-installer-image.component';
import { VmEnvironmentListComponent } from '../../components/virtualLab/vm-environment-list/vm-environment-list.component';
import { VirtualMachineListComponent } from '../../components/virtualLab/virtual-machine-list/virtual-machine-list.component';
import { VirtualLabListComponent } from '../../components/virtualLab/virtual-lab-list/virtual-lab-list.component';
import { EducationThemeDetailComponent } from '../../components/educationThemes/education-theme-detail/education-theme-detail.component';
import { CourseContentDetailsComponent } from '../../components/courseContent/course-content-details/course-content-details.component';
import { DeleteCourseContentComponent } from '../../components/courseContent/delete-course-content/delete-course-content.component';
import { DeleteEducationComponent } from '../../components/educationManagement/delete-education/delete-education.component';
import { UpdateEducationContentComponent } from '../../components/courseContent/update-education-content/update-education-content.component';
import { UpdateEducationVersionComponent } from '../../components/educationManagement/update-education-version/update-education-version.component';
import { CreateInstallerImageComponent } from '../../components/virtualLab/create-installer-image/create-installer-image.component';
import { DeleteInstallerImageComponent } from '../../components/virtualLab/delete-installer-image/delete-installer-image.component';
import { UpdateInstallerImageComponent } from '../../components/virtualLab/update-installer-image/update-installer-image.component';
import { UploadInstallerImageComponent } from '../../components/virtualLab/upload-installer-image/upload-installer-image.component';
import { InstallerImageListComponent } from '../../components/virtualLab/installer-image-list/installer-image-list.component';
import { InstallerImageDetailsComponent } from '../../components/virtualLab/installer-image-details/installer-image-details.component';
import { ActiveConnectionsComponent } from '../../components/active-connections/active-connections.component';
import { GuacamoleLmsSessionTerminalsComponent } from '../../components/guacamole-lms-session-terminals/guacamole-lms-session-terminals.component';
import { CreateVmInstanceTypeComponent } from '../../components/vm-instance-types/create-vm-instance-type/create-vm-instance-type.component';
import { DeleteVmInstanceTypeComponent } from '../../components/vm-instance-types/delete-vm-instance-type/delete-vm-instance-type.component';
import { UpdateVmInstanceTypeComponent } from '../../components/vm-instance-types/update-vm-instance-type/update-vm-instance-type.component';
import { ManageVmInstanceTypeComponent } from '../../components/vm-instance-types/manage-vm-instance-type/manage-vm-instance-type.component';
import { CreateVmInstanceTypeMappingComponent } from '../../components/vm-instance-type-mappings/create-vm-instance-type-mapping/create-vm-instance-type-mapping.component';
import { UpdateVmInstanceTypeMappingComponent } from '../../components/vm-instance-type-mappings/update-vm-instance-type-mapping/update-vm-instance-type-mapping.component';
import { DeleteVmInstanceTypeMappingComponent } from '../../components/vm-instance-type-mappings/delete-vm-instance-type-mapping/delete-vm-instance-type-mapping.component';
import { ManageVmInstanceTypeMappingComponent } from '../../components/vm-instance-type-mappings/manage-vm-instance-type-mapping/manage-vm-instance-type-mapping.component';
import { ListVmInstanceTypeMappingComponent } from '../../components/vm-instance-type-mappings/list-vm-instance-type-mapping/list-vm-instance-type-mapping.component';
import { CreateHostProviderTypeComponent } from '../../components/host-provider-components/create-host-provider-type/create-host-provider-type.component';
import { UpdateHostProviderTypeComponent } from '../../components/host-provider-components/update-host-provider-type/update-host-provider-type.component';
import { DeleteHostProviderTypeComponent } from '../../components/host-provider-components/delete-host-provider-type/delete-host-provider-type.component';
import { ListHostProviderTypeComponent } from '../../components/host-provider-components/list-host-provider-type/list-host-provider-type.component';
import { ManageHostProviderComponent } from '../../components/host-provider-components/manage-host-provider/manage-host-provider.component';
import { VmInstanceTypeListComponent } from '../../components/vm-instance-types/vm-instance-type-list/vm-instance-type-list.component';
import { VmInstanceTypeMappingNoteComponent } from '../../components/vm-instance-type-mappings/vm-instance-type-mapping-note/vm-instance-type-mapping-note.component';
import { VmEnvironmentDetailsComponent } from '../../components/virtualLab/vm-environment-details/vm-environment-details.component';
import { HostDashboardComponent } from '../../components/host-dashboard/host-dashboard/host-dashboard.component';
import { HostDashboardCounterComponent } from '../../components/host-dashboard/host-dashboard-counter/host-dashboard-counter.component';
import { HostDashboardSwitchComponent } from '../../components/host-dashboard/host-dashboard-switch/host-dashboard-switch.component';
import { HostDashboardChartComponent } from '../../components/host-dashboard/host-dashboard-chart/host-dashboard-chart.component';
import { HostDashboardBasicComponent } from '../../components/host-dashboard/host-dashboard-basic/host-dashboard-basic.component';
import { AddLocaleComponent } from '../../components/locales/add-locale/add-locale.component';
import { UpdateLocaleComponent } from '../../components/locales/update-locale/update-locale.component';
import { DeleteLocaleComponent } from '../../components/locales/delete-locale/delete-locale.component';
import { LocalesTableComponent } from '../../components/locales/locales-table/locales-table.component';
import { LocaleDetailsComponent } from '../../components/locales/locale-details/locale-details.component';
import { CreateLocalizedStringComponent } from '../../components/localizationString/create-localized-string/create-localized-string.component';
import { LocalizedStringComponent } from '../../components/localizationString/localized-string/localized-string.component';
import { LocalizedStringTableComponent } from '../../components/localizationString/localized-string-table/localized-string-table.component';
import { UpdateLocalizedStringComponent } from '../../components/localizationString/update-localized-string/update-localized-string.component';
import { PreviewTextComponent } from '../../components/preview-text/preview-text.component';
import { DeleteLocalizedStringComponent } from '../../components/localizationString/delete-localized-string/delete-localized-string.component';
import { CreateLocalizedFormatStringComponent } from '../../components/localizedFormatString/create-localized-format-string/create-localized-format-string.component';
import { LocalizedFormatStringListComponent } from '../../components/localizedFormatString/localized-format-string-list/localized-format-string-list.component';
import { UpdateLocalizedFormatStringComponent } from '../../components/localizedFormatString/update-localized-format-string/update-localized-format-string.component';
import { DeleteLocalizedFormatStringComponent } from '../../components/localizedFormatString/delete-localized-format-string/delete-localized-format-string.component';
import { LocalizedFormatStringDetailsComponent } from '../../components/localizedFormatString/localized-format-string-details/localized-format-string-details.component';
import { CreateTenantComponent } from '../../components/tenants/create-tenant/create-tenant.component';
import { UpdateTenantComponent } from '../../components/tenants/update-tenant/update-tenant.component';
import { DeleteTenantComponent } from '../../components/tenants/delete-tenant/delete-tenant.component';
import { GetTenantsTableComponent } from '../../components/tenants/get-tenants-table/get-tenants-table.component';
import { GetTenantDetailsComponent } from '../../components/tenants/get-tenant-details/get-tenant-details.component';
import { FormattedDatePipe } from 'projects/usorta-library/src/pipes/formatted-date.pipe';
import { CreateMenuComponent } from '../../components/navigation/create-menu/create-menu.component';
import { MenuListTableComponent } from '../../components/navigation/menu-list-table/menu-list-table.component';
import { UpdateMenuComponent } from '../../components/navigation/update-menu/update-menu.component';
import { MenuDetailsComponent } from '../../components/navigation/menu-details/menu-details.component';
import { DeleteMenuComponent } from '../../components/navigation/delete-menu/delete-menu.component';
import { MenuItemListComponent } from '../../components/navigation/menu-item-list/menu-item-list.component';
import { CreateMenuItemComponent } from '../../components/navigation/create-menu-item/create-menu-item.component';
import { UpdateMenuItemComponent } from '../../components/navigation/update-menu-item/update-menu-item.component';
import { DeleteMenuItemComponent } from '../../components/navigation/delete-menu-item/delete-menu-item.component';
import { MenuItemDetailsComponent } from '../../components/navigation/menu-item-details/menu-item-details.component';
import { CreateLocalizedMenuComponent } from '../../components/navigation/create-localized-menu/create-localized-menu.component';
import { UpdateLocalizedMenuComponent } from '../../components/navigation/update-localized-menu/update-localized-menu.component';
import { DeleteLocalizedMenuComponent } from '../../components/navigation/delete-localized-menu/delete-localized-menu.component';
import { LocalizedMenuListComponent } from '../../components/navigation/localized-menu-list/localized-menu-list.component';
import { LocalizedMenuDetailsComponent } from '../../components/navigation/localized-menu-details/localized-menu-details.component';
import { CreateLocalizedItemMenuComponent } from '../../components/navigation/create-localized-item-menu/create-localized-item-menu.component';
import { UpdateLocalizedItemMenuComponent } from '../../components/navigation/update-localized-item-menu/update-localized-item-menu.component';
import { DeleteLocalizedItemMenuComponent } from '../../components/navigation/delete-localized-item-menu/delete-localized-item-menu.component';
import { LocalizedItemMenuListComponent } from '../../components/navigation/localized-item-menu-list/localized-item-menu-list.component';
import { LocalizedItemMenuDetailsComponent } from '../../components/navigation/localized-item-menu-details/localized-item-menu-details.component';
import { TypedStringsComponent } from '../../components/typed-strings/typed-strings.component';
import { DeleteQuestionSetComponent } from '../../components/questionManagement/delete-question-set/delete-question-set.component';
import { QuestionsTableComponent } from '../../components/questionManagement/questions-table/questions-table.component';
import { DeleteQuestionComponent } from '../../components/questionManagement/delete-question/delete-question.component';
import { DeleteQuestionOptionComponent } from '../../components/questionManagement/delete-question-option/delete-question-option.component';
import { QuestionOptionDetailsComponent } from '../../components/questionManagement/question-option-details/question-option-details.component';
import { AutocompleteInputComponent } from '../../components/autocomplete-input/autocomplete-input.component';
import { AddQuestionTranslationComponent } from '../../components/questionManagement/add-question-translation/add-question-translation.component';
import { VmSessionGroupsListComponent } from '../../components/connection-manager/vm-session-groups-list/vm-session-groups-list.component';
import { VmSessionConnectionsListComponent } from '../../components/connection-manager/vm-session-connections-list/vm-session-connections-list.component';
import { VmSessionsListComponent } from '../../components/connection-manager/vm-sessions-list/vm-sessions-list.component';
import { VmConnectionsListComponent } from '../../components/connection-manager/vm-connections-list/vm-connections-list.component';
import { CreateBaseVmImageComponent } from '../../components/base-vm-images/create-base-vm-image/create-base-vm-image.component';
import { UploadBaseVmImageComponent } from '../../components/base-vm-images/upload-base-vm-image/upload-base-vm-image.component';
import { CreateSetupVmInstanceComponent } from '../../components/setupVMInstance/create-setup-vm-instance/create-setup-vm-instance.component';
import { UpdateBaseVmImageComponent } from '../../components/base-vm-images/update-base-vm-image/update-base-vm-image.component';
import { BaseVmImagesTableComponent } from '../../components/base-vm-images/base-vm-images-table/base-vm-images-table.component';
import { DeleteBaseVmImageComponent } from '../../components/base-vm-images/delete-base-vm-image/delete-base-vm-image.component';
import { BaseVmImageDetailComponent } from '../../components/base-vm-images/base-vm-image-detail/base-vm-image-detail.component';
import { ManageSetupVmInstanceComponent } from '../../components/setupVMInstance/manage-setup-vm-instance/manage-setup-vm-instance.component';
import { AddCourseTranslationComponent } from '../../components/educationManagement/add-course-translation/add-course-translation.component';
import { UpdateCourseTranslationComponent } from '../../components/educationManagement/update-course-translation/update-course-translation.component';
import { CourseTranslationDetailsComponent } from '../../components/educationManagement/course-translation-details/course-translation-details.component';
import { DeleteCourseTranslationComponent } from '../../components/educationManagement/delete-course-translation/delete-course-translation.component';
import { CourseTranslationListComponent } from '../../components/educationManagement/course-translation-list/course-translation-list.component';
import { ListQuestionsByQuestionSetIdComponent } from '../../components/questionManagement/list-questions-by-question-set-id/list-questions-by-question-set-id.component';
import { AddBeginDateFilterToCourseListComponent } from '../../components/course-lists/add-begin-date-filter-to-course-list/add-begin-date-filter-to-course-list.component';
import { AddCategoryFilterToCourseListComponent } from '../../components/course-lists/add-category-filter-to-course-list/add-category-filter-to-course-list.component';
import { AddEndDateFilterToCourseListComponent } from '../../components/course-lists/add-end-date-filter-to-course-list/add-end-date-filter-to-course-list.component';
import { AddLevelFilterToCourseListComponent } from '../../components/course-lists/add-level-filter-to-course-list/add-level-filter-to-course-list.component';
import { AddLocaleFilterToCourseListComponent } from '../../components/course-lists/add-locale-filter-to-course-list/add-locale-filter-to-course-list.component';
import { CreateCourseListComponent } from '../../components/course-lists/create-course-list/create-course-list.component';
import { DeleteCourseListComponent } from '../../components/course-lists/delete-course-list/delete-course-list.component';
import { UpdateCourseListComponent } from '../../components/course-lists/update-course-list/update-course-list.component';
import { CreateApplicationConfigurationComponent } from '../../components/application-configuration/create-application-configuration/create-application-configuration.component';
import { CreateTenantConfigurationComponent } from '../../components/application-configuration/create-tenant-configuration/create-tenant-configuration.component';
import { CreateApplicationTenantConfigurationComponent } from '../../components/application-configuration/create-application-tenant-configuration/create-application-tenant-configuration.component';
import { UploadConfigurationImageComponent } from '../../components/application-configuration/upload-configuration-image/upload-configuration-image.component';
import { ApplicationTenantListTableComponent } from '../../components/application-tenants/application-tenant-list-table/application-tenant-list-table.component';
import { CreateApplicationTenantComponent } from '../../components/application-tenants/create-application-tenant/create-application-tenant.component';
import { DeleteApplicationTenantComponent } from '../../components/application-tenants/delete-application-tenant/delete-application-tenant.component';
import { ApplicationListTableComponent } from '../../components/applications/application-list-table/application-list-table.component';
import { CreateApplicationComponent } from '../../components/applications/create-application/create-application.component';
import { DeleteApplicationComponent } from '../../components/applications/delete-application/delete-application.component';
import { UpdateApplicationComponent } from '../../components/applications/update-application/update-application.component';
import { CmsImageViewerComponent } from '../../components/cms-image-viewer/cms-image-viewer.component';
import { ApplicationDetailsComponent } from '../../components/applications/application-details/application-details.component';
import { TranslateModeHelperComponent } from '../../components/translate-mode-helper/translate-mode-helper.component';
import { TranslatePipe } from 'projects/usorta-library/src/pipes/translate.pipe';
import { UpdateLocalizedStringWithKeyComponent } from '../../components/localizationString/update-localized-string-with-key/update-localized-string-with-key.component';
import { GetLocalizedStringTableWithKeyComponent } from '../../components/localizationString/get-localized-string-table-with-key/get-localized-string-table-with-key.component';
import { CreateUserRoleComponent } from '../../components/access-control/roles/user/create-user-role/create-user-role.component';
import { UpdateUserRoleComponent } from '../../components/access-control/roles/user/update-user-role/update-user-role.component';
import { CreateServiceRoleComponent } from '../../components/access-control/roles/service/create-service-role/create-service-role.component';
import { UpdateServiceRoleComponent } from '../../components/access-control/roles/service/update-service-role/update-service-role.component';
import { CreateAdminRoleComponent } from '../../components/access-control/roles/admin/create-admin-role/create-admin-role.component';
import { UpdateAdminRoleComponent } from '../../components/access-control/roles/admin/update-admin-role/update-admin-role.component';
import { ListAdminRolesComponent } from '../../components/access-control/roles/admin/list-admin-roles/list-admin-roles.component';
import { ListServiceRolesComponent } from '../../components/access-control/roles/service/list-service-roles/list-service-roles.component';
import { ListUserRolesComponent } from '../../components/access-control/roles/user/list-user-roles/list-user-roles.component';
import { DeleteAdminRoleComponent } from '../../components/access-control/roles/admin/delete-admin-role/delete-admin-role.component';
import { DeleteServiceRoleComponent } from '../../components/access-control/roles/service/delete-service-role/delete-service-role.component';
import { DeleteUserRoleComponent } from '../../components/access-control/roles/user/delete-user-role/delete-user-role.component';
import { UserRoleDetailsComponent } from '../../components/access-control/roles/user/user-role-details/user-role-details.component';
import { ServiceRoleDetailsComponent } from '../../components/access-control/roles/service/service-role-details/service-role-details.component';
import { AdminRoleDetailsComponent } from '../../components/access-control/roles/admin/admin-role-details/admin-role-details.component';
import { AddRoleToUserComponent } from '../../components/access-control/roles/user/add-role-to-user/add-role-to-user.component';
import { AddRoleToServiceComponent } from '../../components/access-control/roles/service/add-role-to-service/add-role-to-service.component';
import { AddRoleToAdminComponent } from '../../components/access-control/roles/admin/add-role-to-admin/add-role-to-admin.component';
import { GetAssignedUserRolesComponent } from '../../components/access-control/roles/user/get-assigned-user-roles/get-assigned-user-roles.component';
import { GetAssignedAdminRolesComponent } from '../../components/access-control/roles/admin/get-assigned-admin-roles/get-assigned-admin-roles.component';
import { GetAssignedServiceRolesComponent } from '../../components/access-control/roles/service/get-assigned-service-roles/get-assigned-service-roles.component';
import { DeleteRoleFromUserComponent } from '../../components/access-control/roles/delete-role-from-user/delete-role-from-user.component';
import { AddSelectedRoleToUsersComponent } from '../../components/access-control/roles/user/add-selected-role-to-users/add-selected-role-to-users.component';
import { UsernameFilterPipe } from 'projects/usorta-library/src/pipes/username-filter.pipe';
import { VideoTimelineComponent } from '../../components/video-timeline/video-timeline.component';
import { VideoTimelineEditorComponent } from '../../components/video-timeline-editor/video-timeline-editor.component';
import { LmsVideoPlayerComponent } from '../../components/lms-video-player/lms-video-player.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  var tenantId = environment.tenantId;
  var tenantQuery = tenantId == '' || tenantId == null ? "" : `?tenantId=${tenantId}`;
  return new TranslateHttpLoader(httpClient, environment.apiUrl + "api/cms/i18n/strings/" + environment.applicationId + "/", tenantQuery);
}
@NgModule({
  declarations: [
    LmsVideoPlayerComponent,
    VideoTimelineEditorComponent,
    VideoTimelineComponent,
    AddSelectedRoleToUsersComponent,
    UsernameFilterPipe,
    GetAssignedAdminRolesComponent,
    GetAssignedServiceRolesComponent,
    GetAssignedUserRolesComponent,
    AddRoleToAdminComponent,
    AddRoleToServiceComponent,
    AddRoleToUserComponent,
    DeleteAdminRoleComponent,
    DeleteServiceRoleComponent,
    DeleteUserRoleComponent,
    UserRoleDetailsComponent,
    ServiceRoleDetailsComponent,
    AdminRoleDetailsComponent,
    ListAdminRolesComponent,
    ListServiceRolesComponent,
    ListUserRolesComponent,
    CreateUserRoleComponent,
    UpdateUserRoleComponent,
    CreateServiceRoleComponent,
    UpdateServiceRoleComponent,
    CreateAdminRoleComponent,
    UpdateAdminRoleComponent,
    GetLocalizedStringTableWithKeyComponent,
    UpdateLocalizedStringWithKeyComponent,
    TranslatePipe,
    TranslateModeHelperComponent,
    ApplicationListTableComponent,
    CreateApplicationComponent,
    UpdateApplicationComponent,
    ApplicationDetailsComponent,
    DeleteApplicationComponent,
    ApplicationTenantListTableComponent,
    CmsImageViewerComponent,
    CreateApplicationTenantComponent,
    DeleteApplicationTenantComponent,
    DeleteRoleFromUserComponent,
    UploadConfigurationImageComponent,
    CreateTenantConfigurationComponent,
    CreateApplicationTenantConfigurationComponent,
    CreateApplicationConfigurationComponent,
    CourseTranslationListComponent,
    AddCourseTranslationComponent,
    UpdateCourseTranslationComponent,
    CourseTranslationDetailsComponent,
    DeleteCourseTranslationComponent,
    AutocompleteInputComponent,
    CreateSetupVmInstanceComponent,
    DeleteQuestionOptionComponent,
    DeleteQuestionSetComponent,
    TypedStringsComponent,
    CreateLocalizedMenuComponent,
    UpdateLocalizedMenuComponent,
    DeleteLocalizedMenuComponent,
    CreateCourseListComponent,
    UpdateCourseListComponent,
    DeleteCourseListComponent,
    AddCategoryFilterToCourseListComponent,
    AddLevelFilterToCourseListComponent,
    AddLocaleFilterToCourseListComponent,
    AddBeginDateFilterToCourseListComponent,
    AddEndDateFilterToCourseListComponent,
    LocalizedMenuListComponent,
    LocalizedMenuDetailsComponent,
    ListQuestionsByQuestionSetIdComponent,
    CreateLocalizedItemMenuComponent,
    UpdateLocalizedItemMenuComponent,
    DeleteLocalizedItemMenuComponent,
    QuestionOptionDetailsComponent,
    LocalizedItemMenuListComponent,
    LocalizedItemMenuDetailsComponent,
    CreateBaseVmImageComponent,
    UploadBaseVmImageComponent,
    UpdateMenuComponent,
    MenuDetailsComponent,
    DeleteMenuComponent,
    MenuItemListComponent,
    CreateMenuItemComponent,
    VmConnectionsListComponent,
    VmSessionsListComponent,
    VmSessionConnectionsListComponent,
    VmSessionGroupsListComponent,
    UpdateMenuItemComponent,
    DeleteMenuItemComponent,
    MenuItemDetailsComponent,
    CreateMenuComponent,
    MenuListTableComponent,
    CreateTenantComponent,
    UpdateTenantComponent,
    UpdateBaseVmImageComponent,
    BaseVmImagesTableComponent,
    DeleteBaseVmImageComponent,
    BaseVmImageDetailComponent,
    DeleteTenantComponent,
    GetTenantsTableComponent,
    GetTenantDetailsComponent,
    DeleteQuestionComponent,
    UpdateLocalizedFormatStringComponent,
    ManageSetupVmInstanceComponent,
    LocalizedFormatStringDetailsComponent,
    DeleteLocalizedFormatStringComponent,
    LocalizedFormatStringListComponent,
    DeleteLocalizedStringComponent,
    UpdateLocalizedStringComponent,
    LocalizedStringTableComponent,
    LocalizedStringComponent,
    CreateLocalizedStringComponent,
    VmInstanceTypeListComponent,
    PreloaderComponent,
    LocalTranslatePipe,
    FormattedDatePipe,
    SanitizedHtmlPipe,
    LoginComponent,
    CookiePreferencesComponent,
    FooterComponent,
    ForgotPasswordComponent,
    CreateLocalizedFormatStringComponent,
    StaticContentComponent,
    CustomBlockComponent,
    CustomTableComponent,
    CustomNavbarComponent,
    CourseOutlineComponent,
    AddQuestionTranslationComponent,
    LandingMainComponent,
    CustomFormComponent,
    AddAttributesDirective,
    CustomTableEditorComponent,
    CustomLayoutComponent,
    CustomLayoutBlockComponent,
    PreviewTextComponent,
    CustomLayoutRegionComponent,
    CustomMenuComponent,
    CustomChartComponent,
    PieChartComponent,
    BarChartComponent,
    AreaChartComponent,
    ScatterChartComponent,
    LineChartComponent,
    PolarAreaChartComponent,
    RadarChartComponent,
    TerminalChatSenderComponent,
    RtcVideoComponent,
    ConnectedFromOtherComponent,
    SessionLoaderComponent,
    PopoutComponent,
    PopoutMessageComponent,
    BasicTableComponent,
    GlobalModalComponent,
    CourseOutlineComponent,
    NoteEditComponent,
    DynamicFormToJsonComponent,
    SessionManagementPageComponent,
    SessionManagementAddFeatureComponent,
    SessionManagementAddItemComponent,
    ImportFeaturesComponent,
    SessionManagementDeleteFeatureComponent,
    SessionManagementListFeaturesComponent,
    SessionManagementListItemsComponent,
    SessionManagementDeleteItemComponent,
    SessionManagementUpdateSessionComponent,
    SessionManagementSessionMembersComponent,
    SessionManagementRemoveMemberFromSessionComponent,
    SessionManagementAddMemberToSessionComponent,
    NewEducationComponent,
    UpdateEducationComponent,
    AddEducationCategoryComponent,
    EducationDetailsComponent,
    EducationDetailsBasicComponent,
    EducationDetailsOutlineListComponent,
    EducationDetailsDetailAreaComponent,
    AddSectionToEducationComponent,
    AddSubsectionToSectionComponent,
    AddOutlineToEducationComponent,
    UpdateEducationSectionComponent,
    UpdateEducationSubsectionComponent,
    UpdateEducationCategoryComponent,
    EducationCategoryDetailsComponent,
    DeleteEducationCategoryComponent,
    CreateEducationThemeComponent,
    DeleteEducationThemeComponent,
    UpdateEducationThemeComponent,
    AddCourseContentComponent,
    EducationSectionDetailsComponent,
    AddNewVersionComponent,
    VersionDetailsComponent,
    VersionDetailsSectionsComponent,
    VersionDetailsBasicComponent,
    CreateQuestionSetComponent,
    CreateQuestionComponent,
    CreateQuestionOptionComponent,
    QuestionDetailsComponent,
    UpdateQuestionSetComponent,
    UpdateQuestionComponent,
    QuestionSetListComponent,
    QuestionSetDetailsComponent,
    ShowQuestionComponent,
    UpdateQuestionOptionComponent,
    AddExamComponent,
    CreateVmEnvironmentComponent,
    UpdateVmEnvironmentComponent,
    DeleteVmEnvironmentComponent,
    AddVirtualMachineComponent,
    UpdateVirtualMachineComponent,
    VirtualMachineDetailsComponent,
    CustomizeSetRootPasswordComponent,
    CustomizeAddNewUserComponent,
    CustomizeChangePasswordComponent,
    CustomizeInstallPackagesComponent,
    CustomizeCopyFilesComponent,
    CustomizeRunScriptsComponent,
    CreateLabComponent,
    ManageLabComponent,
    AddVmToLabComponent,
    AddControlCriteriaComponent,
    UpdateLabComponent,
    UpdateVmInLabComponent,
    UpdateControlCriteriaComponent,
    AddCategoryTreeComponent,
    UpdateCategoryTreeComponent,
    DeleteCategoryTreeComponent,
    CategoryTreeListComponent,
    EducationCategoryListComponent,
    CoursesTableComponent,
    EducationThemesTableComponent,
    NewInstallerImageComponent,
    VmEnvironmentListComponent,
    VirtualMachineListComponent,
    VirtualLabListComponent,
    EducationThemeDetailComponent,
    CourseContentDetailsComponent,
    DeleteCourseContentComponent,
    DeleteEducationComponent,
    UpdateEducationContentComponent,
    UpdateEducationVersionComponent,
    CreateInstallerImageComponent,
    DeleteInstallerImageComponent,
    UpdateInstallerImageComponent,
    UploadInstallerImageComponent,
    InstallerImageListComponent,
    InstallerImageDetailsComponent,
    ActiveConnectionsComponent,
    GuacamoleLmsSessionTerminalsComponent,
    CreateVmInstanceTypeComponent,
    UpdateVmInstanceTypeComponent,
    DeleteVmInstanceTypeComponent,
    ManageVmInstanceTypeComponent,
    CreateVmInstanceTypeMappingComponent,
    UpdateVmInstanceTypeMappingComponent,
    DeleteVmInstanceTypeMappingComponent,
    ManageVmInstanceTypeMappingComponent,
    ListVmInstanceTypeMappingComponent,
    CreateHostProviderTypeComponent,
    UpdateHostProviderTypeComponent,
    DeleteHostProviderTypeComponent,
    ListHostProviderTypeComponent,
    ManageHostProviderComponent,
    VmInstanceTypeMappingNoteComponent,
    VmEnvironmentDetailsComponent,
    HostDashboardComponent,
    HostDashboardCounterComponent,
    HostDashboardSwitchComponent,
    HostDashboardChartComponent,
    HostDashboardBasicComponent,
    AddLocaleComponent,
    UpdateLocaleComponent,
    DeleteLocaleComponent,
    LocalesTableComponent,
    LocaleDetailsComponent,
    QuestionsTableComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    CommonModule,
    GuacLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false,
      extend: true,
      useDefaultLang: false
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }
      }
    }),
    NgChartsModule,
    ImageCropperModule
  ],
  exports: [
    VideoTimelineEditorComponent,
    GetAssignedAdminRolesComponent,
    GetAssignedServiceRolesComponent,
    GetAssignedUserRolesComponent,
    GetLocalizedStringTableWithKeyComponent,
    TranslatePipe,
    DeleteLocalizedStringComponent,
    UpdateLocalizedStringWithKeyComponent,
    VmInstanceTypeListComponent,
    PreloaderComponent,
    LocalTranslatePipe,
    TranslateModeHelperComponent,
    FormattedDatePipe,
    SanitizedHtmlPipe,
    LoginComponent,
    CookiePreferencesComponent,
    FooterComponent,
    TranslateModule,
    ListQuestionsByQuestionSetIdComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ForgotPasswordComponent,
    StaticContentComponent,
    AddSelectedRoleToUsersComponent,
    UsernameFilterPipe,
    CustomBlockComponent,
    CustomTableComponent,
    CustomNavbarComponent,
    CourseOutlineComponent,
    LandingMainComponent,
    CustomFormComponent,
    AddAttributesDirective,
    CustomTableEditorComponent,
    CustomLayoutComponent,
    CustomLayoutBlockComponent,
    CustomLayoutRegionComponent,
    CustomMenuComponent,
    CustomChartComponent,
    PieChartComponent,
    BarChartComponent,
    AreaChartComponent,
    ScatterChartComponent,
    LineChartComponent,
    PolarAreaChartComponent,
    CreateCourseListComponent,
    UpdateCourseListComponent,
    DeleteCourseListComponent,
    AddCategoryFilterToCourseListComponent,
    AddLevelFilterToCourseListComponent,
    AddLocaleFilterToCourseListComponent,
    AddBeginDateFilterToCourseListComponent,
    AddEndDateFilterToCourseListComponent,
    RadarChartComponent,
    GuacClientComponent,
    GuacMultiscreenComponent,
    TerminalChatSenderComponent,
    RtcVideoComponent,
    ConnectedFromOtherComponent,
    SessionLoaderComponent,
    PopoutComponent,
    PopoutMessageComponent,
    BasicTableComponent,
    GlobalModalComponent,
    CourseOutlineComponent,
    NoteEditComponent,
    DynamicFormToJsonComponent,
    SessionManagementPageComponent,
    SessionManagementAddFeatureComponent,
    SessionManagementAddItemComponent,
    ImportFeaturesComponent,
    SessionManagementDeleteFeatureComponent,
    SessionManagementListFeaturesComponent,
    SessionManagementListItemsComponent,
    SessionManagementDeleteItemComponent,
    SessionManagementUpdateSessionComponent,
    SessionManagementSessionMembersComponent,
    SessionManagementRemoveMemberFromSessionComponent,
    SessionManagementAddMemberToSessionComponent,
    NewEducationComponent,
    UpdateEducationComponent,
    AddEducationCategoryComponent,
    EducationDetailsComponent,
    EducationDetailsBasicComponent,
    EducationDetailsOutlineListComponent,
    EducationDetailsDetailAreaComponent,
    AddSectionToEducationComponent,
    AddSubsectionToSectionComponent,
    AddOutlineToEducationComponent,
    UpdateEducationSectionComponent,
    UpdateEducationSubsectionComponent,
    UpdateEducationCategoryComponent,
    EducationCategoryDetailsComponent,
    DeleteEducationCategoryComponent,
    CreateEducationThemeComponent,
    DeleteEducationThemeComponent,
    UpdateEducationThemeComponent,
    AddCourseContentComponent,
    EducationSectionDetailsComponent,
    AddNewVersionComponent,
    VersionDetailsComponent,
    VersionDetailsSectionsComponent,
    VersionDetailsBasicComponent,
    CreateQuestionSetComponent,
    CreateQuestionComponent,
    CreateQuestionOptionComponent,
    QuestionDetailsComponent,
    UpdateQuestionSetComponent,
    UpdateQuestionComponent,
    QuestionSetListComponent,
    QuestionSetDetailsComponent,
    ShowQuestionComponent,
    UpdateQuestionOptionComponent,
    AddExamComponent,
    CreateVmEnvironmentComponent,
    UpdateVmEnvironmentComponent,
    DeleteVmEnvironmentComponent,
    AddVirtualMachineComponent,
    UpdateVirtualMachineComponent,
    VirtualMachineDetailsComponent,
    CustomizeSetRootPasswordComponent,
    CustomizeAddNewUserComponent,
    CustomizeChangePasswordComponent,
    CustomizeInstallPackagesComponent,
    CustomizeCopyFilesComponent,
    CustomizeRunScriptsComponent,
    CreateLabComponent,
    ManageLabComponent,
    AddVmToLabComponent,
    AddControlCriteriaComponent,
    UpdateLabComponent,
    UpdateVmInLabComponent,
    UpdateControlCriteriaComponent,
    AddCategoryTreeComponent,
    UpdateCategoryTreeComponent,
    DeleteCategoryTreeComponent,
    CategoryTreeListComponent,
    EducationCategoryListComponent,
    CoursesTableComponent,
    EducationThemesTableComponent,
    NewInstallerImageComponent,
    VmEnvironmentListComponent,
    VirtualMachineListComponent,
    VirtualLabListComponent,
    EducationThemeDetailComponent,
    CourseContentDetailsComponent,
    DeleteCourseContentComponent,
    DeleteEducationComponent,
    UpdateEducationContentComponent,
    UpdateEducationVersionComponent,
    CreateInstallerImageComponent,
    DeleteInstallerImageComponent,
    UpdateInstallerImageComponent,
    UploadInstallerImageComponent,
    InstallerImageListComponent,
    InstallerImageDetailsComponent,
    ActiveConnectionsComponent,
    GuacamoleLmsSessionTerminalsComponent,
    CreateVmInstanceTypeComponent,
    UpdateVmInstanceTypeComponent,
    DeleteVmInstanceTypeComponent,
    ManageVmInstanceTypeComponent,
    CreateVmInstanceTypeMappingComponent,
    UpdateVmInstanceTypeMappingComponent,
    DeleteVmInstanceTypeMappingComponent,
    ManageVmInstanceTypeMappingComponent,
    ListVmInstanceTypeMappingComponent,
    CreateHostProviderTypeComponent,
    UpdateHostProviderTypeComponent,
    DeleteHostProviderTypeComponent,
    ListHostProviderTypeComponent,
    ManageHostProviderComponent,
    VmInstanceTypeMappingNoteComponent,
    VmEnvironmentDetailsComponent,
    HostDashboardComponent,
    HostDashboardCounterComponent,
    HostDashboardSwitchComponent,
    HostDashboardChartComponent,
    HostDashboardBasicComponent,
    AddLocaleComponent,
    UpdateLocaleComponent,
    DeleteLocaleComponent,
    LocalesTableComponent,
    LocaleDetailsComponent,
    LocalizedStringComponent,
    CreateLocalizedStringComponent,
    UpdateLocalizedStringComponent,
    LocalizedStringTableComponent,
    PreviewTextComponent,
    CreateLocalizedFormatStringComponent,
    UpdateLocalizedFormatStringComponent,
    DeleteLocalizedFormatStringComponent,
    LocalizedFormatStringListComponent,
    LocalizedFormatStringDetailsComponent,
    CreateTenantComponent,
    UpdateTenantComponent,
    DeleteTenantComponent,
    GetTenantsTableComponent,
    GetTenantDetailsComponent,
    CreateMenuComponent,
    MenuListTableComponent,
    UpdateMenuComponent,
    MenuDetailsComponent,
    DeleteMenuComponent,
    MenuItemListComponent,
    CreateMenuItemComponent,
    UpdateMenuItemComponent,
    DeleteMenuItemComponent,
    MenuItemDetailsComponent,
    CreateLocalizedMenuComponent,
    UpdateLocalizedMenuComponent,
    DeleteLocalizedMenuComponent,
    LocalizedMenuListComponent,
    LocalizedMenuDetailsComponent,
    CreateLocalizedItemMenuComponent,
    UpdateLocalizedItemMenuComponent,
    DeleteLocalizedItemMenuComponent,
    LocalizedItemMenuListComponent,
    LocalizedItemMenuDetailsComponent,
    TypedStringsComponent,
    DeleteQuestionSetComponent,
    QuestionsTableComponent,
    DeleteQuestionComponent,
    DeleteQuestionOptionComponent,
    QuestionOptionDetailsComponent,
    AutocompleteInputComponent,
    AddQuestionTranslationComponent,
    VmConnectionsListComponent,
    VmSessionsListComponent,
    VmSessionConnectionsListComponent,
    VmSessionGroupsListComponent,
    CreateBaseVmImageComponent,
    UploadBaseVmImageComponent,
    CreateSetupVmInstanceComponent,
    UpdateBaseVmImageComponent,
    BaseVmImagesTableComponent,
    DeleteBaseVmImageComponent,
    BaseVmImageDetailComponent,
    ManageSetupVmInstanceComponent,
    AddCourseTranslationComponent,
    UpdateCourseTranslationComponent,
    CourseTranslationDetailsComponent,
    DeleteCourseTranslationComponent,
    CourseTranslationListComponent,
    CreateApplicationConfigurationComponent,
    CreateTenantConfigurationComponent,
    CreateApplicationTenantConfigurationComponent,
    UploadConfigurationImageComponent,
    ApplicationListTableComponent,
    CreateApplicationComponent,
    UpdateApplicationComponent,
    DeleteApplicationComponent,
    VideoTimelineComponent,
    ApplicationTenantListTableComponent,
    CreateApplicationTenantComponent,
    DeleteApplicationTenantComponent,
    CmsImageViewerComponent,
    ApplicationDetailsComponent,
    CreateUserRoleComponent,
    UpdateUserRoleComponent,
    CreateServiceRoleComponent,
    UpdateServiceRoleComponent,
    CreateAdminRoleComponent,
    UpdateAdminRoleComponent,
    ListAdminRolesComponent,
    ListServiceRolesComponent,
    ListUserRolesComponent,
    DeleteAdminRoleComponent,
    DeleteServiceRoleComponent,
    DeleteUserRoleComponent,
    UserRoleDetailsComponent,
    ServiceRoleDetailsComponent,
    AdminRoleDetailsComponent,
    AddRoleToAdminComponent,
    AddRoleToServiceComponent,
    AddRoleToUserComponent,
    DeleteRoleFromUserComponent,
    LmsVideoPlayerComponent
  ],
  entryComponents: [
    LmsVideoPlayerComponent,
    VideoTimelineEditorComponent,
    DeleteRoleFromUserComponent,
    GetAssignedAdminRolesComponent,
    GetAssignedServiceRolesComponent,
    GetAssignedUserRolesComponent,
    DeleteAdminRoleComponent,
    DeleteServiceRoleComponent,
    DeleteUserRoleComponent,
    UserRoleDetailsComponent,
    ServiceRoleDetailsComponent,
    AdminRoleDetailsComponent,
    ListAdminRolesComponent,
    ListServiceRolesComponent,
    ListUserRolesComponent,
    CreateUserRoleComponent,
    UpdateUserRoleComponent,
    CreateServiceRoleComponent,
    UpdateServiceRoleComponent,
    CreateAdminRoleComponent,
    UpdateAdminRoleComponent,
    GetLocalizedStringTableWithKeyComponent,
    UpdateLocalizedStringWithKeyComponent,
    TranslateModeHelperComponent,
    ApplicationDetailsComponent,
    ApplicationListTableComponent,
    CreateApplicationComponent,
    UpdateApplicationComponent,
    DeleteApplicationComponent,
    ApplicationTenantListTableComponent,
    CreateApplicationTenantComponent,
    DeleteApplicationTenantComponent,
    UploadConfigurationImageComponent,
    CreateTenantConfigurationComponent,
    CreateApplicationTenantConfigurationComponent,
    CreateApplicationConfigurationComponent,
    CourseTranslationListComponent,
    AddCourseTranslationComponent,
    UpdateCourseTranslationComponent,
    CourseTranslationDetailsComponent,
    DeleteCourseTranslationComponent,
    UpdateBaseVmImageComponent,
    BaseVmImagesTableComponent,
    DeleteBaseVmImageComponent,
    BaseVmImageDetailComponent,
    CreateSetupVmInstanceComponent,
    CreateBaseVmImageComponent,
    UploadBaseVmImageComponent,
    VmConnectionsListComponent,
    VmSessionsListComponent,
    VmSessionConnectionsListComponent,
    VmSessionGroupsListComponent,
    AddQuestionTranslationComponent,
    AutocompleteInputComponent,
    QuestionOptionDetailsComponent,
    DeleteQuestionOptionComponent,
    DeleteQuestionComponent,
    QuestionsTableComponent,
    TypedStringsComponent,
    DeleteQuestionSetComponent,
    CreateLocalizedMenuComponent,
    UpdateLocalizedMenuComponent,
    DeleteLocalizedMenuComponent,
    LocalizedMenuListComponent,
    ListQuestionsByQuestionSetIdComponent,
    ManageSetupVmInstanceComponent,
    LocalizedMenuDetailsComponent,
    CreateLocalizedItemMenuComponent,
    UpdateLocalizedItemMenuComponent,
    DeleteLocalizedItemMenuComponent,
    LocalizedItemMenuListComponent,
    LocalizedItemMenuDetailsComponent,
    UpdateMenuComponent,
    MenuDetailsComponent,
    DeleteMenuComponent,
    MenuItemListComponent,
    CreateMenuItemComponent,
    UpdateMenuItemComponent,
    DeleteMenuItemComponent,
    MenuItemDetailsComponent,
    CreateMenuComponent,
    MenuListTableComponent,
    CreateTenantComponent,
    UpdateTenantComponent,
    DeleteTenantComponent,
    GetTenantsTableComponent,
    GetTenantDetailsComponent,
    LocalizedFormatStringDetailsComponent,
    UpdateLocalizedFormatStringComponent,
    DeleteLocalizedFormatStringComponent,
    LocalizedFormatStringListComponent,
    CreateLocalizedFormatStringComponent,
    DeleteLocalizedStringComponent,
    PreviewTextComponent,
    UpdateLocalizedStringComponent,
    LocalizedStringTableComponent,
    LocalizedStringComponent,
    CreateLocalizedStringComponent,
    AddLocaleComponent,
    UpdateLocaleComponent,
    DeleteLocaleComponent,
    LocalesTableComponent,
    LocaleDetailsComponent,
    VmInstanceTypeListComponent,
    PreloaderComponent,
    LoginComponent,
    CookiePreferencesComponent,
    ForgotPasswordComponent,
    StaticContentComponent,
    CustomBlockComponent,
    CustomTableComponent,
    CustomNavbarComponent,
    CustomFormComponent,
    CustomTableEditorComponent,
    CustomLayoutComponent,
    CustomLayoutBlockComponent,
    CustomLayoutRegionComponent,
    CustomMenuComponent,
    CustomChartComponent,
    PieChartComponent,
    BarChartComponent,
    AreaChartComponent,
    ScatterChartComponent,
    LineChartComponent,
    PolarAreaChartComponent,
    RadarChartComponent,
    GuacClientComponent,
    GuacMultiscreenComponent,
    TerminalChatSenderComponent,
    RtcVideoComponent,
    ConnectedFromOtherComponent,
    SessionLoaderComponent,
    PopoutComponent,
    PopoutMessageComponent,
    BasicTableComponent,
    GlobalModalComponent,
    CourseOutlineComponent,
    NoteEditComponent,
    DynamicFormToJsonComponent,
    SessionManagementPageComponent,
    SessionManagementAddFeatureComponent,
    SessionManagementAddItemComponent,
    ImportFeaturesComponent,
    SessionManagementDeleteFeatureComponent,
    SessionManagementListFeaturesComponent,
    SessionManagementListItemsComponent,
    SessionManagementDeleteItemComponent,
    SessionManagementUpdateSessionComponent,
    SessionManagementSessionMembersComponent,
    SessionManagementRemoveMemberFromSessionComponent,
    SessionManagementAddMemberToSessionComponent,
    NewEducationComponent,
    UpdateEducationComponent,
    AddEducationCategoryComponent,
    EducationDetailsComponent,
    EducationDetailsBasicComponent,
    EducationDetailsOutlineListComponent,
    EducationDetailsDetailAreaComponent,
    AddSectionToEducationComponent,
    AddSubsectionToSectionComponent,
    AddOutlineToEducationComponent,
    UpdateEducationSectionComponent,
    UpdateEducationSubsectionComponent,
    UpdateEducationCategoryComponent,
    EducationCategoryDetailsComponent,
    DeleteEducationCategoryComponent,
    CreateEducationThemeComponent,
    DeleteEducationThemeComponent,
    UpdateEducationThemeComponent,
    AddCourseContentComponent,
    EducationSectionDetailsComponent,
    AddNewVersionComponent,
    VersionDetailsComponent,
    VersionDetailsSectionsComponent,
    VersionDetailsBasicComponent,
    CreateQuestionSetComponent,
    CreateQuestionComponent,
    CreateQuestionOptionComponent,
    QuestionDetailsComponent,
    UpdateQuestionSetComponent,
    UpdateQuestionComponent,
    CreateCourseListComponent,
    UpdateCourseListComponent,
    DeleteCourseListComponent,
    AddCategoryFilterToCourseListComponent,
    AddLevelFilterToCourseListComponent,
    AddLocaleFilterToCourseListComponent,
    AddBeginDateFilterToCourseListComponent,
    AddEndDateFilterToCourseListComponent,
    QuestionSetListComponent,
    QuestionSetDetailsComponent,
    ShowQuestionComponent,
    UpdateQuestionOptionComponent,
    AddExamComponent,
    CreateVmEnvironmentComponent,
    UpdateVmEnvironmentComponent,
    DeleteVmEnvironmentComponent,
    AddVirtualMachineComponent,
    UpdateVirtualMachineComponent,
    VirtualMachineDetailsComponent,
    CustomizeSetRootPasswordComponent,
    CustomizeAddNewUserComponent,
    CustomizeChangePasswordComponent,
    CustomizeInstallPackagesComponent,
    CustomizeCopyFilesComponent,
    CustomizeRunScriptsComponent,
    CreateLabComponent,
    ManageLabComponent,
    AddVmToLabComponent,
    AddControlCriteriaComponent,
    UpdateLabComponent,
    UpdateVmInLabComponent,
    UpdateControlCriteriaComponent,
    AddCategoryTreeComponent,
    UpdateCategoryTreeComponent,
    DeleteCategoryTreeComponent,
    CategoryTreeListComponent,
    VideoTimelineComponent,
    EducationCategoryListComponent,
    CoursesTableComponent,
    EducationThemesTableComponent,
    NewInstallerImageComponent,
    VmEnvironmentListComponent,
    VirtualMachineListComponent,
    VirtualLabListComponent,
    EducationThemeDetailComponent,
    CourseContentDetailsComponent,
    DeleteCourseContentComponent,
    DeleteEducationComponent,
    UpdateEducationContentComponent,
    UpdateEducationVersionComponent,
    CreateInstallerImageComponent,
    DeleteInstallerImageComponent,
    UpdateInstallerImageComponent,
    UploadInstallerImageComponent,
    InstallerImageListComponent,
    InstallerImageDetailsComponent,
    ActiveConnectionsComponent,
    GuacamoleLmsSessionTerminalsComponent,
    CreateVmInstanceTypeComponent,
    UpdateVmInstanceTypeComponent,
    DeleteVmInstanceTypeComponent,
    ManageVmInstanceTypeComponent,
    CreateVmInstanceTypeMappingComponent,
    UpdateVmInstanceTypeMappingComponent,
    DeleteVmInstanceTypeMappingComponent,
    ManageVmInstanceTypeMappingComponent,
    ListVmInstanceTypeMappingComponent,
    CreateHostProviderTypeComponent,
    UpdateHostProviderTypeComponent,
    DeleteHostProviderTypeComponent,
    ListHostProviderTypeComponent,
    ManageHostProviderComponent,
    VmInstanceTypeMappingNoteComponent,
    VmEnvironmentDetailsComponent,
    HostDashboardComponent,
    HostDashboardCounterComponent,
    HostDashboardSwitchComponent,
    HostDashboardChartComponent,
    AddSelectedRoleToUsersComponent,
    UsernameFilterPipe,
    HostDashboardBasicComponent,
    CmsImageViewerComponent,
    AddRoleToAdminComponent,
    AddRoleToServiceComponent,
    AddRoleToUserComponent

  ],
  providers: [
    TranslatePipe
  ]
})
export class GeneralModule {

}
