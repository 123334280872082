import { UpcomingSummaryModel } from './../../../../../usorta-library/src/lib/models/upcomingSummaryModel';
import { CourseService } from 'projects/usorta-library/src/lib/services/course/course.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upcoming-courses',
  templateUrl: './upcoming-courses.component.html',
  styleUrls: ['./upcoming-courses.component.css']
})
export class UpcomingCoursesComponent implements OnInit {

  constructor(private courseService:CourseService) { }
  upcomingSummary:UpcomingSummaryModel[];
  ngOnInit(): void {
    this.courseService.getUpcomingSummary().subscribe(response=>{
      this.upcomingSummary = response;
    })
  }

}
