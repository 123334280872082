import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VirtualLabService } from '../../../services/lab/virtual-lab.service';
import { HostProviderService } from '../../../services/hostProvider/host-provider.service';
import { ApiUrlService } from '../../../services/api/api-url.service';
import { SetupHostService } from '../../../services/setupHost/setup-host.service';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { ToastrService } from 'ngx-toastr';
import { SetupVmInstanceService } from '../../../services/setupVmInstance/setup-vm-instance.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-create-setup-vm-instance',
  templateUrl: './create-setup-vm-instance.component.html',
  styleUrls: ['./create-setup-vm-instance.component.css']
})
export class CreateSetupVmInstanceComponent implements OnInit {
  formUrl:string = this.apiUrlService.getApiUrl() + "api/range/vm/setupvminstances/create"
  baseUrl:string = this.apiUrlService.getApiUrl();
  instanceTypes:ListItemModel[];
  hosts:ListItemModel[];
  @ViewChild("hostElement") hostElement:ElementRef;
  @ViewChild("instanceTypeElement") instanceTypeElement:ElementRef;
  @ViewChild("externalForm") externalForm:ElementRef;

  constructor(private toastrService:ToastrService,private router:Router,private setupVmInstanceService:SetupVmInstanceService,private apiUrlService:ApiUrlService,private setupHostService:SetupHostService,private instanceTypeService:VmInstanceService) { }

  ngOnInit(): void {
    this.setupHostService.getSetupHostOptions().subscribe({
      next:(response)=>{
        this.hosts = response.content;
        this.instanceTypeService.getVmInstanceTypesWithProviderTypeAndHost(null,response.content[0].value).subscribe({
          next:(response)=>{
            this.instanceTypes = response.content;
          },
          error:(err)=>{
            this.toastrService.error("Bir hata oluştu");
          }
        });
        
      },error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  changeHost($ev:any){
    this.instanceTypeService.getVmInstanceTypesWithProviderTypeAndHost(null,$ev.target.value).subscribe({
      next:(response)=>{
        this.instanceTypes = response.content;
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    });
  }
  getObj($ev:any){
    console.log($ev);
    var hostValue = this.hostElement.nativeElement.value;
    var instanceTypeValue = this.instanceTypeElement.nativeElement.value;
    $ev.instanceTypeId = instanceTypeValue;
    $ev.hostId = hostValue;
    if($ev.diskSize == ""){
      $ev.diskSize = null;
    }
    if($ev.installerImageId == null && $ev.baseVMImageId == null){
      this.toastrService.error("Installer Image ya da Base VM Image seçilmelidir");
      return;
    }
    this.setupVmInstanceService.createSetupVmInstance($ev).subscribe({
      next:(response)=>{
        this.toastrService.success("Setup VM Instance created successfully");
        this.router.navigate(["/range/vm/setupinstance/"+response.content+"/manage"]);
      },error:(err)=>{
        this.toastrService.error("An error occurred");
      }
    })

  }
  

}
