import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlService } from '../api/api-url.service';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { BaseVMImageDetailModel } from '../../models/BaseVMImages/baseVmImageDetailModel';

@Injectable({
  providedIn: 'root'
})
export class BaseVmImageService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  deleteBaseVmImage(baseVmImageId:string){
    return this.httpClient.delete(this.apiUrlService.getApiUrl() + "api/range/vm/basevmimages/"+baseVmImageId+"/delete",{body:{id:baseVmImageId}})
  }
  getBaseVmImageDetails(id:string){
    return this.httpClient.get<SingleResponseModel<BaseVMImageDetailModel>>(this.apiUrlService.getApiUrl()+`api/range/vm/basevmimages/${id}/details`)
  }
}
