<div class="row notificationsContainer" >
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <h4 class="headerTitle">{{'notificationsTitle' | translate}}</h4>
        <hr style="margin-top: 3px;">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div>
          <app-mini-notification-item *ngFor="let notification of urgentNotifications" [notification]="notification"></app-mini-notification-item>
          <app-mini-notification-item *ngFor="let notification of otherNotifications" [notification]="notification"></app-mini-notification-item>
        </div>
      </div>
    </div>
  </div>
</div>
