import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { VmInstanceService } from '../../../services/vmInstanceTypes/vm-instance.service';
import { CreateVmInstanceTypeModel } from '../../../models/vmInstanceTypes/createVmInstanceTypeModel';
import { FormSubmitRedirectService } from '../../../services/formSubmitRedirect/form-submit-redirect.service';

@Component({
  selector: 'lib-create-vm-instance-type',
  templateUrl: './create-vm-instance-type.component.html',
  styleUrls: ['./create-vm-instance-type.component.css']
})
export class CreateVmInstanceTypeComponent implements OnInit {
  providerId:string | null = null;
  hostId:string | null = null;
  createVmInstanceForm:FormGroup;
  providerList:ListItemModel[];
  hostList:ListItemModel[];
  cpuList:ListItemModel[];
  ramList:ListItemModel[];
  constructor(private formRedirectService:FormSubmitRedirectService,private vmInstanceService:VmInstanceService,private formBuilder:FormBuilder,private activatedRoute:ActivatedRoute,private toastrService:ToastrService) { }
  
  ngOnInit(): void {
    this.createVmInstanceForm = this.formBuilder.group({
      typeId:["",Validators.required],
      name:["",Validators.required],
      description:[""],
      tags:[undefined],
      active:[false],
      ram:[],
      cpuCount:[]
    })

    this.getCpuCountList();
    this.getRamList();

  }

  getRamList(){
    this.vmInstanceService.getRamList().subscribe({
      next:(response)=>{
        this.ramList = response.content;
        this.createVmInstanceForm.get("ram")?.setValue(this.ramList[0].value);
      }
    })
  }
  getCpuCountList(){
    this.vmInstanceService.getCpuCountList().subscribe({
      next:(response)=>{
        this.cpuList = response.content;
        this.createVmInstanceForm.get("cpuCount")?.setValue(this.cpuList[0].value);
      }
    })
  }
  createInstanceType(){
    if(this.createVmInstanceForm.valid){
      var createModel:CreateVmInstanceTypeModel = Object.assign({},this.createVmInstanceForm.value);
      createModel.cpuCount = typeof(createModel.cpuCount) === "string" ? parseInt(createModel.cpuCount) : createModel.cpuCount;
      console.log(createModel);
      this.vmInstanceService.createVmInstanceType(createModel).subscribe({
        next:(response)=>{
          this.toastrService.success("Başarıyla oluşturuldu");
          this.formRedirectService.showModal("/instancetypes/{id}/manage",response.content)
        },error:(err)=>{
          this.toastrService.error("VM Instance Type oluşturulamadı");
        }
      })
    }else{
      this.toastrService.error("Formu kontrol edin");
    }
  }
}
