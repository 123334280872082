import { Component, Input, OnInit } from '@angular/core';
import { MemberStatusModel } from '../../../models/members/memberStatusModel';

@Component({
  selector: 'app-course-landing-members',
  templateUrl: './course-landing-members.component.html',
  styleUrls: ['./course-landing-members.component.css']
})
export class CourseLandingMembersComponent implements OnInit {
  @Input() members:MemberStatusModel[];
  constructor() { }

  ngOnInit(): void {
  }

}
