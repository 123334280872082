<div class="mainNotificationDiv" [class.important]="notification.urgent">
<!--<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12" style="text-align: right;">
        <span *ngIf="notification.snoozed" class="badge bg-darkyellow"><i class="bi bi-clock-history"></i></span>
      </div>
    </div>

  </div>


</div> -->
<div class="row" style="margin-bottom: 10px;">
  <div class="col-12">
    <div class="row">
      <div class="col-1 iconStyle"><i class="{{notification.icon}}"></i></div>
      <div class="col-10 messageStyle" *ngIf="notification.snoozed"><font *ngIf="notificationMessage" [innerHTML]="notificationMessage"></font></div>
      <div class="col-11 messageStyle" *ngIf="notification.snoozed == false"><font *ngIf="notificationMessage" [innerHTML]="notificationMessage"></font></div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div style="text-align: right;">
      <div style="display: inline-block;" *ngFor="let action of notification.actions">
        <button (click)="redirect(action)" *ngIf="(action.text && action.text != '')|| (action.icon && action.icon != '')" class="btn btn-sm btn-secondary customBtn" title="{{action.tooltip}}"><i *ngIf="action.icon" class="{{action.icon}}"></i><font *ngIf="action.text"> {{getText(action) | translate}}</font></button>
        <button *ngIf="notification.canSnooze" title="{{'notification.snooze' | translate }}" class="btn btn-sm btn-warning" (click)="snooze(notification)"><i class="bi bi-alarm"></i></button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-3">
    <div style="display: inline-block; font-size: 15px; width: 100%; text-align: left;"><span *ngIf="notification.snoozed" class="badge bg-darkyellow"><i class="bi bi-clock-history"></i></span></div>

  </div>
  <div class="col-9">
    <div class="dateTime" style="text-align: right;">{{notification.timestamp | date: 'dd/MM/yyyy HH:mm' }}</div>
  </div>
</div>
</div>

