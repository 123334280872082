import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../../models/ListItem/listItemModel';
import { CourseContentService } from '../../../services/education/course-content.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { CourseContentFlags } from '../../../models/CourseContentModels/courseContentFlags';
import { ListResponseModel } from '../../../models/listResponseModel';
import { Observable } from 'rxjs';
import { AddContentToSectionRequestModel } from '../../../models/CourseContentModels/addContentToSectionRequestModel';
import { UpdateContentInSectionModel } from '../../../models/CourseContentModels/updateContentInSectionModel';
import { CourseContentDetailsModel } from '../../../models/CourseContentModels/courseContentDetailsModel';
import { CourseContentUpdateInfo } from '../../../models/CourseContentModels/courseContentUpdateInfo';
declare var $:any;
declare var window:any;
@Component({
  selector: 'lib-update-education-content',
  templateUrl: './update-education-content.component.html',
  styleUrls: ['./update-education-content.component.css']
})
export class UpdateEducationContentComponent implements OnInit {
  flags:{flag:string,value:number}[] = [];
  sectionId:string;
  courseId:string;
  contentId:string;
  flagNumber = 0;
  selectedType:string = "";
  selectItemList:ListItemModel[] = [];
  loading:boolean = false;
  requestSending:boolean = false;
  currentContent:CourseContentUpdateInfo;
  constructor(private courseContentService:CourseContentService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.prepareFlagSelector();
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.contentId = param["contentId"];
        this.getContent(this.contentId);

      }
    })
  }
  getContent(contentId:string){
    this.courseContentService.getCourseContentUpdateInfo(contentId).subscribe({
      next:(response)=>{
        this.currentContent = response.content;
        setTimeout(()=>{
          this.initContent();
          this.optionsAutoCheck();
        },100)

      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu.")
      }
    })
  }
  createLayoutEditor(){
    window.initLayoutEditor($("#htmlLayoutEditor"));
  }
  initContent(){

    var contentType = $(document.getElementById("type")).val()
    switch(contentType){
      case "html":
        this.selectedType = contentType;
        setTimeout(()=>{
          this.createLayoutEditor();
        },150);
        break;
        case "media":
          var contentIdEl = document.getElementById("contentId") as HTMLInputElement;
          contentIdEl.value = this.currentContent.mediaId;
  
          break;
        case "file":
          var contentIdEl = document.getElementById("contentId") as HTMLInputElement;
          contentIdEl.value = this.currentContent.externalToolId;
  
          break;
        case "exam":
          var contentIdEl = document.getElementById("contentId") as HTMLInputElement;
          contentIdEl.value = this.currentContent.examId;
  
          break;
        case "question":
          var contentIdEl = document.getElementById("contentId") as HTMLInputElement;
          contentIdEl.value = this.currentContent.questionId;
  
          break;
        case "random-question":
          var contentIdEl = document.getElementById("contentId") as HTMLInputElement;
          contentIdEl.value = this.currentContent.questionQueryId;
  
          break;
        case "poll":
          var contentIdEl = document.getElementById("contentId") as HTMLInputElement;
          contentIdEl.value = this.currentContent.surveyId;
          break;
    }
  }
  changeType(event:any){
    var contentType = $(event.target).val()
    switch(contentType){
      case "html":
        this.selectedType = contentType;
        setTimeout(()=>{
          this.createLayoutEditor();
        },150);

        break;
      case "audio":
        var request = this.courseContentService.getAudioList();
        this.requestSender(request,contentType);

        break;
      case "image":
        var request = this.courseContentService.getImageList();
        this.requestSender(request,contentType);


        break;
      case "video":
        var request = this.courseContentService.getVideoList();
        this.requestSender(request,contentType);

        break;
      case "file":
        var request = this.courseContentService.getFileList();
        this.requestSender(request,contentType);

        break;
      case "exam":
        var request = this.courseContentService.getExamList();
        this.requestSender(request,contentType);

        break;
      case "question":
        var request = this.courseContentService.getQuestionList();
        this.requestSender(request,contentType);

        break;
      case "random-question":
        var request = this.courseContentService.getRandomQuestionList();
        this.requestSender(request,contentType);

        break;
      case "poll":
        var request = this.courseContentService.getPollList();
        this.requestSender(request,contentType);

        break;
    }
  }
  optionsAutoCheck(){
    var checks = document.getElementsByClassName("optionsFlag");
    for (let index = 0; index < checks.length; index++) {
      const element = checks[index] as HTMLInputElement;
      if(this.currentContent.options & parseInt(element.value)){
        element.checked = true;
      }
      
    }
  }
  prepareFlagSelector(){
    var temp:{flag:string,value:number}[] = [];
    var keys = Object.entries(CourseContentFlags);
    keys.forEach((val)=>{
      temp.push({flag:val[0],value:val[1]});
    })
    this.flags = temp;
    console.log(this.flags);
  }
  requestSender(req:Observable<ListResponseModel<ListItemModel>>,entityName:string){
    this.selectedType = "";
    this.loading = true;
    req.subscribe({
      next:(response)=>{
        this.loading = false;
        this.selectItemList = response.content;
        this.selectedType = entityName;
      },error:()=>{
        this.loading = false;
        this.toastrService.error("Veriler getirilemedi");
      }
    })
  }
  getValue():string{
    if(this.selectedType == "html"){
      return $("#htmlLayoutEditor").val();
    }else{
      var input = document.getElementById("contentId") as HTMLInputElement;
      return input.value;
    }
  }
  getFlagNumber():number{
    var flag = 0;
    var flagCheckList = document.getElementsByClassName("optionsFlag");
    for (let index = 0; index < flagCheckList.length; index++) {
      const element = flagCheckList[index] as HTMLInputElement;
      if(element.checked){
        flag+=parseInt(element.value);
      }
    }
    return flag;
  }
  typeToInt(){
    switch(this.selectedType){
      case "html":
        return 0;
      case "file":
        return 1;
      case "exam":
        return 2;
      case "poll":
        return 3;
      case "question":
        return 4;
      case "random-question":
        return 5;
      case "audio":
        return 6;
      case "image":
        return 6;
      case "video":
        return 6;
      case "media":
        return 6;
    }
    return 0;
  }
  updateContent(){
    try {
      var updateContentModel:UpdateContentInSectionModel = {
        contentId : this.contentId,
        type: this.typeToInt() as number,
        content: this.typeToInt() == 0 ? this.getValue() : undefined,
        options: this.getFlagNumber(),
        orderValue: parseInt((document.getElementById("sortValueInput") as HTMLInputElement).value ?? "0"),
        name: ((document.getElementById("title") as HTMLInputElement).value ?? ""),
        contentFormat: this.typeToInt() == 0 ? 0 : undefined,
        examId: this.typeToInt() == 2 ? this.getValue() : undefined,
        surveyId: this.typeToInt() == 3 ? this.getValue() : undefined,
        mediaId: this.typeToInt() == 6 ? this.getValue() : undefined,
        questionId: this.typeToInt() == 4 ? this.getValue() : undefined,
        questionQueryId: this.typeToInt() == 5 ? this.getValue() : undefined,
        externalToolId: this.typeToInt() == 1 ? this.getValue() : undefined
      }
      this.requestSending = true;
      this.courseContentService.updateCourseContent(updateContentModel).subscribe({
        next:(response)=>{
          this.requestSending = false;
          this.toastrService.success("İçerik güncellendi");
        },
        error:(err)=>{
          this.requestSending = false;
          this.toastrService.error("İşlem başarısız oldu");
        }
      })
      console.log(updateContentModel);
    }catch{
      this.toastrService.error("Bir hata oluştu");
    }
  }

}
