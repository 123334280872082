import { CourseItemModel } from './../../../../../usorta-library/src/lib/models/courseItemModel';
import { CourseDetails } from './../../../../../usorta-library/src/lib/models/courseDetails';
import { CourseService } from 'projects/usorta-library/src/lib/services/course/course.service';
import { FooterListenerService } from './../../../../../usorta-library/src/lib/services/listener/footer-listener.service';
import { Component, OnInit } from '@angular/core';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { UserInfo } from 'projects/usorta-library/src/lib/models/userInfo';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  currentUserInfo:UserInfo;
  courses:CourseItemModel[];
  queryUrl:string = "courses/getcourses";
  carousel1:string = "api/lms/courses/samples/carousel1";
  carousel2:string = "api/lms/courses/samples/carousel2";
  carousel3:string = "api/lms/courses/samples/carousel3";
  constructor(private loginService:LoginService,private courseService:CourseService) { }

  ngOnInit(): void {
    this.getUserInfo();

  }
  getUserInfo(){
    this.loginService.getUserInfo(false).subscribe(response=>{
      this.currentUserInfo = response.content;
    },error=>{
      console.log(error);
    }
    )
  }

}
