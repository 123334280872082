<div style="min-height: 95vh;">
<div class="row" *ngIf="landingPage != undefined">
  <div class="col-lg-3 col-md-3 col-sm-3">
    <div class="row">
      <div class="col-12">
        <button class="continueCourseBtn">Eğitime Devam Et</button>
        <app-course-landing-details [detailsModel]="landingPage.details"></app-course-landing-details>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <br/>
        <h5>Kurs Bölümleri</h5>
        <app-course-landing-outline [outlineModel]="landingPage.studentOutline"></app-course-landing-outline>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-6">
    <div class="row">
      <div class="col-12">
        <app-course-landing-info [courseDetails]="landingPage.details"></app-course-landing-info>
        <hr/>
        <h5>Kurs Dokümanları</h5>
        <hr/>
        <app-course-documents [courseDocuments]="landingPage.files"></app-course-documents>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-3">
    <div class="row">
      <div class="col-12">
        <h5>Duyurular</h5>
        <app-course-landing-announcements [announcements]="landingPage.announcements"></app-course-landing-announcements>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5>Katılımcılar</h5>
        <app-course-landing-members [members]="landingPage.members"></app-course-landing-members>
      </div>
    </div>
  </div>
</div>
</div>
<!-- Modal start -->
<div class="modal fade" id="announcementsModal" tabindex="-1" aria-labelledby="announcementsModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <!--<div class="modal-header">
        <h5 class="modal-title" id="announcementsModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>-->
      <div class="modal-body">
        <app-all-course-announcements [courseId]="courseId"></app-all-course-announcements>
      </div>
      <!--<div class="modal-footer">
      </div>-->
    </div>
  </div>
</div>
<!--modal end-->
