import { Component, Input, OnInit } from '@angular/core';
import { CourseInfoModel } from '../../../models/courses/courseInfoModel';
import { TenantService } from 'projects/usorta-library/src/lib/services/tenant/tenant.service';
import { TenantInfoModel } from 'projects/usorta-library/src/lib/models/TenantModels/tenantInfoModel';

@Component({
  selector: 'app-course-landing-info',
  templateUrl: './course-landing-info.component.html',
  styleUrls: ['./course-landing-info.component.css']
})
export class CourseLandingInfoComponent implements OnInit {
  @Input() courseDetails:CourseInfoModel
  siteInfo:TenantInfoModel;
  defaultImage:string;
  constructor(private tenantService:TenantService) { }

  ngOnInit(): void {
    let tenantInfo = localStorage.getItem('tenantInfo');
    if(tenantInfo){
      this.siteInfo = JSON.parse(tenantInfo).applicationConfiguration;
      this.defaultImage = this.siteInfo.defaultCourseImage;
    }
  }

}
