import { ActivatedRoute } from '@angular/router';
import { AllAnnouncementsModel } from './../../../models/announcements/allAnnouncementsModel';
import { Component, Input, OnInit } from '@angular/core';
import { AnnouncementsService } from '../../../services/announcements.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-all-course-announcements',
  templateUrl: './all-course-announcements.component.html',
  styleUrls: ['./all-course-announcements.component.css']
})
export class AllCourseAnnouncementsComponent implements OnInit {
  @Input() courseId:number;
  currentPage:number = 1;
  announcements:AllAnnouncementsModel;
  pageList:number[] = [];
  loading = false;
  constructor(private announcementService:AnnouncementsService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param)=>{
      this.courseId = param['courseId'];
      this.getAnnouncements(this.courseId,1).then(()=>{
        this.getPageList();

      });
    })

  }
  getAnnouncements(course:number,page:number):Promise<boolean>{
    this.loading = true;
    return new Promise((resolve,reject)=>{
      this.announcementService.getAllAnnouncements(course,page).subscribe({
        next:(response)=>{
          this.announcements = response;
          console.log("announcementsss")
          console.log(response)
          this.currentPage = page;
          this.loading = false;
          resolve(true);
        },
        error:(err)=>{
          this.toastrService.error("Bir hata oluştu");
          this.loading = false;
          resolve(false);
        }
      })
    })

  }
  getPageList(){
    this.pageList = [];
    let total = this.announcements.pagerData.totalCount;
    let pageItemCount = this.announcements.pagerData.pageSize;
    let pageCount = Math.ceil(total/pageItemCount);
    for(let i=0;i<pageCount;i++){
      this.pageList.push(i+1);
    }
  }
  changePage(page:number){
    this.getAnnouncements(this.courseId,page);
  }
  prev(){
    if(this.currentPage > 1){
      this.getAnnouncements(this.courseId,this.currentPage-1);
    }
  }
  next(){
    if(this.currentPage < this.pageList.length){
      this.getAnnouncements(this.courseId,this.currentPage+1);
    }

  }

}
