<div *ngIf="menuItems && menuItems.length > 0" class="navbarMain">
  <div class="settingsDiv">
    <div class="btn-group menu-top-flex">
      <img class="menuLogo" style="width: calc(100% - 70px);" *ngIf="tenantInfoModel !== undefined && tenantInfoModel.showLogoOnNavbar && logo" [src]="logo"/>
      <button type="button" class="userActionButton dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"
        aria-expanded="false">
        <i class="bi bi-person-circle"></i>
      </button>
      <ul class="dropdown-menu dropdown-menu-end">
        <li><button class="dropdown-item" (click)="logout()" data-bs-dismiss="offcanvas" data-bs-target="#mobileMenu"
            type="button">{{'logout' | translate}}</button></li>
      </ul>
    </div>
  </div>
  <hr class="settingsHr">
  <div class="navbarContent">
    <div *ngFor="let menuItem of menuItems">
      <div id="{{menuItem.id}}" class="menuitem" *ngIf="!menuItem.subItems || menuItem.subItems.length == 0"
        [class.active]="isActivePage(menuItem)" (click)="redirect(menuItem)"
        title="{{(menuItem.tooltip ?? '') | translate}}"><i [class]="menuItem.preIcon"></i> {{menuItem.title |
        translate}} <i [class]="menuItem.postIcon"></i></div>
      <div class="menuItems" *ngIf="menuItem.subItems && menuItem.subItems.length > 0">
        <div class="menuHeader" [class.collapsible]="false" [attr.data-bs-toggle]="collapsible  == true? 'collapse' : ''" [attr.data-bs-target]="'#collapsible_'+menuItem.id">{{menuItem.title | translate}}&nbsp;&nbsp;<i *ngIf="false" style="font-size: 14px; padding-right: 15px;" class="bi bi-caret-down-fill"></i></div>
        <div [class.collapse]="false" [class.show]="collapsible && defaultShowItems" [id]="'collapsible_'+menuItem.id">
          <div id="{{subItem.id}}" class="menuitem submenuitem" data-bs-dismiss="offcanvas" data-bs-target="#mobileMenu"
            [class.active]="isActivePage(subItem)" *ngFor="let subItem of menuItem.subItems"
            title="{{(subItem.tooltip ?? '') | translate}}" (click)="redirect(subItem)"><i
              [class]="subItem.preIcon"></i>
            {{subItem.title | translate}} <i [class]="subItem.postIcon"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="menuItems">
      <div class="menuHeader">====</div>
      <!--<div id="{{subItem.id}}" class="menuitem submenuitem" [class.active]="isActivePage(subItem)" *ngFor="let subItem of menuItem.subItems" title="{{subItem.tooltip}}" (click)="redirect(subItem)"><i [class]="subItem.preIcon"></i> {{subItem.title}} <i [class]="subItem.postIcon"></i></div>-->
    </div>
  </div>
</div>