<div *ngIf="notifications" class="mainNotificationsDiv container">
  <div class="allNotificationsContainer">
    <h4 class="headerTitle">{{'notificationsTitle' |translate}}</h4>
    <hr>
  <div class="notificationsList">
    <app-mini-notification-item *ngFor="let notification of urgentNotifications" [notification]="notification"></app-mini-notification-item>
    <app-mini-notification-item *ngFor="let notification of otherNotifications" [notification]="notification"></app-mini-notification-item>
  </div>
  <div *ngIf="pages" class="pages">
    <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group me-2" role="group" aria-label="First group">
        <button type="button" *ngFor="let page of pages" class="btn btn-secondary" (click)="getNotifications(page)">{{page}}</button>
      </div>
    </div>
  </div>
</div>
</div>
