import { LoginComponent } from './../../../usorta-library/src/lib/components/login/login.component';
import { SanitizedHtmlPipe } from './../../../usorta-library/src/pipes/sanitized-html.pipe';
import { CookiePreferencesComponent } from './../../../usorta-library/src/lib/components/cookie-preferences/cookie-preferences.component';
import { CustomBlockComponent } from './../../../usorta-library/src/lib/components/static-content/custom-block/custom-block.component';
import { StaticContentComponent } from './../../../usorta-library/src/lib/components/static-content/static-content.component';
import { NotificationTestService } from './services/notification-test.service';
import { AddAttributesDirective } from './../../../usorta-library/src/lib/directives/addAttribute/add-attributes.directive';
import { CustomFormComponent } from './../../../usorta-library/src/lib/components/custom-form/custom-form.component';
import { NavbarTestService } from './services/navbar-test.service';
import { CourseTestService } from './../../../usorta-library/src/lib/services/course/course-test.service';
import { LocalTranslatePipe } from './../../../usorta-library/src/pipes/local-translate.pipe';
import { TenantTestService } from './../../../usorta-library/src/lib/services/tenant/tenant-test.service';
import { FooterService } from './../../../usorta-library/src/lib/services/footer/footer.service';
import { PreloaderComponent } from './../../../usorta-library/src/lib/components/preloader/preloader.component';
import { FooterComponent } from './../../../usorta-library/src/lib/components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceInterceptor } from './../../../usorta-library/src/interceptors/service.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TestLoginService } from './../../../usorta-library/src/lib/services/login/test-login.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, SecurityContext } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { ToastrModule } from 'ngx-toastr';
import { FooterTestService } from 'projects/usorta-library/src/lib/services/footer/footer-test.service';
import { TenantService } from 'projects/usorta-library/src/lib/services/tenant/tenant.service';
import { MainComponent } from './components/main/main.component';
import { HorizontalCourseListComponent } from './components/courseList/horizontalCourseList/horizontal-course-list/horizontal-course-list.component';
import { CourseService } from 'projects/usorta-library/src/lib/services/course/course.service';
import { CourseCatalogComponent } from './components/courseList/course-catalog/course-catalog.component';
import { NavbarComponent } from './components/navbar/navbar/navbar.component';
import { HorizontalCourseItemComponent } from './components/courseList/horizontal-course-item/horizontal-course-item.component';
import { VerticalCourseItemComponent } from './components/courseList/vertical-course-item/vertical-course-item.component';
import { CoursesComponent } from './components/courses/courses.component';
import { CourseCatalogFilterComponent } from './components/courseList/course-catalog-filter/course-catalog-filter.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { UpcomingCoursesComponent } from './components/upcoming-courses/upcoming-courses.component';
import { StudentFormComponent } from './components/student-form/student-form.component';
import { MiniNotificationItemComponent } from './components/notifications/mini-notification-item/mini-notification-item.component';
import { NotificationService } from './services/notification.service';
import { NotificationDetailsItemComponent } from './components/notifications/notification-details-item/notification-details-item.component';
import { AllNotificationsComponent } from './components/notifications/all-notifications/all-notifications.component';
import { NotificationDetailsPageComponent } from './components/notifications/notification-details-page/notification-details-page.component';
import { UpcomingCourseItemComponent } from './components/upcoming-courses/upcoming-course-item/upcoming-course-item.component';
import { CustomPageComponent } from './components/custom-page/custom-page.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { CustomizeCookiePreferenceComponent } from './components/customize-cookie-preference/customize-cookie-preference.component';
import { GeneralModule } from 'projects/usorta-library/src/lib/modules/general/general.module';
import { WhiteboardModule } from '@usorta-packages/whiteboard';
import { LearnComponent } from './components/learn/learn.component'
import { ChatLibraryModule } from '@usorta-packages/chat-library';
import { RemoteClientComponent } from './components/remote-client/remote-client.component';
import { NavbarService } from 'projects/usorta-library/src/lib/services/navbar/navbar.service';
import { NavbarMainService } from 'projects/usorta-library/src/lib/services/navbar/navbar-main.service';
import { CourseLandingPageComponent } from './components/course-landing-page/course-landing-page.component';
import { CourseLandingOutlineComponent } from './components/course-landing-page/course-landing-outline/course-landing-outline.component';
import { CourseLandingDetailsComponent } from './components/course-landing-page/course-landing-details/course-landing-details.component';
import { CourseLandingInfoComponent } from './components/course-landing-page/course-landing-info/course-landing-info.component';
import { CourseLandingAnnouncementsComponent } from './components/course-landing-page/course-landing-announcements/course-landing-announcements.component';
import { CourseLandingMembersComponent } from './components/course-landing-page/course-landing-members/course-landing-members.component';
import { CourseDocumentsComponent } from './components/course-landing-page/course-documents/course-documents.component';
import { AllCourseAnnouncementsComponent } from './components/course-landing-page/all-course-announcements/all-course-announcements.component';
import { CourseAnnouncementItemComponent } from './components/course-landing-page/course-announcement-item/course-announcement-item.component';
import { SynchronousLearnComponent } from './components/learn/synchronous-learn/synchronous-learn.component'
import { NgxSummernoteModule } from 'ngx-summernote';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { SessionEndedComponent } from './components/learn/session-ended/session-ended.component';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { GlobalOkCancelEventService } from 'projects/usorta-library/src/lib/services/globalOkCancelEvent/global-ok-cancel-event.service';
import { RefreshTokenService } from 'projects/usorta-library/src/lib/services/auth/refresh-token.service';
import { StudentLmsClientRefreshTokenService } from './services/student-lms-client-refresh-token.service';
import { TranslateStore } from '@ngx-translate/core';
/*export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.tenantUrl+"language?lang=", "");
}*/
@NgModule({
  declarations: [

    AppComponent,
    MainComponent,
    HorizontalCourseListComponent,
    CourseCatalogComponent,
    NavbarComponent,
    HorizontalCourseItemComponent,
    VerticalCourseItemComponent,
    CoursesComponent,
    CourseCatalogFilterComponent,
    NotificationsComponent,
    UpcomingCoursesComponent,
    StudentFormComponent,
    MiniNotificationItemComponent,
    NotificationDetailsItemComponent,
    AllNotificationsComponent,
    NotificationDetailsPageComponent,
    UpcomingCourseItemComponent,
    CustomPageComponent,
    CustomizeCookiePreferenceComponent,
    LearnComponent,
    RemoteClientComponent,
    CourseLandingPageComponent,
    CourseLandingOutlineComponent,
    CourseLandingDetailsComponent,
    CourseLandingInfoComponent,
    CourseLandingAnnouncementsComponent,
    CourseLandingMembersComponent,
    CourseDocumentsComponent,
    AllCourseAnnouncementsComponent,
    CourseAnnouncementItemComponent,
    SynchronousLearnComponent,
    SessionEndedComponent

  ],
  imports: [
    BrowserModule,
    GeneralModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    WhiteboardModule,
    ChatLibraryModule,
    HttpClientModule,
    FormsModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass:'toast-bottom-right'
    }),
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }
      }
    })

  ],
  providers: [
    TranslateStore,
    {provide:LoginService,useClass:TestLoginService},
    {provide:RefreshTokenService,useClass:StudentLmsClientRefreshTokenService},
    {provide:HTTP_INTERCEPTORS,useClass:ServiceInterceptor,multi:true},
    {provide:FooterService,useClass:FooterTestService},
    {provide:TenantService,useClass:TenantTestService},
    {provide:CourseService,useClass:CourseTestService},
    {provide:NavbarService,useClass:NavbarMainService},
    {provide:NotificationService,useClass:NotificationTestService},
    GlobalModalService,
    GlobalOkCancelEventService,
    GeneralModule

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
