import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';
import { AddContentToSectionRequestModel } from '../../models/CourseContentModels/addContentToSectionRequestModel';
import { ResponseModel } from '../../models/responseModel';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { CourseContentDetailsModel } from '../../models/CourseContentModels/courseContentDetailsModel';
import { UpdateContentInSectionModel } from '../../models/CourseContentModels/updateContentInSectionModel';
import { CourseContentUpdateInfo } from '../../models/CourseContentModels/courseContentUpdateInfo';

@Injectable({
  providedIn: 'root'
})
export class CourseContentService {

  constructor(private httpClient:HttpClient) { }
  getAudioList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/exampleContentList.json");
  }
  getImageList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/exampleContentList.json");
  }
  getVideoList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/exampleContentList.json");
  }
  getFileList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/exampleContentList.json");
  }
  getExamList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/exampleContentList.json");
  }
  getQuestionList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/exampleContentList.json");
  }
  getRandomQuestionList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/exampleContentList.json");
  }
  getPollList(){
    return this.httpClient.get<ListResponseModel<ListItemModel>>("/assets/exampleContentList.json");
  }
  addCourseContent(addCourseContent:AddContentToSectionRequestModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/coursecontents/create`,addCourseContent);
  }
  getContentDetail(contentId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<CourseContentDetailsModel>>(`${apiUrl}api/lms/coursecontents/${contentId}`)
  }
  deleteCourseContent(contentId:string){
    var apiUrl = environment.apiUrl;
    var body = {contentId:contentId};
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/lms/coursecontents/${contentId}/delete`,{body:body});
  }
  updateCourseContent(updateModel:UpdateContentInSectionModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/lms/coursecontents/${updateModel.contentId}/update`,updateModel);
  }
  getCourseContentUpdateInfo(contentId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<CourseContentUpdateInfo>>(`${apiUrl}api/lms/coursecontents/${contentId}/update`)
  }
}
