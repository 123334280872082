
<div class="row" style="margin-right: 10px;">
  <div class="upcomingCourseMain">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <h4>{{'upcomingCoursesTitle' | translate }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-upcoming-course-item *ngFor="let item of upcomingSummary" [upcomingsummary]="item"></app-upcoming-course-item>
      </div>
    </div>
  </div>
</div>
</div>
