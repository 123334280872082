<div>
  <div class="courseTitle">{{courseDetails.courseName}}</div>
  <img
    *ngIf="courseDetails.courseImage != undefined && courseDetails.courseImage != '' && courseDetails.courseImage != null"
    class="courseImage" [src]="courseDetails.courseImage" />
  <img
    *ngIf="courseDetails.courseImage == undefined || courseDetails.courseImage == '' || courseDetails.courseImage == null"
    class="courseImage" [src]="defaultImage" />
  <div class="courseDescription">
    {{courseDetails.courseDescription}}
  </div>
</div>
