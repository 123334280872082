import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingPageService } from '../../services/landing-page.service';
import { ToastrService } from 'ngx-toastr';
import { StudentLandingPageModel } from '../../models/studentLandingPage/studentLandingPageModel';

@Component({
  selector: 'app-course-landing-page',
  templateUrl: './course-landing-page.component.html',
  styleUrls: ['./course-landing-page.component.css']
})
export class CourseLandingPageComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute,private router:Router,private landingPageService:LandingPageService,private toastrService:ToastrService) { }
  courseId:number;
  landingPage?:StudentLandingPageModel = undefined;
  ngOnInit(): void {
    this.getActivatedRoute();
  }
  getActivatedRoute(){
    this.activatedRoute.params.subscribe(param=>{
      this.courseId = param['courseId'];
      this.getLandingPage();
    })
  }
  getLandingPage(){
    this.landingPageService.getLandingPage(this.courseId).subscribe({
      next:(response)=>{
        this.landingPage = response;
        console.log(this.landingPage);
      },
      error:(err)=>{
        this.toastrService.error("Kurs bilgisi alınırken bir hata oluştu");
      }
    });
  }

}
