<div class="detailsMain">
  <table class="courseDetails">
    <tr>
      <td><i class="detailIcon bi bi-clock"></i>
        <font class="detailTitle"> Uzunluk : </font>
        <font class="detailContent">{{dateParse()}}</font>
      </td>
    </tr>
    <tr>
      <td><i class="detailIcon bi bi-mortarboard-fill"></i>
        <font class="detailTitle"> Kategori : </font>
        <font class="detailContent">{{detailsModel.category}}</font>
      </td>
    </tr>
    <tr>
      <td><i class="detailIcon bi bi-award"></i>
        <font class="detailTitle"> Seviye : </font>
        <font class="detailContent">{{getCourseLevel()}}</font>
      </td>
    </tr>
  </table>
</div>
