<div class="contentDetailsMain" *ngIf="contentDetails">
    <h5>{{'courses.contentDetails' | translate}}</h5>
    <hr/>
    <div class="contentDetails">
        <div class="mb-3">
            <b>{{'courses.contentName' | translate}} : </b><font>{{contentDetails.name}}</font>
        </div>
        <div class="mb-3">
            <b>{{'courses.contentType' | translate}} : </b><font>{{contentDetails.type}}</font>
        </div>
        <div class="mb-3">
            <b>{{'courses.relatedSection' | translate}} : </b><font>{{contentDetails.outlineItemName}}</font>&nbsp;&nbsp;<button [routerLink]="'/lms/courses/section/'+contentDetails.outlineItemId+'/details'" class="btn btn-warning btn-xs">{{'courses.goToSectionDetails' | translate}}</button>
        </div>
        <div class="mb-3">
            <b>{{'courses.orderValue' | translate}} : </b><font>{{contentDetails.orderValue}}</font>
        </div>
        <div class="mb-3" *ngIf="contentDetails.content !== null">
            <button (click)="showContent()" class="btn btn-secondary">{{'courses.viewContent' | translate}}</button>
        </div>
        <div class="mb-3">
            <b>{{'courses.options' | translate}}</b><br/>
            <hr/>
            <div class="mb-3" *ngFor="let option of contentOptions" style="width: 50%; display:inline-block;">
                <input type="checkbox" [checked]="checkOption(option.value)" onclick="return false" [readOnly]="true" class="form-check-input"><label class="form-check-label">{{option.key}}</label>
            </div>
        </div>

    </div>
</div>
