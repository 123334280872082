import { UpcomingSummaryModel } from './../../../../../../usorta-library/src/lib/models/upcomingSummaryModel';
import { Component, Input, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-upcoming-course-item',
  templateUrl: './upcoming-course-item.component.html',
  styleUrls: ['./upcoming-course-item.component.css']
})
export class UpcomingCourseItemComponent implements OnInit {
  @Input() upcomingsummary:UpcomingSummaryModel;
  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  redirect(url:string){
    this.router.navigate([url]);
  }


}
