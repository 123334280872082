import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';

import { ResponseModel } from '../../models/responseModel';
import { CreateVmInstanceTypeModel } from '../../models/vmInstanceTypes/createVmInstanceTypeModel';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { VmInstanceTypeUpdateInfoModel } from '../../models/vmInstanceTypes/vmInstanceUpdateInfoModel';
import { VmInstanceTypeUpdateModel } from '../../models/vmInstanceTypes/vmInstanceUpdateModel';
import { VmInstanceTypeModel } from '../../models/vmInstanceTypes/vmInstanceTypeModel';
import { CreateVmInstanceMappingModel } from '../../models/vmInstanceTypeMappings/createVmInstanceTypeMappingModel';
import { VmInstanceTypeMappingsUpdateInfoModel } from '../../models/vmInstanceTypeMappings/vmInstanceTypeMappingsUpdateInfo';
import { UpdateVmInstanceMappingModel } from '../../models/vmInstanceTypeMappings/updateVmInstanceMappingModel';
import { VmInstanceTypeMappingsInfoModel } from '../../models/vmInstanceTypeMappings/vmInstanceTypeMappingsInfoModel';

@Injectable({
  providedIn: 'root'
})
export class VmInstanceService {

  constructor(private httpClient:HttpClient) { }
  getRamList(){
    var apiUrl = "";
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}/assets/fakeJson/ramList.json`);
  }
  getCpuCountList(){
    var apiUrl = "";
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}/assets/fakeJson/cpuList.json`);
  }
  getProviderList(){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/range/providertypes/options`);
  }
  getHostList(providerId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/range/hosts/options?providerType=${providerId}`);
  }
  getVmInstanceTypeUpdateInfo(typeId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<VmInstanceTypeUpdateInfoModel>>(`${apiUrl}api/range/vminstancetypes/${typeId}/update`);
  }
  getVmInstanceTypeInfo(typeId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<VmInstanceTypeModel>>(`${apiUrl}api/range/vminstancetypes/${typeId}`);
  }
  createVmInstanceType(createModel:CreateVmInstanceTypeModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<SingleResponseModel<string>>(`${apiUrl}api/range/vminstancetypes/create`,createModel);
  }
  updateVmInstanceType(updateModel:VmInstanceTypeUpdateModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/vminstancetypes/${updateModel.instanceTypeId}/update`,updateModel);
  }
  deleteVmInstanceType(obj:any){
    var apiUrl = environment.apiUrl;
    var body = obj;
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/range/vminstancetypes/${obj.typeId}/delete`,{body:body});
  }
  deleteVmInstanceTypeMapping(obj:any){
    var apiUrl = environment.apiUrl;
    var body = obj;
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/range/vminstancetypes/mappings/${obj.mappingId}/delete`,{body:body});
  }
  /*****/
  getVmInstanceTypesWithProviderTypeAndHost(providerType:string|null|undefined,hostId:string|null|undefined = undefined){
    var apiUrl = environment.apiUrl;
    var path = "api/range/vminstancetypes/options";
    var qs = "?";
    if(providerType){
      qs += "providerType="+providerType
    }
    if(hostId != "null" && hostId != null && hostId != undefined){
      qs += "&hostId="+hostId;
    }
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}${path}${qs}`);
  }
  addVmInstanceTypeMapping(createModel:CreateVmInstanceMappingModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/vminstancetypes/mappings/create`,createModel);
  }
  updateInstanceTypeMapping(updateModel:UpdateVmInstanceMappingModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/vminstancetypes/mappings/${updateModel.mappingId}/update`,updateModel);
  }
  getInstanceTypeMappingUpdateInfo(id:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<VmInstanceTypeMappingsUpdateInfoModel>>(`${apiUrl}api/range/vminstancetypes/mappings/${id}/update`);
  }
  getInstanceTypeMappingInfo(id:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<VmInstanceTypeMappingsInfoModel>>(`${apiUrl}api/range/vminstancetypes/mappings/${id}`);
  }
}
