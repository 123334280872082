import { SendCookiePreferenceItem } from './../../../../../usorta-library/src/lib/models/CookiePreferences/sendCookiePreferences';
import { CookiePreferenceItem } from './../../../../../usorta-library/src/lib/models/CookiePreferences/getCookiePreferences';
import { CookieManagerService } from './../../../../../usorta-library/src/lib/services/cookieManager/cookie-manager.service';
import { CookiePreferencesService } from './../../../../../usorta-library/src/lib/services/cookiePreferences/cookie-preferences.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customize-cookie-preference',
  templateUrl: './customize-cookie-preference.component.html',
  styleUrls: ['./customize-cookie-preference.component.css']
})
export class CustomizeCookiePreferenceComponent implements OnInit {

  constructor(private cookiePreference:CookiePreferencesService,private cookieManager:CookieManagerService) { }
  items:SendCookiePreferenceItem[];
  ngOnInit(): void {
    this.items =this.cookiePreference.cookiePreferenceParser(this.cookieManager.getCookie("cookieprefs"));
  }

}
