import { NotificationItem } from './../../../../../usorta-library/src/lib/models/NotificationModels/notificationItem';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(private notificationService:NotificationService) { }
  notificationList:NotificationItem[];
  urgentNotifications:NotificationItem[];
  otherNotifications:NotificationItem[];
  ngOnInit(): void {
    this.notificationService.getMyNotifications().subscribe(result=>{
      this.notificationList = result;
      this.getUrgentNotifications();
      this.getOtherNotifications();
    })
  }
  getUrgentNotifications(){
    let filterUrgent = this.notificationList.filter(x=>x.urgent == true);
    this.urgentNotifications = filterUrgent;
  }
  getOtherNotifications(){
    let filterOthers = this.notificationList.filter(x=>x.urgent == false);
    this.otherNotifications = filterOthers;
  }

}
