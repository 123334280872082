import { Component, Input, OnInit } from '@angular/core';
import { AnnouncementsModel } from '../../../models/announcements/announcementsModel';

@Component({
  selector: 'app-course-landing-announcements',
  templateUrl: './course-landing-announcements.component.html',
  styleUrls: ['./course-landing-announcements.component.css']
})
export class CourseLandingAnnouncementsComponent implements OnInit {
  @Input() announcements:AnnouncementsModel[];
  constructor() { }

  ngOnInit(): void {
  }

}
