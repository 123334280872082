import { Component, OnInit } from '@angular/core';
import { CourseContentService } from '../../../services/education/course-content.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CourseContentDetailsModel } from '../../../models/CourseContentModels/courseContentDetailsModel';
import { CourseContentFlags } from '../../../models/CourseContentModels/courseContentFlags';
import { GlobalModalService } from '../../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../../models/ModalManagementModels/modalTypeEnum';
declare var $:any;
@Component({
  selector: 'lib-course-content-details',
  templateUrl: './course-content-details.component.html',
  styleUrls: ['./course-content-details.component.css']
})
export class CourseContentDetailsComponent implements OnInit {
  courseContentId:string;
  contentDetails:CourseContentDetailsModel;
  contentOptions:{key:string,value:number}[] = Object.entries(CourseContentFlags).map((val,index,arr)=>{
    return {key:arr[index][0],value:arr[index][1]};
  })
  constructor(private globalModalService:GlobalModalService,private activatedRoute:ActivatedRoute,private toastrService:ToastrService,private courseContentService:CourseContentService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.courseContentId = param["contentId"];
        this.getCourseContentDetails();
      }
    })
  }
  showContent(){
    var ifr = document.createElement("iframe");
    ifr.style.width = "100%";
    ifr.style.height = "100%";
    ifr.src = "about:blank"
    var link = document.createElement("link");
    this.globalModalService.showModal({
      title: "Detay Açıklama",
      type: ModalTypes.HTMLELEMENT,
      data: ifr,
      helperModals: []
    },true);
    link.rel="stylesheet";
    link.href = "/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css";
    ifr.onload = ()=>{
      $(ifr).contents().find("head").get(0).append(link);
      $(ifr).contents().find("body").html(this.contentDetails.content);
    }


  }
  checkOption(num:number){
    if(num == 0 && this.contentDetails.options == 0){
      return true;
    }
    if(this.contentDetails.options & num){
      return true;
    }
    return false;
  }
  getCourseContentDetails(){
    this.courseContentService.getContentDetail(this.courseContentId).subscribe({
      next:(response)=>{
        this.contentDetails = response.content;
      }
    })
  }

}
