<div class="card courseCard card_{{key}}">
  <img *ngIf="course.pic_url" src="{{apiUrl+course.pic_url}}" class="img-fluid rounded-start coverImage" alt="...">
  <img *ngIf="!course.pic_url" src="{{defaultImage}}" class="img-fluid rounded-start coverImage" alt="...">
  <div class="card-body">
    <div class="courseContent">
    <font class="courseTitle">{{course.title}}</font><br/>
    <font class="courseCategory"><i class="bi bi-tag-fill"></i> {{course.category}}</font>
    <div class="courseDescriptionDiv">
      <p class="courseDescription">{{course.description}}</p>
    </div>
    <!--<span class="badge rounded-pill bg-secondary badgeStyle">{{course.levelName}}</span>-->
    <hr>
      <div div class="links">
        <button *ngFor="let button of course.links"class="btn btn-outline btn-sm" title={{button.tooltip}} routerLink="{{button.url}}" [style]="'outline:none; margin-left:5px; font-weight:bold; font-size:12px; border:1px solid '+course.color+'; color:'+course.color">{{button.text | translate}}</button>
      </div>
    </div>
    <div [style]="'margin-top:5px; border-radius:0px 0px 10px 10px; width:100%; height:10px; background:'+course.color"></div>
  </div>
</div>
