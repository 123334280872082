<div style="width:80%;display: inline-block; vertical-align: top; position: relative; bottom: 20px;" >
  <lib-canvas
  [height]="600"
[width]="1200"
[imageMainPath]="'https://localhost:5503/uploads/'"
[imageUploadEndpoint]="'https://localhost:5503/api/draw/addimage'"
[websocketURL]="'https://localhost:5503/draw'"
[requestedRoom]="'dab765fca9b54e6fb38b50c9d62e504b'"
[apiUrl]="'https://localhost:5503/api/'"
  ></lib-canvas>
</div>
<div style="width:19%; display: inline-block; height: 90vh;">
<lib-chat
websocketURL="https://localhost:7100/chat"
apiUrl="https://localhost:7100/api/"
chatRoomId="0a1402cd434f415ebf0b21e743a5355b"

>
</lib-chat>
</div>
