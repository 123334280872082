<div style="margin-top:-15px;">
  <div style="text-align: center;" class="remoteClientActionsMain">
    <div class="help remoteClientActionsDiv" (click)="askForHelp()">
      <i class="bi bi-info-square"></i>
      Yardım İste
    </div>
    <div class="otherMachines remoteClientActionsDiv" data-bs-toggle="offcanvas" data-bs-target="#userOffcanvas">
      <i class="bi bi-display"></i>
      Diğer Makineler
    </div>
    <div class="chat remoteClientActionsDiv" data-bs-toggle="modal" data-bs-target="#staticBackdrop" (click)="openChat()">
      <i class="bi bi-chat"></i>
      Sohbet
    </div>
  </div>
<div class="connectingMachine" [class.displayNone]="state == 3">
  <div class="connectingLogo" *ngIf="logoUrl">
    <img [src]="logoUrl"/>
  
  </div>
  <div class="connectingTitle" *ngIf="logoUrl == ''">Remote Desktop Client</div>
  <div class="connectingMessage">
    <p>{{loaderMessage}}</p>
    <div *ngIf="state == 1 || state == 2" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</div>
<br/>
<div *ngIf="showDisplay == true">
  <lib-guac-client (outTunnelState)="changeTunnelState($event)" class="showCursor" (outManagedClient)="getManagedClient($event)" [keyboardAccess]="keyboardAccess" [mouseAccess]="mouseAccess" *ngIf="start && ((socketRestrict && socketConnected) || (socketRestrict == false))" [sessionJwt]="jwt" [extClientId]="vmName" (outState)="clientStateChanged($event)"></lib-guac-client>
</div>
<div class="pageBg"></div>
</div>
<div class="offcanvas offcanvas-end" tabindex="-1" id="userOffcanvas" aria-labelledby="userOffcanvasLabel">
  <div class="offcanvas-header">
    <h5 id="userOffcanvasLabel">Size Atanmış Makine Listesi</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="ConnectionItem" *ngFor="let connectionName of machineList">
      <div class="ConnectionName"><i class="bi bi-display"></i> {{connectionName}}</div>
      <div class="ConnectionConnectBtn" (click)="changeGuacMachine(connectionName)"><i style="color:rgb(0, 172, 0);" class="bi bi-play-fill"></i></div>
      <div class="ConnectionConnectBtn" (click)="popupGuacMachine(connectionName)"><i style="color:rgb(0, 147, 201);" class="bi bi-window-plus"></i></div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
          <lib-terminal-chat-sender [messageHistory]="privateMessages" (message)="sendTerminalMessage($event)" [userList]="terminalUsers"></lib-terminal-chat-sender>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" (click)="closeChat()">Sekmeyi Kapat</button>
      </div>
    </div>
  </div>
</div>
