import { CourseService } from 'projects/usorta-library/src/lib/services/course/course.service';
import { CourseItemModel } from './../../../../../../usorta-library/src/lib/models/courseItemModel';
import { CourseDetails } from './../../../../../../usorta-library/src/lib/models/courseDetails';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-catalog',
  templateUrl: './course-catalog.component.html',
  styleUrls: ['./course-catalog.component.css']
})
export class CourseCatalogComponent implements OnInit {
  @Input() courseCatalogTitle:string;
  @Input() courseCatalogDescription:string;
  @Input() queryUrl:string;
  @Input() courseList:CourseItemModel[];
  constructor(private courseService:CourseService) { }

  ngOnInit(): void {
    if(this.queryUrl){
      this.courseService.getCoursesByQueryUrl(this.queryUrl).subscribe(response=>{
        this.courseList = response.content;
      })
    }

  }

}
