import { NotificationDetailsItemComponent } from './../components/notifications/notification-details-item/notification-details-item.component';
import { AllNotificationsModel } from './../../../../usorta-library/src/lib/models/NotificationModels/allNotifications';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationItem } from 'projects/usorta-library/src/lib/models/NotificationModels/notificationItem';
import { NotificationItemDetails } from 'projects/usorta-library/src/lib/models/NotificationModels/notificationItemDetails';

@Injectable({
  providedIn: 'root'
})
export abstract class NotificationService {

  constructor() { }
  public abstract getMyNotifications():Observable<NotificationItem[]>
  public abstract getAllNotifications(page:number):Observable<AllNotificationsModel>
  public abstract getNotificationDetails(notificationId:string):Observable<NotificationItemDetails>
  public abstract snoozeNotification(notificationItem:NotificationItem):Observable<any>;
  public abstract deleteNotification(notificationItem:NotificationItem):Observable<any>;
}
