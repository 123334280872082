import { TranslateService } from '@ngx-translate/core';
import { CourseInfoModel } from './../../../models/courses/courseInfoModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-landing-details',
  templateUrl: './course-landing-details.component.html',
  styleUrls: ['./course-landing-details.component.css']
})
export class CourseLandingDetailsComponent implements OnInit {
  @Input() detailsModel:CourseInfoModel;
  constructor(private translateService:TranslateService) { }

  ngOnInit(): void {
    console.log("details");
    console.log(this.detailsModel);
  }
  dateParse(){
    let kvpMap:Map<string,string> = new Map<string,string>();
    kvpMap.set('M','datetime.month');
    kvpMap.set('w','datetime.week');
    kvpMap.set('d','datetime.day');
    kvpMap.set('h','datetime.hour');
    kvpMap.set('m','datetime.minute');
    let dateString = "";
    var datetime = this.detailsModel.length;
    var reg = datetime.match(/\d+[dhmwM]/g) as any;
    console.log("reg");
    console.log(reg);
    let mSelect = 0;
    let paramlist = ['M','w','d','h','m'];
    for(let i=0;i<paramlist.length;i++){
      reg.forEach((data:string)=>{
        let ends = data[data.length-1];
        if(ends === paramlist[mSelect]){
          console.log(paramlist[mSelect]);
          let langParam = kvpMap.get(paramlist[mSelect]) ?? paramlist[mSelect];
          console.log(data.substring(0,data.length-1)+this.translateService.instant(langParam));
          dateString += data.substring(0,data.length-1)+" "+this.translateService.instant(langParam)+" ";
        }
      })
      mSelect++;
      console.log(mSelect);
    }
    return dateString;
  }
  getCourseLevel(){
    let level = this.translateService.instant("courseLevel."+this.detailsModel.level);
    return level;
  }

}
