import { CourseItemModel } from './../../models/courseItemModel';
import { ApiUrlService } from './../api/api-url.service';
import { environment } from './../../../../../student/src/environments/environment.prod';
import { ListResponseModel } from './../../models/listResponseModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseDetails } from '../../models/courseDetails';
import { CourseService } from './course.service';
import { CourseCatalogResponse } from '../../models/courseCatalogResponse';
import { UpcomingSummaryModel } from '../../models/upcomingSummaryModel';

@Injectable({
  providedIn: 'root'
})
export class CourseTestService implements CourseService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  public getUpcomingSummary(): Observable<UpcomingSummaryModel[]>
  {
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<UpcomingSummaryModel[]>(apiUrl+"courses/upcoming-summary")
  }
  public getCourseCatalogResponseByQueryUrl(queryUrl: string): Observable<CourseCatalogResponse>
  {
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<CourseCatalogResponse>(apiUrl+queryUrl);
  }
  public getCourses(): Observable<CourseItemModel[]>
  {
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<CourseItemModel[]>(apiUrl+"courses/getcourses");
  }
  public getCoursesByQueryUrl(queryUrl:string):Observable<ListResponseModel<CourseItemModel>>{
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<ListResponseModel<CourseItemModel>>(apiUrl+queryUrl);
  }
}
