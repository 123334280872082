<div class="mb-3">
    <label for="title" class="form-label label">Başlık</label>
    <input type="text" class="form-control" id="title" placeholder="Başlık Girin">
</div>
<div class="mb-3">
    <label for="type" class="form-label label">İçerik Tipi</label>
    <select class="form-select" (change)="changeType($event)" id="type" name="type">
        <option value="" selected disabled>İçerik Tipi Seçin</option>
        <option value="html">HTML İçerik</option>

<!--      <option value="audio">Ses</option>
        <option value="image">Görüntü</option>
        <option value="video">Video</option>-->
        <option value="media">Medya</option>
        <option value="file">Dosya</option>
        <option value="exam">Sınav</option>
        <option value="question">Soru</option>
        <option value="random-question">Rastgele Soru</option>
        <option value="poll">Anket</option>
    </select>
    <p style="color: red; font-size: 14px;" *ngIf="selectedType == ''">Lütfen içerik tipi seçin *</p>
</div>
<div class="contentArea">
    <label *ngIf="selectedType !== ''" for="type" class="form-label label">İçerik</label>
    <!-- Seçilen içerik tipine göre divlerden biri görüntülenecek-->
    <div id="htmlContent" *ngIf="selectedType == 'html'">
        <textarea [id]="'htmlLayoutEditor'" name="htmlLayoutEditor" class="htmlLayoutEditor"
            data-css="/assets/usortaEditor/src/lib/lms-layout-editor/css/layout-editor.css;https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
            autocomplete="off" data-usable-css="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css;">
        </textarea>
    </div>
    <div *ngIf="selectedType !== '' &&selectedType !== 'html'" class="mb-3">
        <input class="form-control" id="contentId" placeholder="Content Id"/>
    </div>
    <div style="display: none;">
        <div id="selectInputLoader" *ngIf="loading">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        <div id="audioSelect" class="selectMain" *ngIf="selectedType == 'audio'">
            <select class="form-select selectInput" style="flex: 1;" id="audioSelectInput" name="audioSelectInput">
                <option *ngFor="let option of selectItemList" [value]="option.value">{{option.label}}</option>
            </select>
            <div class="vr"></div>
            <button class="btn btn-info addNewBtn">Yeni</button>
        </div>
        <div id="videoSelect" class="selectMain" *ngIf="selectedType == 'video'">
            <select class="form-select selectInput" id="videoSelectInput" name="videoSelectInput">
                <option *ngFor="let option of selectItemList" [value]="option.value">{{option.label}}</option>
            </select>
        </div>
        <div id="imageSelect" class="selectMain" *ngIf="selectedType == 'image'">
            <select class="form-select selectInput" id="imageSelectInput" name="imageSelectInput">
                <option *ngFor="let option of selectItemList" [value]="option.value">{{option.label}}</option>
            </select>
        </div>
        <div id="fileSelect" class="selectMain" *ngIf="selectedType == 'file'">
            <select class="form-select selectInput" id="fileSelectInput" name="fileSelectInput">
                <option *ngFor="let option of selectItemList" [value]="option.value">{{option.label}}</option>
            </select>
        </div>
        <div id="examSelect" class="selectMain" *ngIf="selectedType == 'exam'">
            <select class="form-select selectInput" id="examSelectInput" name="examSelectInput">
                <option *ngFor="let option of selectItemList" [value]="option.value">{{option.label}}</option>
            </select>
        </div>
        <div id="questionSelect" class="selectMain" *ngIf="selectedType == 'question'">
            <select class="form-select selectInput" id="questionSelectInput" name="questionSelectInput">
                <option *ngFor="let option of selectItemList" [value]="option.value">{{option.label}}</option>
            </select>
        </div>
        <div id="random-questionSelect" class="selectMain" *ngIf="selectedType == 'random-question'">
            <select class="form-select selectInput" id="random-questionSelectInput" name="random-questionSelectInput">
                <option *ngFor="let option of selectItemList" [value]="option.value">{{option.label}}</option>
            </select>
        </div>
        <div id="pollSelect" class="selectMain" *ngIf="selectedType == 'poll'">
            <select class="form-select selectInput" id="pollSelectInput" name="pollSelectInput">
                <option *ngFor="let option of selectItemList" [value]="option.value">{{option.label}}</option>
            </select>
        </div>
    </div>

</div>
<div id="flagSelector">
    <label for="type" class="form-label label">İçerik Seçenekleri</label>
    <div class="flagListDiv">
        <div *ngFor="let flag of flags" class="flagItemDiv">
            <label><input type="checkbox" class="form-check-input optionsFlag" [value]="flag.value"> {{flag.flag}}</label>
        </div>
    </div>
</div>
<div class="priorityNumber">
    <div class="mb-3">
        <label for="sortValueInput" class="form-label">Sıralama</label>
        <input type="number" [value]="0" class="form-control" id="sortValueInput">
    </div>
</div>
<button [disabled]="selectedType == '' || requestSending" (click)="createContent()" class="btn btn-sm btn-danger">İçerik ekle <span *ngIf="requestSending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>