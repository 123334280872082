import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalModalService } from '../../services/globalModal/global-modal.service';
import { ModalTypes } from '../../models/ModalManagementModels/modalTypeEnum';
import { TranslateService } from '@ngx-translate/core';
declare var window:any;
declare var $:any;
@Component({
  selector: 'lib-translate-mode-helper',
  templateUrl: './translate-mode-helper.component.html',
  styleUrls: ['./translate-mode-helper.component.css']
})
export class TranslateModeHelperComponent implements OnInit {
  translationKeys:string[];
  first:boolean =true;
  devModeActive:boolean = (localStorage.getItem("devMode") !== undefined && localStorage.getItem("devMode") == 'active') ? true : false;
  constructor(private translateService:TranslateService,private router:Router,private globalModalService:GlobalModalService) { }

  ngOnInit(): void {
    
    setTimeout(()=>{
      if(this.first){
        this.process();
      }
    },2000);

    this.router.events.subscribe(navi=>{
      if(navi instanceof NavigationEnd){
        if(this.first){
          this.first = false;
        }else{
          this.process();
        }
      }
    })
  }
  helper() {
    
  }
  
  process(){
    var path = location.pathname;
    this.translationKeys = window["translations"][path];
    setTimeout(()=>{
      if(this.devModeActive != true){
        return;
      }
      this.translationKeys = window["translations"][path];
      if(this.translationKeys != undefined){
        var translationKeyStrings = this.translationKeys.toString();
      }else{
        return;
      }

      document.querySelectorAll("*").forEach((el:any)=>{
        if(el.children == null || el.children.length < 1){
          var elText = el.innerHTML;
          this.translationKeys.forEach(key=>{
          if(elText.includes(key)){
            this.addPencilToElement(el,key);
            if(elText.includes("(")){
              this.addUpdateBlockItemToElement(el,key);
              this.addManageBlockToElement(el,key);
            }
          }
          });
        }else if(el.children.length == 1){
          var htmlElement = el as HTMLElement;
          this.translationKeys.forEach(key=>{
            var elText = htmlElement.innerHTML;
            if(elText.includes(key)){
              if(htmlElement.tagName == "TH"){
                this.addPencilItemToElement(el,key);
                if(elText.includes("(")){
                  this.addUpdateItemToElement(el,key);
                  this.addManageItemToElement(el,key);
                }
              }else if(htmlElement.tagName == "BUTTON"){
                this.addPencilToElement(el,key);
                if(elText.includes("(")){
                  this.addUpdateBlockItemToElement(el,key);
                  this.addManageBlockToElement(el,key);
                }
              }
            }
          });         
        }
      })
      if(this.translationKeys == undefined || this.translationKeys == null || this.translationKeys.length == 0){
        setTimeout(()=>{
            this.process();
        },2000);
      }
    },1500); 
  }
  addPencilItemToElement(el:any,key:string){
    var tService = this.translateService;
    var modalFnc = this.globalModalService;
    var pencilDiv = document.createElement("div");
    var pencilEl = document.createElement("i");
    pencilEl.classList.add("bi","bi-plus-lg","translate-helper-pencil");
    
    pencilEl.onclick = function(e){
      
      var cmp = modalFnc.showModal({type:ModalTypes.LINK,title:"New Localized String",data:"/modal/localization/localizedstring/create?key="+key,helperModals:[]},true)
    
      cmp["onclose"].subscribe(()=>{
        tService.currentLoader.getTranslation(tService.currentLang).subscribe((data)=>{
          tService.setTranslation(tService.currentLang,data);
          tService.use(tService.currentLang);
        })
      })
      
      e.preventDefault();
      e.stopPropagation();
      
      return false;
    }
    //pencilDiv.appendChild(pencilEl);
    //pencilDiv.style.display = "inline-block"
    //$(pencilDiv).insertAfter(el);
    pencilEl.style.marginLeft = "5px";
    el.appendChild(pencilEl);
  }
  addUpdateItemToElement(el:any,key:string){
    var tService = this.translateService;
    var modalFnc = this.globalModalService;
    var pencilDiv = document.createElement("div");
    var pencilEl = document.createElement("i");
    pencilEl.classList.add("bi","bi-pencil-fill","translate-helper-pencil");
    
    pencilEl.onclick = function(e){
      var cmp = modalFnc.showModal({type:ModalTypes.LINK,title:"Update Localized String",data:"/modal/localization/localizedstring/updatewithkey?key="+key,helperModals:[]},true)
      cmp["onclose"].subscribe(()=>{
        tService.currentLoader.getTranslation(tService.currentLang).subscribe((data)=>{
          tService.setTranslation(tService.currentLang,data);
          tService.use(tService.currentLang);
        })
      })
      e.preventDefault();
      e.stopPropagation();
      
      return false;
    }
    //pencilDiv.appendChild(pencilEl);
    //pencilDiv.style.display = "inline-block"
    //$(pencilDiv).insertAfter(el);
    pencilEl.style.marginLeft = "5px";
    el.appendChild(pencilEl);
  }
  addPencilToElement(el:any,key:string){
    var tService = this.translateService;
    var modalFnc = this.globalModalService;
    var pencilDiv = document.createElement("div");
    var pencilEl = document.createElement("button");
    pencilEl.classList.add("btn","btn-primary","btn-sm","bi","bi-plus-lg","translate-helper-pencil");
    pencilEl.onclick = function(e){
      var cmp = modalFnc.showModal({type:ModalTypes.LINK,title:"New Localized String",data:"/modal/localization/localizedstring/create?key="+key,helperModals:[]},true)
      cmp["onclose"].subscribe(()=>{
        tService.currentLoader.getTranslation(tService.currentLang).subscribe((data)=>{
          tService.setTranslation(tService.currentLang,data);
          tService.use(tService.currentLang);
        })
      })
      e.preventDefault();
      e.stopPropagation();
      
      return false;
    }
    pencilDiv.appendChild(pencilEl);
    pencilDiv.style.marginLeft = "10px";
    pencilDiv.style.display = "inline-block"
    $(pencilDiv).insertAfter(el);
  }
  addUpdateBlockItemToElement(el:any,key:string){
    var tService = this.translateService;
    var modalFnc = this.globalModalService;
    var pencilDiv = document.createElement("div");
    var pencilEl = document.createElement("button");
    pencilEl.classList.add("btn","btn-warning","btn-sm","bi","bi-pencil-fill","translate-helper-pencil");
    pencilEl.onclick = function(e){
      var cmp = modalFnc.showModal({type:ModalTypes.LINK,title:"Update Localized String",data:"/modal/localization/localizedstring/updatewithkey?key="+key,helperModals:[]},true)
      cmp["onclose"].subscribe(()=>{
        tService.currentLoader.getTranslation(tService.currentLang).subscribe((data)=>{
          tService.setTranslation(tService.currentLang,data);
          tService.use(tService.currentLang);
        })
      })
      e.preventDefault();
      e.stopPropagation();
      
      return false;
    }
    pencilDiv.appendChild(pencilEl);
    pencilDiv.style.marginLeft = "5px";
    pencilDiv.style.display = "inline-block"
    $(pencilDiv).insertAfter(el);
  }
  addManageItemToElement(el:any,key:string){
    var tService = this.translateService;
    var modalFnc = this.globalModalService;
    var pencilDiv = document.createElement("div");
    var pencilEl = document.createElement("i");
    pencilEl.classList.add("bi","bi-wrench","translate-helper-pencil");
    
    pencilEl.onclick = function(e){
      var cmp = modalFnc.showModal({type:ModalTypes.LINK,title:"Manage Localized String",data:"/modal/localization/localizedstring/tablewithkey?key="+key,helperModals:[]},true)
      cmp["onclose"].subscribe(()=>{
        tService.currentLoader.getTranslation(tService.currentLang).subscribe((data)=>{
          tService.setTranslation(tService.currentLang,data);
          tService.use(tService.currentLang);
        })
      })
      e.preventDefault();
      e.stopPropagation();
      
      return false;
    }
    //pencilDiv.appendChild(pencilEl);
    //pencilDiv.style.display = "inline-block"
    //$(pencilDiv).insertAfter(el);
    pencilEl.style.marginLeft = "5px";
    el.appendChild(pencilEl);
  }
  addManageBlockToElement(el:any,key:string){
    var tService = this.translateService;
    var modalFnc = this.globalModalService;
    var pencilDiv = document.createElement("div");
    var pencilEl = document.createElement("button");
    pencilEl.classList.add("btn","btn-warning","btn-sm","bi","bi-wrench","translate-helper-pencil");
    pencilEl.onclick = function(e){
      var cmp = modalFnc.showModal({type:ModalTypes.LINK,title:"Manage Localized String",data:"/modal/localization/localizedstring/tablewithkey?key="+key,helperModals:[]},true)
      cmp["onclose"].subscribe(()=>{
        tService.currentLoader.getTranslation(tService.currentLang).subscribe((data)=>{
          tService.setTranslation(tService.currentLang,data);
          tService.use(tService.currentLang);
        })
      })
      e.preventDefault();
      e.stopPropagation();
      
      return false;
  }
  pencilDiv.appendChild(pencilEl);
  pencilDiv.style.marginLeft = "5px";
  pencilDiv.style.display = "inline-block"
  $(pencilDiv).insertAfter(el);
  
  }
}
