import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { MenuItems } from '../../models/menu/menuItems';
import { CustomMenuService } from '../../services/customMenu/custom-menu.service';
import { LoginService } from '../../services/login/login.service';
import { LoggedUserListenerService } from '../../services/listener/logged-user-listener.service';
import { TenantInfoModel } from '../../models/TenantModels/tenantInfoModel';
import { CmsImageService } from '../../services/cmsImages/cms-image.service';

@Component({
  selector: 'lib-custom-menu',
  templateUrl: './custom-menu.component.html',
  styleUrls: ['./custom-menu.component.css']
})
export class CustomMenuComponent implements OnInit {
  @Input() externalPath:string = "";
  @Input() externalData:MenuItems[] = [];
  menuItems:MenuItems[];
  loginSubscribe:any;
  @Input() collapsible:boolean = false;
  @Input() defaultShowItems:boolean = false;
  logo:string;
  tenantInfoModel:TenantInfoModel;
  constructor(private cmsImageService:CmsImageService,private customMenuService:CustomMenuService,private router:Router,private loginService:LoginService,private loggedUserListener:LoggedUserListenerService) { }


  ngOnInit(): void {
    this.getTenantInfo();
    if(this.externalData != null && this.externalData.length > 0){
      this.getNavbarMenuItemsWithExternalData();
    }else{
      this.getNavbarMenuItemsWithRequest();
    }
  }
  getTenantInfo(){
    var tenantInfo = localStorage.getItem("tenantInfo");
    if(tenantInfo){
      this.tenantInfoModel = JSON.parse(tenantInfo).applicationConfiguration;
      this.logo = this.tenantInfoModel.logo;
      if(!this.tenantInfoModel.logo.includes("/")){
        this.logo = this.cmsImageService.getUrl(this.tenantInfoModel.logo);
      }

      console.log(this.tenantInfoModel)
    }
  }
  getNavbarMenuItemsWithRequest(){
    this.customMenuService.dataUrl = this.externalPath;
    this.customMenuService.getMenu().subscribe({
      next:response=>{
        this.menuItems = response;
      },
      error:error=>{
        console.log(error);
      }
    })
  }
  getNavbarMenuItemsWithExternalData(){
    this.menuItems = this.externalData;
  }
  logout(){
    this.loginService.logout();
    if(this.loginSubscribe){
      this.loginSubscribe.unsubscribe();
    }
    this.loginSubscribe = this.loggedUserListener.getLoggedUserStatus('false').subscribe(response=>{
      this.router.navigate(['/login']);
    });
  }
  getTarget(item:MenuItems){
    if(item.target){
      return item.target;
    }else{
      return '_self';
    }
  }
  redirect(item:MenuItems){
    console.log(item.link);
    if(item.link.startsWith('http') || item.link.startsWith('https')){
      window.open(item.link,this.getTarget(item));
    }else{
      this.router.navigate([item.link]);
      scroll(0,0)
    }
  }
  isActivePage(item:MenuItems){
    //let itemPage = item.link.split('/')[1];
    //let currentUrl = this.router.url.split('/')[1];
    let itemPage = item.link;
    let currentUrl = this.router.url;
    if(itemPage === currentUrl){
      return true;
    }else{
      return false;
    }
  }

}
