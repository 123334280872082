import { TerminalMessageModel } from './../../../../../usorta-library/src/lib/models/RemoteClientModels/terminalMessageModel';
import { WhiteboardModule } from '@usorta-packages/whiteboard';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  GuacAuthService,
  GuacConnectionInfo,
  ManagedClient,
  TunnelStates,
} from '@usorta-packages/guac-library';
import { ChatLibraryService } from '@usorta-packages/chat-library';
import { ChatApiService } from '@usorta-packages/chat-library/lib/services/chat-api.service';
import { ToastrService } from 'ngx-toastr';
import { GuacamoleService } from 'projects/usorta-library/src/lib/services/guacamole/guacamole.service';
import { SessionSocketService } from 'projects/usorta-library/src/lib/services/session/session-socket.service';
import { TerminalChatService } from 'projects/usorta-library/src/lib/services/terminalChat/terminal-chat.service';
import { ReceivedPrivateMessageModel } from '@usorta-packages/chat-library/lib/models/receivedPrivateMessageModel';
import { TenantService } from 'projects/usorta-library/src/lib/services/tenant/tenant.service';
declare var $:any;
@Component({
  selector: 'app-remote-client',
  templateUrl: './remote-client.component.html',
  styleUrls: ['./remote-client.component.css'],
})
export class RemoteClientComponent implements OnInit {
  constructor(
    private terminalChatService: TerminalChatService,
    private toastrService: ToastrService,
    private guacamoleService: GuacamoleService,
    private guacAuthService: GuacAuthService,
    private activatedRoute: ActivatedRoute,
    private sessionSocket: SessionSocketService,
    private tenantService:TenantService
  ) {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      event.returnValue = 'Sayfa kapatılacak';
      return localStorage.removeItem(this.connectKey);
    });
  }
  guacToken = '';
  jwt: string;
  connectKey: string = '';
  connectJson:any;
  vmName: string;
  start: boolean = false;
  keyboardAccess: boolean[] = [true];
  mouseAccess: boolean[] = [true];
  blocedByInstructor:boolean = false;
  socketConnected: boolean = false;
  state: number = 1;
  loaderMessage = 'Bağlantı başlatılıyor';
  machineList: string[] = [];
  showDisplay: boolean = true;
  managedClient: ManagedClient;
  privateMessages:TerminalMessageModel[] = [];
  socketRestrict: boolean = false; //True olursa oturum servisine bağlanılmadan guacamole makinesine bağlanılmaz
  terminalUsers: { userId: string; displayName: string }[] = [];
  logoUrl:string = "";
  ngOnInit(): void {
    this.getLogo();
    this.writeTextarea();
    this.activatedRoute.params.subscribe((param) => {
      this.jwt = param['jwt'];
      this.vmName = param['vm'];
      this.connectKey = param['key'] ?? '';
      localStorage.setItem('GUAC_AUTH', '{"authToken":""}');
      if(localStorage.getItem(this.connectKey) == undefined){
        this.changeConnectingMessage(7);
        this.state = 7;
        return;
      }else{
        this.connectJson = JSON.parse(localStorage.getItem(this.connectKey) ?? "")
      }
      setTimeout(() => {
        this.start = true;
      }, 100);
      this.getMachines();
    });
    let guacConnectionInfo: GuacConnectionInfo = {
      url: `//${window.location.hostname}`,
      wsUrl: `wss://${window.location.hostname}`,
      //url: '//10.8.0.19:8080',
      //wsUrl: 'wss://10.8.0.19:8080',
      signalRSocket: '/hubs/session',
      mainPath: '/guacamole/',
    };
    this.guacAuthService.setGuacamoleParams(guacConnectionInfo);
    this.startSessionSocket();
  }
  getLogo(){
    var tenantInfo = JSON.parse(localStorage.getItem("tenantInfo") ?? "");
    var tenantJson = tenantInfo['applicationConfiguration'];
    this.logoUrl = tenantJson['logo'];
  }
  writeTextarea(){
    $(document).on('keydown',(e:KeyboardEvent)=>{
      if($(e.target).hasClass('privateMessageTextarea')){
        e.preventDefault();
        e.stopPropagation();
        var eventKey = new KeyboardEvent('keydown',{code:e.code,key:e.key,keyCode:e.keyCode});
          var textarea:HTMLTextAreaElement = e.target as HTMLTextAreaElement;
          if(eventKey.key.length == 1){
            textarea.value += e.key;
          }else if(eventKey.key == "Backspace"){
            textarea.value = textarea.value.substring(0,textarea.value.length-1);
          }else if(eventKey.key == "Enter"){
            textarea.value += "\n";
          }else{
            console.log(eventKey.key);
          }
      }
    })
  }
  openChat() {
    this.keyboardAccess = [false];
    this.mouseAccess = [false];
  }
  closeChat() {
    if(this.blocedByInstructor){
      return;
    }
    this.keyboardAccess = [true];
    this.mouseAccess = [true];
  }
  startSessionSocket() {
    let parseConnectKey = this.connectJson;
    let websocketUrl = parseConnectKey['sessionSocket'];
    let sessionId = parseConnectKey['sessionId'];
    this.sessionSocket
      .ConnectSocket(websocketUrl, sessionId)
      .then((connected) => {
        console.log(websocketUrl);
        console.log(sessionId);
        this.socketConnected = connected;
        this.clientAccessListener();
        this.guacamoleJoinListener();
        this.guacamoleDisconnectListener();
        this.helpReceivedListener();
        this.startTerminalChat();
        this.startTerminalMessageListener();
        if (connected == false && this.socketRestrict == true) {
          this.state = 6;
          this.changeConnectingMessage(this.state);
        }
      });
  }
  startTerminalChat() {
    let parseConnectKey = this.connectJson;
    let chatApiUrl = parseConnectKey['chatApi'];
    let chatRoom = parseConnectKey['chatRoom'];
    let chatWS = parseConnectKey['chatSocket'];
    this.terminalChatService.initializeTerminalChat(
      chatWS,
      chatRoom,
      chatApiUrl
    );
  }
  startTerminalMessageListener() {
    this.terminalChatService.receivePrivateMessages.subscribe({
      next: (data) => {
        this.toastrService.info(data.message);
        let terminalMessageModel:TerminalMessageModel = {your:false,userId:data.senderId,message:data.message};
        this.privateMessages.push(terminalMessageModel)
        this.privateMessages = [...this.privateMessages];
      },
    });
  }
  sendTerminalMessage(tm:TerminalMessageModel) {
    this.terminalChatService.sendMessageToUser(tm.userId, tm.message).then(x=>{
      console.log("Result : "+ x);
      if(x == true){
        tm.your = true;
        this.privateMessages.push(tm);
        this.privateMessages = [...this.privateMessages];
      }
    });
  }
  getMachines() {
    this.guacamoleService.getMyMachines(this.jwt).subscribe({
      next: (response) => {
        this.machineList = response.content;
      },
    });
  }
  getDisplayDiv(): HTMLDivElement {
    let displayDiv = document.getElementsByClassName(
      'display'
    )[0] as HTMLDivElement;
    return displayDiv;
  }
  getManagedClient(mc: ManagedClient) {
    this.managedClient = mc;
  }
  changeGuacMachine(vmName: string) {
    this.managedClient.client.disconnect();
    this.managedClient.tunnel.disconnect();
    console.log(this.managedClient.clientState);
    this.showDisplay = false;
    this.clientStateChanged(-1);

    setTimeout(() => {
      this.vmName = vmName;
    }, 1000);
    setTimeout(() => {
      this.showDisplay = true;
    }, 2000);
  }
  popupGuacMachine(vmName:string){
    let connJs = JSON.stringify(this.connectJson);
    localStorage.setItem(this.connectKey,connJs);
    var url = "/remoteClient/"+this.jwt+"/"+vmName+"/"+this.connectKey;
    var popup:Window = window.open(url,vmName,'height=800px,width=1200px') ?? new Window();
    console.log("popup opened");
    popup.addEventListener('beforeunload',function(){
      alert("test");
      console.log("popup closed");
    })
    popup.addEventListener('beforeunload',(pop)=>{
      alert("test");
      console.log("popup closed");
    })

  }
  guacamoleJoinListener() {
    this.sessionSocket.terminalJoinListener.subscribe((response) => {
      if (response.terminalId == this.vmName) {
        this.toastrService.info(`${response.displayName} oturuma giriş yaptı`);
        this.terminalUsers = this.terminalUsers.filter(
          (x) => x.userId != response.connectedMember
        );
        this.terminalUsers.push({
          userId: response.connectedMember,
          displayName: response.displayName,
        });
        this.terminalUsers = [...this.terminalUsers]
      }
    });
  }
  guacamoleDisconnectListener() {
    this.sessionSocket.terminalDisconnectListener.subscribe((response) => {
      if (response.terminalId == this.vmName) {
        this.toastrService.info(`${response.displayName} oturumdan ayrıldı`);
        this.terminalUsers = this.terminalUsers.filter(
          (x) => x.userId != response.connectedMember
        );
      }
    });
  }
  clientStateChanged(state: number) {
    this.state = state;
    this.changeConnectingMessage(state);
    //States
    /*
    STATE_IDLE = 0,
    STATE_CONNECTING = 1,
    STATE_WAITING = 2,
    STATE_CONNECTED = 3,
    STATE_DISCONNECTING = 4,
    STATE_DISCONNECTED = 5
    */

    console.log(state);
  }
  changeTunnelState(state:number){
    console.log("Tunnel state : "+state)
    let tunnelState = state as TunnelStates;
    if(tunnelState == TunnelStates.Closed){
      this.changeConnectingMessage(8);
    }
  }
  changeConnectingMessage(state: number) {
    console.log('state changed ' + state);
    switch (state) {
      case -1:
        this.loaderMessage = 'Bağlantı Değiştiriliyor';
        break;
      case 1:
        this.loaderMessage = 'Bağlantı Başlatılıyor';
        break;
      case 2:
        this.loaderMessage = 'Görüntü Bekleniyor';
        break;
      case 3:
        this.loaderMessage = 'Bağlantı Başarılı';
        break;
      case 4:
        this.loaderMessage = 'Bağlantı kapatılıyor';
        break;
      case 5:
        this.loaderMessage = 'Bağlantı Koptu';
        break;
      case 6:
        this.loaderMessage = 'Oturum servisi ile bağlantı kurulamadı';
        break;
      case 7:
        this.loaderMessage = 'Lütfen makineye oturum sayfası üzerinden giriş yapın';
        break;
      case 8:
        this.loaderMessage = 'Tünel bağlantısı koptu';
        break;
    }
  }
  askForHelp() {
    let parseConnectKey = this.connectJson;
    let websocketUrl = parseConnectKey['sessionSocket'];
    let sessionId = parseConnectKey['sessionId'];
    this.sessionSocket.askForHelp(sessionId, this.vmName);
  }
  helpReceivedListener() {
    this.sessionSocket.helpReceivedNotificationListener.subscribe((data) => {
      this.toastrService.info('Aktif eğitmenlere bildirim gönderildi');
    });
  }
  clientAccess(access: boolean) {
    this.keyboardAccess = [access];
    this.mouseAccess = [access];
  }
  clientAccessListener() {
    this.sessionSocket.terminalAccessListener.subscribe({
      next: (data) => {
        if (this.vmName == data.terminalId) {
          this.clientAccess(data.isAccess);
          if (data.isAccess == true) {
            this.blocedByInstructor = false;
            this.toastrService.info('Klavye ve mouse erişiminiz aktif edildi');
            this.getDisplayDiv().classList.add('software-cursor');
            this.getDisplayDiv().style.cursor = '';
          } else {
            this.blocedByInstructor = true;
            this.getDisplayDiv().classList.remove('software-cursor');
            this.getDisplayDiv().style.cursor = 'not-allowed';
            this.toastrService.info(
              'Klavye ve mouse erişiminiz geçici olarak devre dışı bırakıldı'
            );
          }
        }
      },
    });
  }
  @HostListener('window:beforeunload')
  closeWindowListener() {
    window.onbeforeunload = function () {
      alert('Sekme kapatılacak');
      return 'Dialog text here.';
    };
  }
}
