<div class="mainAnnouncements" *ngIf="announcements && announcements.items.length > 0">
  <h5>Kurs Duyuruları</h5>
  <div class="announcementItems">
  <app-course-announcement-item [lineLimit]="false" [announcement]="announcement" *ngFor="let announcement of announcements.items"></app-course-announcement-item>
  </div>
</div>
<div style="text-align: center; margin-top: 10px;">
  <ul class="pagination" style="display: inline-flex;">
    <li class="page-item" style="cursor: pointer;" (click)="prev()">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer;" (click)="changePage(page)" [class.active]="page == currentPage" *ngFor="let page of pageList"><a class="page-link">{{page}}</a></li>
    <li class="page-item" style="cursor: pointer;" (click)="next()">
      <a class="page-link"  aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</div>
