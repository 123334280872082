<div class="courseListMain" id="courseListMain_{{scrollIdentifier}}" (mouseover)="stopAutoScroll()" (mouseout)="startAutoScroll()">
  <div *ngIf="courses" class="scrollArrows" (click)="scrollLeft()" style="left:8px;"><i class="bi bi-arrow-left-circle-fill"></i></div>
  <div *ngIf="courses" class="scrollArrows" (click)="scrollRight()" style="right:8px;"><i class="bi bi-arrow-right-circle-fill"></i></div>
  <div style="text-align: left;">
  <h4 class="headerTitle">{{headerTitle}}</h4>
  <font *ngIf="description" class="componentDescription">{{description}}</font>
  <hr>
  </div>
<div *ngIf="!courses" class="courseList" style="text-align: center;">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">...</span>
  </div>
</div>
<div *ngIf="courses && itemType=='vertical'" class="courseList" id="courseList_{{scrollIdentifier}}">
  <app-vertical-course-item *ngFor="let course of courses" [course]="course" [key]="scrollIdentifier"></app-vertical-course-item>
</div>
<div *ngIf="courses && itemType=='horizontal'" class="courseList" id="courseList_{{scrollIdentifier}}">
  <app-horizontal-course-item *ngFor="let course of courses" [course]="course" [key]="scrollIdentifier"></app-horizontal-course-item>
</div>

</div>
