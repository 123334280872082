import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActionLinkModel } from 'projects/usorta-library/src/lib/models/NotificationModels/actionLinkModel';
import { NotificationItem } from 'projects/usorta-library/src/lib/models/NotificationModels/notificationItem';
import { NotificationItemDetails } from 'projects/usorta-library/src/lib/models/NotificationModels/notificationItemDetails';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-notification-details-item',
  templateUrl: './notification-details-item.component.html',
  styleUrls: ['./notification-details-item.component.css']
})
export class NotificationDetailsItemComponent implements OnInit {
  @Input() notification:NotificationItemDetails;
  constructor(private translateService:TranslateService,private router:Router,private notificationService:NotificationService,private toastrService:ToastrService) { }
  notificationMessage:string;
  details:string;
  ngOnInit(): void {
    this.notificationMessage = this.translateService.instant(this.notification.message,this.notification.messageParams);
    this.details = this.translateService.instant(this.notification.details,this.notification.detailParams);
  }
  getText(actionLink:ActionLinkModel){
    if(actionLink.text){
      return this.translateService.instant(actionLink.text,actionLink.textParams);
    }
    return '';
  }
  redirect(item:ActionLinkModel){
    console.log(item.url);
    if(item.url.startsWith('http') || item.url.startsWith('https')){
      window.open(item.url);
    }else{
      this.router.navigate([item.url]);
    }
  }
  snooze(notificationItem:NotificationItem){
    let snoozedMessage = this.translateService.instant("notifications.snoozedSuccess");
    let snoozedErrorMessage = this.translateService.instant("notifications.snoozedError");
    this.notificationService.snoozeNotification(notificationItem).subscribe(response=>{
      this.toastrService.success(snoozedMessage);
    },response=>{
      this.toastrService.error(snoozedErrorMessage);
    })
  }

}
