import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { LearnMainModel } from '../models/learn/LearnMainModel';
import { CreateNoteModel } from '../models/learn/Notes/createNoteModel';
import { UpdateNoteModel } from '../models/learn/Notes/updateNoteModel';
import { ResponseModel } from 'projects/usorta-library/src/lib/models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class LearnService {

  constructor(private httpClient:HttpClient) { }
  getLearn(courseId:string) {
    let apiUrl = environment.apiUrl + `api/learn`; //api/lms/{courseId} olarak değişecek
    return this.httpClient.get<LearnMainModel>(apiUrl);
  }
  updateNote(note:UpdateNoteModel,courseId:string){
    let apiUrl = environment.apiUrl + `api/lms/${courseId}/note/${note.id}/update`;
    return this.httpClient.post<ResponseModel>(apiUrl,note);
  }
  createNote(note:CreateNoteModel,courseId:string){
    let apiUrl = environment.apiUrl + `api/lms/${courseId}/note/create`;
    return this.httpClient.post<ResponseModel>(apiUrl,note);
  }
  deleteNote(noteId:string,courseId:string){
    let apiUrl = environment.apiUrl + `api/lms/${courseId}/note/${noteId}/delete`;
    return this.httpClient.delete<ResponseModel>(apiUrl);
  }
}
