<lib-custom-form [actionApiUrl]="baseUrl" (GetSendingObjects)="getObj($event)" [formTopElement]="externalForm" [externalAPIUrl]="formUrl"></lib-custom-form>

<div style="display: none;">
<div #externalForm style="width: 100%;">
<label class="formLabel" style="width: 100%;">
   Select Host
<select  #hostElement id="hostId" (change)="changeHost($event)" name="hostId" class="form-select" aria-label="Default select example">
    <option *ngFor="let host of hosts" [value]="host.value">{{host.label}}</option>
</select>
</label>

<label class="formLabel" style="width: 100%;">
    Select Instance Type
<select #instanceTypeElement id="instanceTypeId" name="instanceTypeId" class="form-select" aria-label="Default select example">

    <option *ngFor="let instance of instanceTypes" [value]="instance.value">{{instance.label}}</option>

</select>
</label>
</div></div>