import { Component, OnInit } from '@angular/core';
import { GuacAuthService } from '@usorta-packages/guac-library';
import { GuacamoleService } from '../../../services/guacamole/guacamole.service';

@Component({
  selector: 'lib-manage-setup-vm-instance',
  templateUrl: './manage-setup-vm-instance.component.html',
  styleUrls: ['./manage-setup-vm-instance.component.css']
})
export class ManageSetupVmInstanceComponent implements OnInit {
  jwt:string;
  openVm:boolean = false;
  machineName:string;
  constructor(private guacAuthService:GuacAuthService,private guacamoleService:GuacamoleService) { }

  ngOnInit(): void {

  }
  guacamoleAuth(){
    var serviceJwt = sessionStorage.getItem("token");
    if(serviceJwt != null){
      this.guacAuthService.authenticate({data:serviceJwt}).subscribe({
        next:(response)=>{
          
        },error:(err)=>{

        }
      })
    }

  }

}
