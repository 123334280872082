import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateRoomModel } from '@usorta-packages/chat-library/lib/models/createRoomModel';
import { GetPrivateMessageHistoryDto } from '@usorta-packages/chat-library/lib/models/getMessageHistoryDto';
import { MessageModel } from '@usorta-packages/chat-library/lib/models/messageModel';
import { ReceivedPrivateMessageModel } from '@usorta-packages/chat-library/lib/models/receivedPrivateMessageModel';
import { RoomModel } from '@usorta-packages/chat-library/lib/models/roomModel';
import { SendPrivateMessageDto } from '@usorta-packages/chat-library/lib/models/sendPrivateMessageDto';
import { ShowUserDto } from '@usorta-packages/chat-library/lib/models/showUserDto';

@Injectable({
  providedIn: 'root'
})
export class TerminalChatApiService {
  private apiUrl:string;
  constructor(private httpClient:HttpClient) { }
  setApiUrl(url:string){
    this.apiUrl = url;
  }
  getAllRooms(){
    return this.httpClient.get<RoomModel[]>(this.apiUrl+"Rooms/GetRooms");
  }
  addRoom(createRoomModel:CreateRoomModel){
    return this.httpClient.post(this.apiUrl+"Rooms/AddRoom",createRoomModel);
  }
  getMessageHistory(){
    return this.httpClient.get<MessageModel[]>(this.apiUrl+"Rooms/GetMessageHistory");
  }

  writeAccess(roomPreferences:number,yourRole:number){
    if(yourRole > 1){
      return true;
    }else{
      if(roomPreferences & 1){
        return true;
      }
    }
    return false;
  }

  getUserList(){
    return this.httpClient.get<ShowUserDto[]>(this.apiUrl+"Rooms/GetUserList");
  }

  getFullNameWithUserId(userId:string){
    return this.httpClient.get<string>(this.apiUrl+"Rooms/GetFullName?userId="+userId);
  }

  sendPrivateMessage(sendPrivateMessageDto:SendPrivateMessageDto){
    return this.httpClient.post<boolean>(this.apiUrl+"Rooms/SendPrivateMessage",sendPrivateMessageDto);
  }

  getPrivateMessageHistory(getMessageHistoryDto:GetPrivateMessageHistoryDto){
    return this.httpClient.post<ReceivedPrivateMessageModel[]>(this.apiUrl+"Rooms/GetPrivateMessageList",getMessageHistoryDto);
  }
}
