import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment';
import { StudentLandingPageModel } from '../models/studentLandingPage/studentLandingPageModel';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {

  constructor(private httpClient:HttpClient) { }
  getLandingPage(courseId:number){
    let requestUrl = environment.apiUrl+"api/landing/"+courseId;
    return this.httpClient.get<StudentLandingPageModel>(requestUrl);
  }
}
