<div>
  <ul class="mainList">
    <li [title]="item.sectionText" class="mainListItem" [class.cursorPointer]="item.sectionLink != undefined && item.sectionLink != ''" *ngFor="let item of outlineModel" (click)="route(item)">
      <i *ngIf="item.sectionIcon == undefined" class="sectionIcon bi bi-bookmark-fill"></i>
      <i *ngIf="item.sectionIcon != undefined" [class]="'sectionIcon '+item.sectionIcon"></i>
      <font class="mainItemText">{{item.sectionText}}</font>
      <hr style="height: 2px; padding: 0px; margin: 3px;"/>
      <ul class="subList" *ngIf="item.sub != undefined && item.sub.length > 0">
        <li [class.cursorPointer]="item.sectionLink != undefined && item.sectionLink != ''" [title]="subItem.sectionText" class="subListItem" *ngFor="let subItem of item.sub" (click)="route(subItem)">
          <i *ngIf="subItem.sectionIcon == undefined" class="sectionIcon bi bi-bookmark-fill"></i>
          <i *ngIf="subItem.sectionIcon != undefined" [class]="'sectionIcon '+subItem.sectionIcon"></i>
          <font>{{subItem.sectionText}}</font>
          <hr style="height: 2px; padding: 0px; margin: 3px;"/>
        </li>
      </ul>
    </li>

  </ul>
</div>
