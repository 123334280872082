import { CourseFilterRequestModel } from './../../../../../../usorta-library/src/lib/models/courseFilterRequestModel';
import { CourseFilterModel } from './../../../../../../usorta-library/src/lib/models/courseFilterModel';
import { CourseItemModel } from './../../../../../../usorta-library/src/lib/models/courseItemModel';
import { CourseService } from 'projects/usorta-library/src/lib/services/course/course.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-catalog-filter',
  templateUrl: './course-catalog-filter.component.html',
  styleUrls: ['./course-catalog-filter.component.css']
})
export class CourseCatalogFilterComponent implements OnInit {
  @Input() courseCatalogTitle:string;
  @Input() courseCatalogDescription:string;
  @Input() queryUrl:string;
  constructor(private courseService:CourseService) { }
  courses:CourseItemModel[];
  filters:CourseFilterModel[];
  page:number;
  pageSize:number;
  totalCount:number;
  courseFilterRequestModel:CourseFilterRequestModel;
  ngOnInit(): void {
    this.courseService.getCourseCatalogResponseByQueryUrl(this.queryUrl).subscribe(response=>{
      this.courses = response.results;
      this.filters = response.filters;
      this.page = response.page;
      this.pageSize = response.pageSize;
      this.totalCount = response.totalCount;
      this.courseFilterRequestModel = {keywords:"",filters:[],page:0};
      this.setFilters();
    })
  }
  uncheckIfChecked(event:any){
    if(this.filters.find(x=>x.paramName==event.name)?.multiSelect==false){
    let item = this.courseFilterRequestModel.filters.find(x=>x.paramName==event.name);
    if(item?.values[0] == event.value){
      let radioButton = document.querySelectorAll('[name="'+event.name+'"][value="'+event.value+'"]').item(0) as HTMLInputElement
      radioButton.checked = false;
      if(item){
        item.values = [];
      }
    }
  }
  }
  setFilters(){
    this.filters.forEach((filter)=>{
      this.courseFilterRequestModel.filters.push({paramName:filter.paramName,values:[]})
    });
    this.filters.forEach((filter)=>{
      filter.options.forEach((option)=>{
        let item = this.courseFilterRequestModel.filters.find(x=>x.paramName==filter.paramName);
        if(option.selected){
          if(filter.multiSelect){
            item ? item.values = [option.value] : this.courseFilterRequestModel.filters.push({paramName:filter.paramName,values:[option.value]});
          }else{
            item ? item.values.push(option.value) : this.courseFilterRequestModel.filters.push({paramName:filter.paramName,values:[option.value]});
          }
        }
        option.children?.forEach(chd=>{
          if(option.selected){
            if(chd.selected){
              if(!filter.multiSelect){
                item ? item.values = [chd.value] : this.courseFilterRequestModel.filters.push({paramName:filter.paramName,values:[chd.value]});
              }else{
                item ? item.values.push(chd.value) : this.courseFilterRequestModel.filters.push({paramName:filter.paramName,values:[chd.value]});
              }
            }
          }
        })
      })
    })
  }
  isRadioEnable(parentName:string){
    let baseElement = document.getElementById(parentName) as HTMLInputElement;
    if(baseElement.checked){
      return true;
    }
    return false;
  }
  filter(){
    let keywords = document.getElementById("keywords") as HTMLInputElement;
    this.courseFilterRequestModel.keywords = keywords.value;
    console.log(this.courseFilterRequestModel);
    /* Bu kısım asıl backend kullanılmaya başlandığında yazılacak. Test-API yeni JSON lara uyumlu değil.*/
  }
  isSelectedOptionValue(paramName:string,optionValue:string){
    let item = this.courseFilterRequestModel.filters.find(x=>x.paramName==paramName);
    if(item){
      if(item.values.find(x=>x == optionValue)){
        return true;
      }
      return false;
    }else{
      return false;
    }
  }
  change(event:any){
    let item = this.courseFilterRequestModel.filters.find(x=>x.paramName==event.target.name);
    if(event.target.checked){
      if(this.filters.find(x=>x.paramName==event.target.name)?.multiSelect==false){
        item ? item.values = [event.target.value] : this.courseFilterRequestModel.filters.push({paramName:event.target.name,values:[event.target.value]});
      }else{
        item ? item.values.push(event.target.value) : this.courseFilterRequestModel.filters.push({paramName:event.target.name,values:[event.target.value]});
      }
    }else{
      if(this.filters.find(x=>x.paramName==event.target.name)?.multiSelect==true){
        if(item){
          item.values.splice(item.values.indexOf(event.target.value),1);
        }
      }
      /* Eğer seçilen seçeneğin alt seçenekleri varsa arrayden onları da kaldırır */
      let options = this.filters.find(x=>x.paramName == event.target.name)?.options
      let option = options?.find(x=>x.value == event.target.value);
      option?.children?.forEach(chd=>{
        let radioButton = document.querySelectorAll('[name="'+event.target.name+'"][value="'+chd.value+'"]').item(0) as HTMLInputElement
        radioButton.checked = false;
        if(item){
          item.values.splice(item.values.indexOf(chd.value),1);
        }
      })
      /* Açıklama Sonu */
    }
  }

}
