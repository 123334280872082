
<div class="mainComponent">
<!--<div class="row">
  <div class="col-xs-12">
    <div class="row">

    </div>
  </div>
</div> -->
<div class="row">
  <div class="col-md-8 col-lg-9">
    <div class="row">
      <div class="col-xs-12">
        <div class="continuingCourses mainContentDiv">
          <app-horizontal-course-list [pageSize]=5 [queryUrl]="carousel1" [pageScroll]="true" headerTitle="{{'continuingCourses' | translate}}" description="{{'continuingCoursesDescription' | translate}}" itemType="horizontal" [autoScroll]="false"></app-horizontal-course-list>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="continuingCourses mainContentDiv">
          <app-horizontal-course-list [pageSize]=5 [queryUrl]="carousel2" [pageScroll]="true" [autoScroll]="true" headerTitle="{{'continuingCourses' | translate}}" description="{{'continuingCoursesDescription' | translate}}" itemType="vertical"></app-horizontal-course-list>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="courseCatalog mainContentDiv">
          <app-course-catalog courseCatalogDescription="{{'courseCatalogDescription' | translate }}" courseCatalogTitle="{{'courseCatalog' | translate }}" [queryUrl]="carousel3"></app-course-catalog>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-lg-3">
    <div class="row">
      <div class="col-xs-12">
        <div class="notifications mainContentDiv">
          <app-notifications></app-notifications>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="upcomingCourses mainContentDiv">
          <app-upcoming-courses></app-upcoming-courses>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

