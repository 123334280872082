import { Injectable } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class TerminalSignalRChatService {
  private hubConnection:SignalR.HubConnection;
  private requestedRoom:string;
  public connectingRoom:boolean = true;
  public socketUrl:string = "";
  constructor(httpClient:HttpClient) { }
  public startWebsocket(socketUrl:string,requestedRoomId:string):Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      var token = (sessionStorage.getItem("token") != null ? sessionStorage.getItem("token") : localStorage.getItem("token")) ?? "";
      if(token){
        this.hubConnection = new SignalR.HubConnectionBuilder().withUrl(socketUrl,{ accessTokenFactory: () => token}).build();
        this.requestedRoom = requestedRoomId;
        this.socketUrl = socketUrl;
        this.start().then(x=>{
          if(x){
            resolve(true);
          }else{
            resolve(false);
          }
        });
        this.reconnectSystem();
      }else{
        console.log("Lütfen giriş yapın");
        this.connectingRoom = false;
        resolve(false);
      }
    })
  }
  public async start():Promise<boolean>{
    return new Promise<boolean>((resolve,reject)=>{
      this.hubConnection.start().then(()=>{
        console.log("Bağlantı başlatıldı");
        if(this.connectingRoom == true){
          this.hubConnection.on("InfoMessage",()=>{
            this.connectingRoom = false;
            resolve(true);
          })
          this.hubConnection.on("JoinSuccess",()=>{
            this.connectingRoom = false;
            resolve(true);
          })
        }
        setTimeout(()=>{
          this.hubConnection.invoke("JoinRoom",this.requestedRoom);
          resolve(true);
        })
      },(err)=>{
        console.log(err);
        this.connectingRoom = false;
        resolve(false);
      })
    })

  }
  reconnectSystem(){
    this.hubConnection.on("UserOnlineControl",()=>{
      this.startWebsocket(this.socketUrl,this.requestedRoom);
    })
  }
  public getHubConnection(){
    return this.hubConnection;
  }
  public async sendMessage(message:string){
    this.hubConnection.invoke("NewMessage",message);
  }
  public async joinRoom(roomId:string){
    this.hubConnection.invoke("JoinRoom",roomId);
  }
}
