<div class="card courseCard card_{{key}}">
  <div class="row g-0">
    <div class="col-5">
      <img *ngIf="course.pic_url" src="{{apiUrl+course.pic_url}}" class="img-fluid coverImage" alt="...">
      <img *ngIf="!course.pic_url" src="{{defaultImage}}" class="img-fluid coverImage" alt="...">

    </div>
    <div class="col-7">
      <div class="card-body">
        <div class="courseContent">
          <h6 class="card-title titlePreferences">{{course.title}}</h6>
          <font class="card-text"><small class="text-muted"><i class="bi bi-tag-fill"></i> {{course.category}}</small></font>
          <hr style="margin:0.3em 0px"/>
          <p class="card-text courseDescription">{{course.description}}</p>
          <font class="card-text"><small class="text-muted"><i class="bi bi-calendar-check-fill"></i> {{'lastUpdate' | translate}} : --</small></font>
          <div div class="links">
            <button *ngFor="let button of course.links"class="btn btn-outline btn-sm" title={{button.tooltip}} routerLink="{{button.url}}" [style]="'outline:none; font-weight:bold; margin-right:3px; font-size:12px; border:1px solid'+course.color+'; color:'+course.color">{{button.text | translate}}</button>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
