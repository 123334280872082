import { CustomizeCookiePreferenceComponent } from './components/customize-cookie-preference/customize-cookie-preference.component';
import { NotificationDetailsPageComponent } from './components/notifications/notification-details-page/notification-details-page.component';
import { AllNotificationsComponent } from './components/notifications/all-notifications/all-notifications.component';
import { StudentFormComponent } from './components/student-form/student-form.component';
import { CourseCatalogFilterComponent } from './components/courseList/course-catalog-filter/course-catalog-filter.component';

import { CoursesComponent } from './components/courses/courses.component';
import { NotLoggedGuard } from './../../../usorta-library/src/guards/not-logged.guard';
import { LoggedGuard } from './../../../usorta-library/src/guards/logged.guard';
import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './../../../usorta-library/src/lib/components/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from 'projects/usorta-library/src/lib/components/forgot-password/forgot-password.component';
import { HorizontalCourseItemComponent } from './components/courseList/horizontal-course-item/horizontal-course-item.component';
import { CustomPageComponent } from './components/custom-page/custom-page.component';
import { LearnComponent } from './components/learn/learn.component';
import { RemoteClientComponent } from './components/remote-client/remote-client.component';
import { CourseLandingPageComponent } from './components/course-landing-page/course-landing-page.component';
import { AllCourseAnnouncementsComponent } from './components/course-landing-page/all-course-announcements/all-course-announcements.component';
import { SynchronousLearnComponent } from './components/learn/synchronous-learn/synchronous-learn.component';
import { ConnectedFromOtherComponent } from 'projects/usorta-library/src/lib/components/connected-from-other/connected-from-other.component';
import { SessionEndedComponent } from './components/learn/session-ended/session-ended.component';
import { PopoutComponent } from 'projects/usorta-library/src/lib/components/popout/popout.component';

const routes: Routes = [
  {path: '',component:MainComponent,pathMatch:'full',canActivate:[LoggedGuard]},
  {path: 'login',component:LoginComponent,pathMatch:'full',canActivate:[NotLoggedGuard]},
  {path: 'login/:type',component:LoginComponent,pathMatch:'full',canActivate:[LoggedGuard]},
  {path:'forgot-password',component:ForgotPasswordComponent,pathMatch:'full'},
  {path:'main',component:MainComponent,pathMatch:'full',canActivate:[LoggedGuard]},
  {path:'courses',component:CoursesComponent,pathMatch:'full',canActivate:[LoggedGuard]},
  {path:'hitem',component:HorizontalCourseItemComponent,pathMatch:'full'},
  {path:'filter',component:CoursesComponent,pathMatch:'full'},
  {path:'studentForm',component:StudentFormComponent,pathMatch:'full'},
  {path:'notifications',component:AllNotificationsComponent,pathMatch:'full'},
  {path:'notificationdetail/:notificationId',component:NotificationDetailsPageComponent},
  {path:'page/:pageKey',component:CustomPageComponent},
  {path:'customize-cookie-preference',component:CustomizeCookiePreferenceComponent},
  {path:'learn',component:LearnComponent,canActivate:[LoggedGuard]},
  {path:'remoteClient/:jwt/:vm/:key',component:RemoteClientComponent},
  {path:'remoteClient/:jwt/:vm',component:RemoteClientComponent},
  {path:'course/:courseId',component:CourseLandingPageComponent},
  {path:'course/:courseId/announcements',component:AllCourseAnnouncementsComponent},
  {path:'session/:courseId',component:SynchronousLearnComponent,canActivate:[LoggedGuard]},
  {path:'session/:courseId/completed',component:SessionEndedComponent,canActivate:[LoggedGuard]},
  {path:'sessionkicked/:sessionId',component:ConnectedFromOtherComponent},
  {path:'popout',component:PopoutComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

