import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { AllAnnouncementsModel } from '../models/announcements/allAnnouncementsModel';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {

  constructor(private httpClient:HttpClient) { }
  getAllAnnouncements(courseId:number,page:number=1){
    let apiUrl = environment.apiUrl+'api/course/'+courseId+'/allannouncements/'+page;
    return this.httpClient.get<AllAnnouncementsModel>(apiUrl);
  }
}
