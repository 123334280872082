import { UpcomingSummaryModel } from './../../models/upcomingSummaryModel';
import { CourseItemModel } from './../../models/courseItemModel';
import { ListResponseModel } from './../../models/listResponseModel';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CourseDetails } from '../../models/courseDetails';
import { CourseCatalogResponse } from '../../models/courseCatalogResponse';

@Injectable({
  providedIn: 'root'
})
export abstract class CourseService {

  constructor() { }
  public abstract getCourses(): Observable<CourseItemModel[]>;
  public abstract getCoursesByQueryUrl(queryUrl:string):Observable<ListResponseModel<CourseItemModel>>;
  public abstract getCourseCatalogResponseByQueryUrl(queryUrl:string):Observable<CourseCatalogResponse>;
  public abstract getUpcomingSummary():Observable<UpcomingSummaryModel[]>
}
