<div class="row">
  <div class="col-md-3">
    <div class="row">
      <div class="col-12">
        <div class="filter">
          <h3 style="text-align: center;" class="filterOptionDiv">{{'filters' | translate}}</h3>

          <div *ngFor="let filter of filters">

            <div *ngIf="filter.multiSelect" class="filterOptionDiv">
              <font class="filterTitle"> {{filter.title}}</font>
              <div class="form-check" >
                <div *ngFor="let option of filter.options">
                  <input class="form-check-input showPointer" type="checkbox" name="{{filter.paramName}}" [checked]="option.selected" (change)="change($event)" value="{{option.value}}" id="{{option.value}})">
                  <label class="form-check-label" for="{{option.value}}">
                    {{option.label}}
                  </label>
                  <div *ngFor="let childOption of option.children" class="childOptionsDiv">
                    <input class="form-check-input showPointer" type="checkbox" name="{{filter.paramName}}" (change)="change($event)" (click)="uncheckIfChecked($event.currentTarget)" value="{{childOption.value}}" [checked]="childOption.selected" id="{{childOption.value}}" [disabled]="!isSelectedOptionValue(filter.paramName,option.value)">
                    <label class="form-check-label" for="{{childOption.value}}">
                      {{childOption.label}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="filter.multiSelect == false" class="filterOptionDiv">
              <font class="filterTitle"> {{filter.title}}</font>
              <div class="form-check" >
                <div *ngFor="let option of filter.options">
                  <input class="form-check-input showPointer" [type]="option.children ? 'checkbox':'radio' " name="{{filter.paramName}}" (change)="change($event)" (click)="uncheckIfChecked($event.currentTarget)" value="{{option.value}}" [checked]="option.selected" id="{{option.value}}">
                  <label class="form-check-label" for="{{option.value}}">
                    {{option.label}}
                  </label>
                  <div *ngFor="let childOption of option.children" class="childOptionsDiv">
                    <!--<input class="form-check-input showPointer" type="radio" name="{{filter.paramName}}" (change)="change($event)" (click)="uncheckIfChecked($event.currentTarget)" value="{{childOption.value}}" [checked]="childOption.selected" id="{{childOption.value}}" [disabled]="!isSelectedOptionValue(filter.paramName,option.value)">-->
                    <input class="form-check-input showPointer" type="radio" name="{{filter.paramName}}" (change)="change($event)" (click)="uncheckIfChecked($event.currentTarget)" value="{{childOption.value}}" [checked]="childOption.selected" id="{{childOption.value}}" [disabled]="!isRadioEnable(option.value)">
                    <label class="form-check-label" for="{{childOption.value}}">
                      {{childOption.label}}
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <font class="filterTitle filterOptionDiv"> {{'keywords' | translate}}</font>
          <input type="text" class="form-control" placeholder="{{'keywords' | translate}}" id="keywords">

          <br/>
          <button (click)="filter()" class="btn btn-primary">{{'filter' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9">
    <div class="row">
      <div class="col-12">
        <app-course-catalog courseCatalogTitle="{{'courseCatalog' | translate}}" [courseList]="courses"></app-course-catalog>
      </div>
    </div>
  </div>
</div>
