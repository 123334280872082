import { Injectable } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { CreateVMEnvironmentModel } from '../../models/VirtualLabModels/createVMEnvironment';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../models/responseModel';
import { UpdateVMEnvironmentModel } from '../../models/VirtualLabModels/updateVMEnvironment';
import { SingleResponseModel } from '../../models/singleResponseModel';
import { VMEnvironmentModel } from '../../models/VirtualLabModels/VMEnvironment';
import { ListResponseModel } from '../../models/listResponseModel';
import { ListItemModel } from '../../models/ListItem/listItemModel';
import { CreateVirtualMachineModel } from '../../models/VirtualLabModels/createVirtualMachine';
import { VirtualMachineModel } from '../../models/VirtualLabModels/virtualMachineModel';
import { UpdateVirtualMachineModel } from '../../models/VirtualLabModels/updateVirtualMachine';
import { VMCustomization } from '../../models/VirtualLabModels/vmCustomization';
import { AddVMToVirtualLab } from '../../models/VirtualLabModels/addVMToVirtualLabModel';
import { AddControlCriteriaModel } from '../../models/VirtualLabModels/addControlCriteriaModel';
import { VirtualLabModel } from '../../models/VirtualLabModels/virtualLabModel';
import { UpdateVirtualLabModel } from '../../models/VirtualLabModels/updateVirtualLabModel';
import { VmInLabModel } from '../../models/VirtualLabModels/vmInLabModel';
import { UpdateVmInVirtualLabModel } from '../../models/VirtualLabModels/updateVmInVirtualLab';
import { ControlCrtiteriaModel } from '../../models/VirtualLabModels/controlCriteriaModel';
import { UpdateControlCriteriaModel } from '../../models/VirtualLabModels/updateControlCriteriaModel';
import { CustomTableMainModel } from '../../models/CustomTableModels/customTableMainModel';
import { CreateInstallerImageModel } from '../../models/VirtualLabModels/createInstallerImageModel';
import { UpdateInstallerImageModel } from '../../models/VirtualLabModels/updateInstallerImageModel';
import { DeleteInstallerImageModel } from '../../models/VirtualLabModels/deleteInstallerImageModel';
import { InstallerImageUpdateInfoModel } from '../../models/VirtualLabModels/installerImageUpdateInfoModel';
import { InstallerImageDetailsModel } from '../../models/VirtualLabModels/installerImageDetailsModel';
import { VmEnvironmentInfoModel } from '../../models/VirtualLabModels/vmEnvironmentInfoModel';
import { CreateVirtualLabModel } from '../../models/VirtualLabModels/createVirtualLabModel';
import { VirtualLabUpdateData } from '../../models/VirtualLabModels/virtualLabUpdateData';

@Injectable({
  providedIn: 'root'
})
export class VirtualLabService {

  constructor(private httpClient: HttpClient) { }
  createVMEnvironment(createEnv: CreateVMEnvironmentModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/hosts/create`, createEnv);

  }
  updateVMEnvironment(updateEnv: UpdateVMEnvironmentModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/hosts/${updateEnv.hostId}/update`, updateEnv);
  }
  getVMEnvironment(environmentId: string) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<VMEnvironmentModel>>(`${apiUrl}api/range/hosts/${environmentId}/update`);

  }
  deleteVmEnvironment(confirm1:boolean,confirm2:boolean,hostId:string) {
    var apiUrl = environment.apiUrl;
    var body = { hostId: hostId,confirm1:confirm1,confirm2:confirm2 };
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/range/hosts/${hostId}/delete`, { body: body });
  }
  getEnvironmentListItem() {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/range/hosts/options`);
  }
  getVMImageListItem(environmentId: string) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`/assets/fakeJson/vmimages.json`);
  }
  getVmEnvironmentInfo(environmentId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<VmEnvironmentInfoModel>>(`${apiUrl}api/range/hosts/${environmentId}`);
  }
  getVMList() {
    var apiUrl = environment.apiUrl;
    if (localStorage.getItem("fakejson") == "1") {
      return this.httpClient.get<ListResponseModel<ListItemModel>>(`/assets/fakeJson/vms.json`);
    }
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/range/vms/options`);

  }
  getNetworks(labId: string) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`/assets/fakeJson/networkList.json`);
  }

  getRamList() {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`/assets/fakeJson/ramList.json`);
  }
  getCPUList() {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`/assets/fakeJson/cpuList.json`);
  }
  createVirtualMachine(createVMModel: CreateVirtualMachineModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/vms/create`, createVMModel);
  }
  updateVirtualMachine(updateVM: UpdateVirtualMachineModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/vms/${updateVM.id}/update`, updateVM);
  }
  getVirtualMachine(vmId: string) {
    var apiUrl = environment.apiUrl;
    if(localStorage.getItem("fakejson") == "1"){
      return this.httpClient.get<SingleResponseModel<VirtualMachineModel>>(`/assets/fakeJson/virtualMachine.json`);
    }
    return this.httpClient.get<SingleResponseModel<VirtualMachineModel>>(`${apiUrl}api/range/vms/${vmId}/update`);

  }
  createLabBlueprint(createModel:CreateVirtualLabModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<SingleResponseModel<string>>(environment.apiUrl+"api/range/labblueprints/create",createModel);
  }
  getVMListByLabId(labId: string) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`/assets/fakeJson/vms.json`);
  }
  addCustomizationToVm(vmId: string, model: VMCustomization) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}lab/virtualmachines/${vmId}/addCustomization`, model);
  }
  addCustomizationFilesToVm(vmId: string, model: FormData) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}lab/virtualmachines/${vmId}/addCustomization`, model);
  }
  addVirtualMachineToLab(labId: string, model: AddVMToVirtualLab) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}lab/${labId}/addVm`, model);
  }
  updateVirtualMachineInLab(labId: string, model: UpdateVmInVirtualLabModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}lab/${labId}/vm/${model.id}/update`, model);
  }
  addLabObjective(labId: string, model: AddControlCriteriaModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/labblueprints/${labId}/labobjectives/create`, model);
  }
  getVirtualLabInfo(labId: string) {
    var apiUrl = environment.apiUrl;
    /*if(localStorage.getItem("fakejson") == "1"){
      return this.httpClient.get<SingleResponseModel<VirtualLabModel>>(`/assets/fakeJson/vlab.json`);
    }*/
    return this.httpClient.get<SingleResponseModel<VirtualLabModel>>(`${apiUrl}api/range/labblueprints/${labId}`);

  }
  getVirtualLabUpdateData(labId: string) {
    var apiUrl = environment.apiUrl;
    /*if(localStorage.getItem("fakejson") == "1"){
      return this.httpClient.get<SingleResponseModel<VirtualLabModel>>(`/assets/fakeJson/vlab.json`);
    }*/
    return this.httpClient.get<SingleResponseModel<VirtualLabUpdateData>>(`${apiUrl}api/range/labblueprints/${labId}/update`);

  }
  updateLab(labId: string, model: UpdateVirtualLabModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/labblueprints/${labId}/update`, model)
  }
  getVmInLab(labId: string, vmlabId: number) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<VmInLabModel>>(`/assets/fakeJson/vminlabdata.json`);
  }
  getControlCriteria(labId: string, crtiteriaId: number) {
    return this.httpClient.get<SingleResponseModel<ControlCrtiteriaModel>>(`/assets/fakeJson/criter.json`);
  }
  updateControlCriteria(labId: string, model: UpdateControlCriteriaModel) {
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}lab/${labId}/criteria/${model.id}`, model);
  }
  /* Installer Images */
  createInstallerImage(createInstallerImage:CreateInstallerImageModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<SingleResponseModel<string>>(`${apiUrl}api/range/vm/installerimages/create`,createInstallerImage);
  } 
  updateInstallerImage(updateInstallerImage:UpdateInstallerImageModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/vm/installerimages/${updateInstallerImage.imageId}/update`,updateInstallerImage);
  }
  getInstallerImageUpdateInfo(imageId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<InstallerImageUpdateInfoModel>>(`${apiUrl}api/range/vm/installerimages/${imageId}/update`)
  }
  deleteInstallerImage(deleteInstallerImage:DeleteInstallerImageModel){
    var apiUrl = environment.apiUrl;
    return this.httpClient.delete<ResponseModel>(`${apiUrl}api/range/vm/installerimages/${deleteInstallerImage.imageId}/delete`,{body:deleteInstallerImage});
  }
  getInstallerImageOptions(){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<ListResponseModel<ListItemModel>>(`${apiUrl}api/range/vm/installerimages/options`);
  }
  uploadInstallerImage(imageId:string,data:FormData){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/vm/installerimages/${imageId}/upload`,data);
  }
  uploadBaseVmImage(baseVmImageId:string,data:FormData){
    var apiUrl = environment.apiUrl;
    return this.httpClient.post<ResponseModel>(`${apiUrl}api/range/vm/basevmimage/${baseVmImageId}/upload`,data);
  }
  getInstallerImageDetails(imageId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get<SingleResponseModel<InstallerImageDetailsModel>>(`${apiUrl}api/range/vm/installerimages/${imageId}`)
  }
  downloadInstallerImageFile(imageId:string){
    var apiUrl = environment.apiUrl;
    return this.httpClient.get(`${apiUrl}api/range/vm/installerimages/${imageId}/file`,{responseType:'arraybuffer',reportProgress:true,observe:'events'})
  }
  /* -------- */
}
