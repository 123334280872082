<div class="summaryClass" style="position: relative;" (click)="redirect(upcomingsummary.courseUrl)">
  <span *ngIf="upcomingsummary.registered" title="{{'upcomingsummary.enrolled' | translate}}" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"><i class="bi bi-check" style="font-size: 16px;"></i></span>
<div class="row">
  <div class="col-12">

    <div class="row">
      <div class="col-2">
        <img style="height:40px; width:40px; object-fit:cover; border-radius: 10px;" src="{{upcomingsummary.courseImage}}">
      </div>
      <div class="col-10">
        <div style="padding-left: 8px;">
        <div style="display: inline-block; vertical-align: top;"><font class="upcomingsummaryname"> {{upcomingsummary.courseName}}</font></div>
        <br/><font class="categoryClass"><i class="bi bi-tag-fill"></i>{{upcomingsummary.categoryName}}</font>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="padding-bottom:5px; padding-top:3px; text-align:right">
          <span class="badge bg-secondary">{{upcomingsummary.startsAt | date: 'dd/MM/yyyy HH:mm'}}</span>
        </div>

      </div>

    </div>
    <div class="row">
      <div class="badges" [style]="'background-color:'+upcomingsummary.categoryColor+';'">
      </div>
    </div>
  </div>
</div>
</div>
