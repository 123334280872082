import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { NotificationItemDetails } from 'projects/usorta-library/src/lib/models/NotificationModels/notificationItemDetails';

@Component({
  selector: 'app-notification-details-page',
  templateUrl: './notification-details-page.component.html',
  styleUrls: ['./notification-details-page.component.css']
})
export class NotificationDetailsPageComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute,private notificationService:NotificationService) { }
  notificationId:string
  notificationDetail:NotificationItemDetails;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(response=>{
      this.notificationId = response['notificationId'];
      this.notificationService.getNotificationDetails(this.notificationId).subscribe(result=>{
        this.notificationDetail = result;
      })
    })
  }

}
