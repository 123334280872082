<div *ngIf="!showSuccessIcon || (formResponse == null || formResponse.success == false)">
  <div class="loadingForm formMain form-default-border form-maroon-border" *ngIf="formLoading">
    <h4>{{"localizedForm.loadingForm" | translate}}</h4>
    <div class="loadingSpinner">
      <div class="d-flex justify-content-center">
        <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <form #form="ngForm" [formGroup]="csForm" [class.form-maroon-border]="searchForm == false"
    [class.form-default-border]="searchForm == false" class="formMain" *ngIf="customForm && csForm"
    action="{{customForm.action}}" method="{{customForm.method}}" enctype="{{customForm.enctype}}" id="customForm">
    <div class="form-title-div">
      <font class="form-title-content">{{getPage().title | translate}}</font>
    </div>


    <div *ngIf="(pageNavigation == 'top') && (nextPageAvailabe() || previousPageAvailabe()) && (perPageSubmit == false)"
      style="text-align: right;">
      <div class="navigationButtons topNavigationButtons">
        <button *ngIf="nextPageAvailabe() && perPageSubmit == false"
          [disabled]="requirePageValidation() && !pageValidation()" class="btn btn-sm btn-primary" (click)="nextPage()"
          id="nextPage">{{ getButtonText("nextPage") | translate}}</button>
        <button *ngIf="previousPageAvailabe() && perPageSubmit == false" class="btn btn-sm btn-primary"
          (click)="previousPage()">{{ getButtonText("previousPage") | translate}}</button>
      </div>
    </div>
    <div id="formTop"></div>
    <div class="row">

      <div *ngFor="let element of getPage().elements" class="formField"
        [class.col-md-6]="((getPage().layout && getPage().layout == '2column') || getPage().layout == '3column') && element.type != 'hidden'"
        [class.col-lg-4]="(getPage().layout && getPage().layout == '3column') && element.type != 'hidden'">
        <div class="form-check" *ngIf="element.type == 'singlecheck'">
          <input *ngIf="element.type == 'singlecheck'" name="{{element.name}}" id="{{element.id}}"
            formControlName="{{element.name}}" [class]="element.cssClasses"
            [checked]="element.defaultValue == 'checked'" type="checkbox">
          <label class="form-check-label" for="flexCheckDefault">
            {{element.label??""|translate}}
          </label>
        </div>

        <label class="mainLabel" style="display: block;" aria-describedby="test"
          *ngIf="element.type != 'singlecheck' && element.type != 'htmlContent' && element.type != 'select' && element.type != 'hidden' &&element.type != 'checkbox' && element.type != 'radio'">{{element.label??""|translate}}</label>
        <input
          *ngIf="element.type != 'textarea' && element.type != 'htmlContent' && element.type != 'singlecheck' && element.type != 'select' && element.type != 'checkbox' && element.type != 'radio' && element.type != 'file'"
          autocomplete="{{element.autocomplete}}" name="{{element.name}}" (change)="changeEvent($event)"
          formControlName="{{element.name}}" type="{{element.type}}" placeholder="{{element.placeholder | translate}}"
          id="{{element.id != null ? element.id : element.name}}"
          class="{{element.cssClasses != null ? element.cssClasses : ''}}" libAddAttributes
          [attributes]="element.attributes" value="{{element.defaultValue}}">
        <textarea [readonly]="element.disabled" *ngIf="element.type == 'textarea'"
          autocomplete="{{element.autocomplete}}" name="{{element.name}}" (change)="changeEvent($event)"
          formControlName="{{element.name}}" placeholder="{{element.placeholder | translate}}"
          id="{{element.id != null ? element.id : element.name}}"
          class="{{element.cssClasses != null ? element.cssClasses : ''}}" libAddAttributes
          [attributes]="element.attributes" value="{{element.defaultValue}}"></textarea>
        <input [readonly]="element.disabled" *ngIf="element.type == 'file'" name="{{element.name}}"
          (change)="changeEvent($event)" type="file" placeholder="{{element.placeholder | translate}}"
          id="{{element.id != null ? element.id : element.name}}"
          class="{{element.cssClasses != null ? element.cssClasses : ''}}" libAddAttributes
          [attributes]="element.attributes">
        <font *ngIf="element.type == 'file' && formDataImageCheck(element.name)"><i class="bi bi-check2-circle"></i>
          {{'form.fileSelected' | translate}}</font>
        <div *ngIf="element.type == 'htmlContent'" [innerHtml]="element.defaultValue | translate"
          [class]="element.cssClasses"></div>
        <div *ngIf="element.type == 'checkbox'">
          <label class="mainLabel">{{element.label??""|translate}}</label>
          <div *ngFor="let option of element.options">
            <label><input name="{{element.name}}" (change)="changeEvent($event)"
                [checked]="getChecked(element.type,element.name,option.value)" formArrayName="{{element.name}}"
                type="checkbox" id="{{element.id != null ? element.id : element.name}}"
                class="{{element.cssClasses != null ? element.cssClasses : ''}}" libAddAttributes
                [attributes]="element.attributes" value="{{option.value}}">{{option.label|translate}}</label>
          </div>
        </div>
        <div *ngIf="element.type == 'select'">
          <label class="mainLabel">{{element.label??""|translate}}</label>
          <select formControlName="{{element.name}}" (change)="changeEvent($event)" name="{{element.name}}"
            class="{{element.cssClasses}}" id="{{element.id != null ? element.id : element.name}}" libAddAttributes
            [attributes]="element.attributes" value="{{element.defaultValue}}">
            <option *ngFor="let option of element.options" ngValue="{{option.value}}">{{option.label | translate}}
            </option>
          </select>
        </div>
        <div *ngIf="element.type == 'radio'">
          <label class="mainLabel">{{element.label??""|translate}}</label>
          <div *ngFor="let option of element.options">
            <label> <input [readonly]="element.disabled" name="{{element.name}}" (change)="changeEvent($event)"
                (click)="radioClick($event)" [checked]="getChecked(element.type,element.name,option.value)"
                formArrayName="{{element.name}}" type="radio" id="{{element.id != null ? element.id : element.name}}"
                class="{{element.cssClasses != null ? element.cssClasses : ''}}" libAddAttributes
                [attributes]="element.attributes" value="{{option.value}}">{{option.label | translate}}</label>
          </div>
        </div>
        <div style="display: inline;" *ngIf="element.type !== 'hidden'">
          <div *ngFor="let error of getErrors(element.name)" id="test" class="form-text warning">*{{error | translate}}
          </div>
          <div *ngIf="element.description" id="test" class="form-text">{{element.description | translate}}</div>
        </div>

      </div>

    </div>
    <div id="formBottom"></div>
    <div style="text-align: right;">
      <div *ngIf="pageNavigation == 'bottom'" class="navigationButtons bottomNavigationButtons">

        <button *ngIf="nextPageAvailabe() && perPageSubmit == false"
          [disabled]="requirePageValidation() && !pageValidation()" class="btn btn-sm btn-primary mx-1"
          (click)="nextPage()">{{ getButtonText("nextPage") | translate}}</button>
        <button *ngIf="previousPageAvailabe() && perPageSubmit == false" class="btn btn-sm btn-primary mx-1"
          (click)="previousPage()">{{ getButtonText("previousPage") | translate}}</button>

      </div>
      <div class="formButtons" style="display: inline-block;">
        <button *ngIf="getPage().showReset" type="reset" value="" class="btn-sm btn btn-danger mx-1"
          (click)="getForm()">{{ getButtonText("formReset") | translate}}</button>
        <button *ngIf="nextPageAvailabe() == false" [disabled]="!isValid()" class="btn btn-sm btn-primary mx-1"
          style="margin-right:10px;" (click)="submitForm()"> <span *ngIf="submitting"
            class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> {{
          getButtonText("submitButton") | translate}}</button>
        <button *ngIf="perPageSubmit == true && nextPageAvailabe()" [disabled]="!isValid()"
          class="btn btn-sm btn-primary mx-1" style="margin-right:10px;" (click)="submitForm()"><span *ngIf="submitting"
            class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> {{
          getButtonText("submitAndNextPage") | translate}}</button>
      </div>
    </div>

    <!--<p *ngIf="csForm">{{ csForm.value | json }}</p>-->
    <br />
    <div class="bottomDiv" *ngIf="!getPage().pageLabel && getPageCount() > 1">{{pageNumber+1 + " / " + totalPageCount}}
    </div>
    <div class="bottomDiv" *ngIf="getPage().pageLabel">{{getPage().pageLabel ?? "" | translate}}</div>
  </form>
</div>
<div *ngIf="showSuccessIcon && (formResponse != null && formResponse.success == true)">
  <div style="text-align: center;">
    <i class="bi bi-check-circle-fill" style="font-size: 100px; color: rgb(128, 214, 0);"></i>
    <p style="text-align: center; font-size:20px">{{'formSuccess.message' | translate}}</p>
    <div style="text-align: center;" *ngIf="successRedirectUrl != '' && successRedirectUrl != undefined">
      <button class="btn btn-primary" [routerLink]="successRedirectUrl">{{(successRedirectTitle ?? "customforms.goback")
        | translate}}</button>
    </div>
  </div>
</div>