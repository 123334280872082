import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '../../models/responseModel';
import { ApiUrlService } from '../api/api-url.service';
import { SingleResponseModel } from '../../models/singleResponseModel';


@Injectable({
  providedIn: 'root'
})
export class SetupVmInstanceService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  createSetupVmInstance(data:any){
    return this.httpClient.post<SingleResponseModel<string>>(this.apiUrlService.getApiUrl() + "api/range/vm/setupvminstances/create",data);
  }
}
