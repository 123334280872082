import { StudentNoteModel } from './../../../models/learn/StudentNoteModel';
import { Component, ElementRef, OnInit } from '@angular/core';
import { LearnService } from '../../../services/learn.service';
import { ToastrService } from 'ngx-toastr';
import { LearnMainModel } from '../../../models/learn/LearnMainModel';
import { LearnToolsEnum } from '../../../models/learn/learnToolsEnum';
import { CreateNoteModel } from '../../../models/learn/Notes/createNoteModel';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateNoteModel } from '../../../models/learn/Notes/updateNoteModel';
import { SessionSocketService } from 'projects/usorta-library/src/lib/services/session/session-socket.service';
import { iif } from 'rxjs';
import { GuacamoleService } from 'projects/usorta-library/src/lib/services/guacamole/guacamole.service';
import { RemoteClientUserModel } from 'projects/usorta-library/src/lib/models/RemoteClientModels/remoteClientUserModel';
import { RemoteClientSessionModel } from 'projects/usorta-library/src/lib/models/RemoteClientModels/remoteClientSessionModel';
declare var $: any;
declare var window:any;
@Component({
  selector: 'app-synchronous-learn',
  templateUrl: './synchronous-learn.component.html',
  styleUrls: ['./synchronous-learn.component.css']
})

export class SynchronousLearnComponent implements OnInit {
  fullscreen: boolean = false;
  constructor(private router: Router, private guacamoleService: GuacamoleService, private sessionSocket: SessionSocketService, private learnService: LearnService, private toastrService: ToastrService, private activatedRoute: ActivatedRoute) {

    window.addEventListener("beforeunload", (event:any) => {
      event.preventDefault();
      event.returnValue = "Sayfa kapatılacak";
      return event;

    });

  }
  learnContent: LearnMainModel
  selectedLearnTool: LearnToolsEnum = LearnToolsEnum.Content;
  editMode: boolean = false;
  noteEditId?: string = undefined;
  courseId: string = "";
  socketConnected: boolean = false;
  socketConnectedError: boolean = false;
  sessionId: string = "";
  isHandRaised: boolean = false;
  socketConnectionId = "";
  lastPoll: any = null;
  guacToken: string;
  guacMachines: string[];
  sessionHub: any;
  liveStreams: { user: string, stream: MediaStream }[] = [];
  selectedLiveStream: string = "";
  userId: string = "";
  sessionStatus = -1;
  streamContentEl: HTMLDivElement;
  noteContentEl: HTMLDivElement;
  mainPageWindow: Window = window;
  chatWindow: Window[] = [window];
  noteWindow: Window = window;
  courseContentWindow: Window = window;
  whiteboardWindow: Window = window;
  fileAttachmentWindow: Window = window;
  streamWindow: Window = window;
  newMessageReceived: boolean = false;
  lastActionTimestamp: Date = new Date();
  userStatusNumber = 1;
  firstname: string = sessionStorage.getItem("firstname") ?? "";
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.getLearnContent(param['courseId']);
      this.courseId = param['courseId'];
      this.fullscreenListener();
      this.requireLeaveNotify();

    })
  }
  requireLeaveNotify(){
    window['exitNotify'] = {
      notify:true,
      notifyTitle:"Sayfadan ayrılıyorsunuz",
      notifyMessage:"Bu işlem oturumu sonlandıracak.",
      notifyUrl:this.router.url,
      notifyAction:()=>{
        this.sessionSocket.disconnect();
      }
    }
  }
  startSocket = () => {
    this.socketConnectedError = false;
    this.socketConnected = false;
    let websocketUrl = this.learnContent.courseInfo.features.session.websocketUrl;
    let sessionId = this.learnContent.courseInfo.features.session.sessionId;
    this.sessionId = this.learnContent.courseInfo.features.session.sessionId;

    this.sessionSocket.ConnectSocket(websocketUrl, sessionId, true).then(connected => {
      this.socketConnected = connected;
      if (connected) {
        this.messageNotification();
        this.raiseHandListener();
        this.pollListener();
        //this.voteListener();
        this.sessionConnectionListener();
        this.guacamoleStart();
        this.userIdleControl();
        this.userIdleListener();
        this.otherTabJoinListener();
        this.sessionHub = this.sessionSocket.getHubConnection();
        this.userIdListener();
        this.sessionSocket.startPingSender();
        this.sessionStatusListener();
        this.getSessionStatus();
        let streamContent = document.getElementsByClassName("content_5")[0] as HTMLDivElement;
        this.streamContentEl = streamContent
        let noteContent = document.getElementsByClassName("content_3")[0] as HTMLDivElement;
        this.noteContentEl = noteContent;
        this.miniVideoClickListener();
      } else {
        this.socketConnectedError = true;
      }


    })
  }
  getSessionStatus() {
    this.sessionSocket.getHubConnection().invoke("GetSessionStatus");
  }
  userIdleListener = () => {
    window.document.addEventListener("mousemove", () => {
      this.lastActionTimestamp = new Date();
      if (this.userStatusNumber == 3) {
        this.userStatusNumber = 1;
        this.sessionSocket.setUserState(this.sessionId, this.userStatusNumber);
      }
    })
  }
  userIdleControl() {
    setTimeout(() => {
      if (this.userStatusNumber == 1) { //Kullanıcı çevrimiçiyse ve belirlenen süre içinde mouse hareket etmezse idle moduna geçiş yapılır
        let temp = this.lastActionTimestamp.getMinutes() + 1;
        if (temp < new Date().getMinutes()) {
          this.userStatusNumber = 3;
        }
      }
      this.sessionSocket.setUserState(this.sessionId, this.userStatusNumber); //Her 10 saniyede bir durumu karşıya bildirir.

      this.userIdleControl();
    }, 10000);
  }
  changeStatusForMe(statusNumber: number) {
    this.userStatusNumber = statusNumber;
    this.sessionSocket.setUserState(this.sessionId, this.userStatusNumber);

  }
  userIdListener() {
    this.sessionSocket.userIdListener.subscribe((data: string) => {
      this.userId = data;

    })
    this.sessionSocket.getUserId();
  }
  raiseHand() {
    this.sessionSocket.sendRaiseHand(this.isHandRaised ? false : true);
  }
  pollListener() {
    this.sessionSocket.pollListener.subscribe((poll) => {
      this.lastPoll = poll;
    })
  }
  otherTabJoinListener() {
    this.sessionSocket.otherJoinInfo.subscribe((response) => {
      if (response.connectionId !== this.sessionSocket.getConnectionId()) {
        this.toastrService.error("Başka bir yerden giriş yapıldı");
        this.sessionSocket.getHubConnection().stop();
        this.sessionSocket.disconnect();
        this.router.navigate(["/sessionkicked/" + this.sessionId]);
        this.sessionHub = null;

        /*var elements = document.getElementsByTagName("*");
        for(let i =0;i<elements.length;i++){
          elements[i].remove();
        }
        var reconnectMainDiv = document.createElement("div");
        reconnectMainDiv.style.width = "100%";
        reconnectMainDiv.style.height = "100vh";
        reconnectMainDiv.style.display = "flex";
        reconnectMainDiv.style.background = "#efefef";
        var reconnectDiv = document.createElement("div");
        reconnectDiv.style.display = "inline-block";
        reconnectDiv.style.width = "300px";
        reconnectDiv.style.margin = "auto";
        reconnectDiv.style.height = "200px";
        reconnectDiv.style.alignSelf = "center"
        reconnectDiv.style.border = "2px solid black";
        reconnectDiv.style.borderTop = "30px solid green";
        reconnectDiv.style.borderRadius = "20px"
        reconnectDiv.innerHTML = '<button class="btn btn-danger">Yeniden Bağlan</button><button class="btn btn-danger">Anasayfa</button>'
        reconnectMainDiv.appendChild(reconnectDiv);
        document.appendChild(reconnectMainDiv); */
      }
    })
  }
  miniVideoClickListener() {
    this.streamContentEl.addEventListener('click', (event) => {
      let btn = event.target;
      if ($(btn).hasClass("selectMiniVideo")) {
        var userid = $(btn).attr("data-userid");
        this.setBigLiveStream(userid);
      }
    })
  }
  addMiniLiveStream(userId: string) {

    let miniStreamDiv = $(this.streamContentEl).find('#miniStreamList').get(0) as HTMLVideoElement
    var data = this.liveStreams.find(x => x.user == userId);
    if (data?.stream != undefined) {
      var miniVideoEl = document.createElement('video');
      miniVideoEl.id = "miniVideo_" + userId;
      miniVideoEl.classList.add("selectMiniVideo");
      miniVideoEl.autoplay = true;
      miniVideoEl.controls = false;
      miniVideoEl.muted = true;
      miniVideoEl.width = 150;
      miniVideoEl.height = 150;
      miniVideoEl.style.backgroundColor = "rgb(165 165 165)"
      miniVideoEl.style.width = "125px";
      miniVideoEl.style.height = "125px";
      miniVideoEl.style.display = "inline-block";
      miniVideoEl.style.border = "1px solid silver";
      miniVideoEl.style.borderRadius = "20px"
      miniVideoEl.style.margin = "10px";
      miniVideoEl.style.cursor = "pointer";
      miniVideoEl.srcObject = data?.stream;
      $(miniVideoEl).attr("data-userid", userId);
      miniStreamDiv.appendChild(miniVideoEl);
    }

  }
  removeMiniLiveStream(userId: string) {
    $(this.streamContentEl).find("#miniVideo_" + userId).remove();
  }
  setBigLiveStream(userId: string) {
    var data = this.liveStreams.find(x => x.user == userId);
    if (data?.stream != undefined) {
      var vid = $(this.streamContentEl).find("#liveVideoStream").get(0) as HTMLVideoElement;
      vid.srcObject = data?.stream;
      this.selectedLiveStream = userId;
    }
  }
  getLiveStream(event: { user: string, stream: MediaStream }) {
    this.removeMiniLiveStream(event.user);
    this.liveStreams = this.liveStreams.filter(x => x.user != event.user);
    this.liveStreams.push(event);
    this.addMiniLiveStream(event.user);
    this.setBigLiveStream(event.user);
  }
  voteYes() {
    if (this.lastPoll == null) {
      this.toastrService.error("Oy verilebilecek bir anket yok");
      return;
    }
    this.sessionSocket.miniSurveyVoteYes(this.lastPoll.pollId);
    this.toastrService.info("Ankete evet oyu verdiniz");
    this.lastPoll = null;
  }
  voteNo() {
    if (this.lastPoll == null) {
      this.toastrService.error("Oy verilebilecek bir anket yok");
      return;
    }
    this.sessionSocket.miniSurveyVoteNo(this.lastPoll.pollId);
    this.toastrService.info("Ankete hayır oyu verdiniz");
    this.lastPoll = null;
  }
  voteListener() {
    this.sessionSocket.voteListener.subscribe((data) => {
      if (data.userId != this.socketConnectionId) {
        this.toastrService.info(data.displayName + " ankete" + (data.pollAnswer ? " evet " : " hayır ") + "oyu verdi");
      }
    })
  }
  raiseHandListener() {
    this.sessionSocket.raiseHandListener.subscribe((data) => {
      if (data.userId == this.userId) {
        this.isHandRaised = this.isHandRaised ? false : true;
      } else {
        this.toastrService.info(data.displayName + " " + (data.raise ? "el kaldırdı" : "elini indirdi"));
      }

    })
  }
  sessionConnectionListener() {
    this.sessionSocket.sessionConnectionListener.subscribe(response => {
      this.socketConnectionId = response.memberId;
    })
  }
  sessionStatusListener() {
    this.sessionSocket.sessionStatusListener.subscribe(response => {
      this.sessionStatus = response;
    })
  }
  getLearnContent(courseId: string) {
    this.learnService.getLearn(courseId).subscribe({
      next: (response) => {
        this.learnContent = response;
        this.startSocket();
      },
      error: (err) => {
        this.toastrService.error("Eğitim içeriği alınırken bir sorun oluştu");
      }
    })
  }
  handleClosedStream(userId: string) {
    this.liveStreams = this.liveStreams.filter(x => x.user != userId);
    if (this.selectedLiveStream == userId) {
      if (this.liveStreams.length > 0) {
        this.setBigLiveStream(this.liveStreams[0].user);
      } else {
        this.selectedLiveStream = "";
      }
    }
    this.removeMiniLiveStream(userId);
  }
  switchLearnTool(learnTools: LearnToolsEnum) {
    switch (learnTools) {
      case LearnToolsEnum.Content:
        this.selectedLearnTool = LearnToolsEnum.Content;
        break;
      case LearnToolsEnum.Whiteboard:
        this.selectedLearnTool = LearnToolsEnum.Whiteboard;
        break;
      case LearnToolsEnum.TextChat:
        this.selectedLearnTool = LearnToolsEnum.TextChat;
        this.newMessageReceived = false;
        break;
      case LearnToolsEnum.Notes:
        this.selectedLearnTool = LearnToolsEnum.Notes;
        break;
      case LearnToolsEnum.FileAttachment:
        this.selectedLearnTool = LearnToolsEnum.FileAttachment;
        break;
      case LearnToolsEnum.Live:
        this.selectedLearnTool = LearnToolsEnum.Live;
        break;
    }
  }
  selectedTool(learnTools: LearnToolsEnum) {
    if (learnTools == this.selectedLearnTool) {
      return true;
    } else {
      return false;
    }
  }
  fullscreenListener() {
    document.addEventListener('fullscreenchange', (ev) => {
      if (document.fullscreenElement) {
        this.fullscreen = true;
      } else {
        this.fullscreen = false;
      }
    })
  }

  popOut(selectedLearnTool: number) {
    let changeWindow: boolean = false;
    let pageStyles = document.getElementsByTagName("style");

    let win = window.open("/popout", "", "width=900, height=800") ?? new Window(); //about:blank sayfası açarsak scriptler ve stiller yüklenmiyor. o yüzden /popout adında boş bir sayfa oluşturdum. İçerikleri buraya ekleyeceğim

    let cnt = selectedLearnTool == 5 ? this.streamContentEl : document.getElementsByClassName("content_" + selectedLearnTool)[0];
    win.addEventListener("load", () => {
      setTimeout(() => {
        if (selectedLearnTool == 0) {
          this.courseContentWindow = win;
        }
        if (selectedLearnTool == 1) {
          this.whiteboardWindow = win;
        }
        if (selectedLearnTool == 2) {
          this.chatWindow = [win];
        }
        if (selectedLearnTool == 3) {
          this.noteWindow = win;
        }
        if (selectedLearnTool == 4) {
          this.fileAttachmentWindow = win;
        }
        if (selectedLearnTool == 5) {
          this.streamWindow = win;
        }
        win.document.body.append(cnt);
        for (let index = 0; index < pageStyles.length; index++) {
          const sty = pageStyles[index];
          win.document.head.append(sty.cloneNode(true))

        }
      }, 500)
    })
    win.addEventListener("unload", () => {
      document.getElementsByClassName("contentDiv_" + selectedLearnTool)[0].append(cnt)
      if (changeWindow && selectedLearnTool == 0) {
        this.courseContentWindow = window;
      }
      if (changeWindow && selectedLearnTool == 1) {
        this.whiteboardWindow = window;
      }
      if (changeWindow && selectedLearnTool == 2) {
        this.chatWindow = [window];
      }
      if (changeWindow && selectedLearnTool == 3) {
        this.noteWindow = window;
      }
      if (changeWindow && selectedLearnTool == 4) {
        this.fileAttachmentWindow = window;
      }
      if (changeWindow && selectedLearnTool == 5) {
        this.streamWindow = window;
      }
      changeWindow = true;

    })
  }


  messageToast(ev: any) {
    let privateMessageNotificationStorage = localStorage.getItem("privateMessageNotification");
    let messageNotificationStorage = localStorage.getItem("messageNotification");
    console.log(ev);
    if (sessionStorage.getItem("username") == ev.data.name) {
      return;
    }
    if (this.selectedLearnTool != LearnToolsEnum.TextChat) {
      this.newMessageReceived = true;
    }
    switch (ev.type) {
      case "message":
        console.log(ev.data);
        if(messageNotificationStorage == "active"){
          this.toastrService.info(ev.data.message,ev.data.name + " ( Yeni Mesaj )");
        }
        break;
      case "privateMessage":
        console.log(ev.data);
        if(privateMessageNotificationStorage == "active"){
          this.toastrService.info("Özel mesaj alındı");
        }
        break;
    }
  }

  requestFullscreen() {
    let content = document.getElementsByClassName("learnMain")[0] as HTMLDivElement;
    let body = document.body.style;
    console.log(body)
    content.style.background = "#efefef";
    content.requestFullscreen()
    this.fullscreen = true;
  }
  closeFullscreen() {
    document.exitFullscreen();
    this.fullscreen = false;
  }
  readHtmlFromSummernote() {
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let code = $(editNoteContent).summernote('code') as string;
    console.log(code);
  }
  saveNote() {
    let noteTitleElement = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    let noteTitle = noteTitleElement.value;
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let noteContent = $(editNoteContent).summernote('code') as string;
    let noteId = this.noteEditId ?? "";
    if (noteId == "") {
      this.toastrService.error("Not güncellenemedi");
      return;
    };
    let updateNoteModel: UpdateNoteModel = { title: noteTitle, noteContent: noteContent, id: noteId };
    console.log(updateNoteModel);
    this.learnService.updateNote(updateNoteModel, this.courseId).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrService.success("Not başarıyla eklendi");
          this.destroySummerNote();
        } else {
          this.toastrService.error("Not eklenirken bir hata oluştu");
        }
      },
      error: (err) => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
    this.destroySummerNote();
  }
  createNote() {
    let noteTitleElement = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    let contentId = this.learnContent.currentContent.id;
    let noteTitle = noteTitleElement.value;
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let noteContent = $(editNoteContent).summernote('code') as string;
    let createNoteModel: CreateNoteModel = { contentId: contentId, noteContent: noteContent, title: noteTitle };
    console.log(createNoteModel);
    this.learnService.createNote(createNoteModel, this.courseId).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrService.success("Not başarıyla eklendi");
          this.destroySummerNote();
        } else {
          this.toastrService.error("Not eklenirken bir hata oluştu");
        }
      },
      error: (err) => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  private destroySummerNote() {
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote('destroy');
    this.editMode = false;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = ""
    editNoteTitle.value = "";
  }
  editNote(note: StudentNoteModel) {
    this.editMode = true;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = note.noteContent;
    editNoteTitle.value = note.title ?? "";
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote({ height: 200 });
    this.noteEditId = note.id;
  }

  newNote() {
    this.noteEditId = undefined;
    this.editMode = true;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = ""
    editNoteTitle.value = "";
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote({ height: 200 });
  }

  deleteNote(courseId: string, noteId: string) {
    this.learnService.deleteNote(noteId, courseId).subscribe({
      next: (response) => {
        if (response.success) {
          this.toastrService.success("Not başarıyla silindi");
        } else {
          this.toastrService.error("Not silinemedi");
        }
      },
      error: (err) => {
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  cancelNote() {
    this.noteEditId = undefined;
    this.destroySummerNote();
  }
  guacamoleStart() {
    console.log("guacstart")
    let sessionId = this.learnContent.courseInfo.features.session.sessionId
    var model: RemoteClientSessionModel = { expiresIn: 36000, session: "" };
    this.guacamoleService.getGuacamoleSessionIdWithSessionName(sessionId).subscribe({
      next: (guacSession) => {
        model.session = guacSession.content.sessionId;
        this.guacamoleService.getGuacamoleSessionToken(model).subscribe((response) => {
          console.log(response);
          this.guacToken = response.content;
          this.guacamoleService.getMyMachines(response.content).subscribe((data) => {
            console.log("data");
            console.log(data);
            this.guacMachines = data.content;
          })
        })
      }, error: (err) => {
        this.toastrService.error("Uzak makineler alınırken bir sorun oluştu");
      }
    })

  }
  connect(vm: string, newWindow: boolean = false) {
    //this.router.navigate([']);
    let websocketUrl = this.learnContent.courseInfo.features.session.websocketUrl;
    let sessionId = this.learnContent.courseInfo.features.session.sessionId;
    let chatSocketUrl = this.learnContent.courseInfo.features.textchat.websocketUrl;
    let chatApiUrl = this.learnContent.courseInfo.features.textchat.apiUrl;
    let chatRoom = this.learnContent.courseInfo.features.textchat.room;
    let sessionData: { sessionId: string, sessionSocket: string, chatSocket: string, chatApi: string, chatRoom: string }
    sessionData = { sessionId: sessionId, sessionSocket: websocketUrl, chatApi: chatApiUrl ?? "", chatRoom: chatRoom ?? "", chatSocket: chatSocketUrl ?? "" };
    let sessionDataJson = JSON.stringify(sessionData);
    let connectKey = this.generateUUID();
    localStorage.setItem(connectKey, sessionDataJson)
    if (newWindow == false) {
      window.open("/remoteClient/" + this.guacToken + "/" + vm + "/" + connectKey, '_blank');
    } else {
      let connectWindow = window.open("/remoteClient/" + this.guacToken + "/" + vm + "/" + connectKey, connectKey, 'height=800px,width=1200px');
    }

  }
  generateUUID(): string { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  privateMessageNotificationChanged(ev:Event){
    let el = ev.target as HTMLInputElement
    if(el.checked){
      localStorage.setItem("privateMessageNotification","active");
    }else{
      localStorage.removeItem("privateMessageNotification");
    }
  }
  messageNotificationChanged(ev:Event){
    let el = ev.target as HTMLInputElement
    if(el.checked){
      localStorage.setItem("messageNotification","active");
    }else{
      localStorage.removeItem("messageNotification");
    }
  }
  messageNotification(){
    let messageCheck = document.getElementById("notifyMessageCheck") as HTMLInputElement;
    let privateMessageCheck = document.getElementById("notifyPrivateMessageCheck") as HTMLInputElement;
    let privateMessageNotificationStorage = localStorage.getItem("privateMessageNotification");
    let messageNotificationStorage = localStorage.getItem("messageNotification");
    if(privateMessageNotificationStorage == "active"){
      privateMessageCheck.checked = true;
    }
    if(messageNotificationStorage == "active"){
      messageCheck.checked = true;
    }
  }


}
