import { TenantInfoModel } from './../../../../../usorta-library/src/lib/models/TenantModels/tenantInfoModel';
import { FormResponseObject } from './../../../../../usorta-library/src/lib/models/CustomFormModels/formResponseObject';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-form',
  templateUrl: './student-form.component.html',
  styleUrls: ['./student-form.component.css']
})

export class StudentFormComponent implements OnInit {
  tenantInfoModel:TenantInfoModel;
  constructor() { }

  ngOnInit(): void {
    this.getTenantInfo();
  }
  getTenantInfo(){
    var tenantInfo = localStorage.getItem("tenantInfo");
    if(tenantInfo){
      this.tenantInfoModel = JSON.parse(tenantInfo).applicationConfiguration;
      console.log(this.tenantInfoModel)
    }
  }

}
