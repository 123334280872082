import { NotificationItem } from './../../../../usorta-library/src/lib/models/NotificationModels/notificationItem';
import { ApiUrlService } from './../../../../usorta-library/src/lib/services/api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { AllNotificationsModel } from 'projects/usorta-library/src/lib/models/NotificationModels/allNotifications';
import { NotificationItemDetails } from 'projects/usorta-library/src/lib/models/NotificationModels/notificationItemDetails';

@Injectable({
  providedIn: 'root'
})
export class NotificationTestService implements NotificationService{

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  public getNotificationDetails(notificationId: string): Observable<NotificationItemDetails>
  {
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<NotificationItemDetails>(apiUrl+"notifications/"+notificationId);
  }
  public getAllNotifications(page: number): Observable<AllNotificationsModel>
  {
    let apiurl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<AllNotificationsModel>(apiurl+"notifications/my/all/"+page);
  }
  public snoozeNotification(notificationItem: NotificationItem): Observable<any>
  {
    let apiurl = this.apiUrlService.getApiUrl();
    return this.httpClient.post(apiurl+"notifications/"+notificationItem.id+"/snooze",null);
  }
  public deleteNotification(notificationItem: NotificationItem): Observable<any>
  {
    let apiurl = this.apiUrlService.getApiUrl();
    return this.httpClient.post(apiurl+"notifications/"+notificationItem.id+"/delete",null);
  }
  public getMyNotifications(): Observable<NotificationItem[]>
  {
    let apiurl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<NotificationItem[]>(apiurl+"notifications/my");
  }
}
