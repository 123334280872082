import { Injectable } from '@angular/core';
import { SendPrivateMessageDto } from '@usorta-packages/chat-library/lib/models/sendPrivateMessageDto';
import * as SignalR from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ReceivedPrivateMessageModel } from '@usorta-packages/chat-library/lib/models/receivedPrivateMessageModel';

import { HttpClient } from '@angular/common/http';
import { TerminalSignalRChatService } from '../terminalSignalRChat/terminal-signal-rchat.service';
import { TerminalChatApiService } from '../terminalChatApiService/terminal-chat-api.service';
@Injectable({
  providedIn: 'root'
})
export class TerminalChatService {

  constructor(private signalRChat:TerminalSignalRChatService,private chatServiceApi:TerminalChatApiService,private toastrService:ToastrService,private httpClient:HttpClient) { }

  private hubConnection:SignalR.HubConnection;
  private wsUrl:string;
  private room:string;
  private apiUrl:string;
  initializeTerminalChat(wsUrl:string,room:string,apiUrl:string){
    this.chatServiceApi.setApiUrl(apiUrl);
    this.signalRChat.startWebsocket(wsUrl,room);
    this.wsUrl = wsUrl;
    this.room = room;
    this.hubConnection = this.signalRChat.getHubConnection() as unknown as SignalR.HubConnection;

  }
  sendMessageToUser(receiverId:string,messageText:string,resend:boolean=false):Promise<boolean>{
    return new Promise((resolve,reject)=>{
        let privateMessageDto:SendPrivateMessageDto = {sender:"",receiver:receiverId,message:messageText};
        this.chatServiceApi.sendPrivateMessage(privateMessageDto).subscribe({
          next:(success)=>{
            if(success){
              resolve(true);
            }else{
              if(resend){
                this.toastrService.error("Mesaj gönderilemedi");
                resolve(false);
              }else{
                this.toastrService.info("Tekrar bağlanıyor");
                this.signalRChat.startWebsocket(this.wsUrl,this.room).then(x=>{
                  setTimeout(()=>{
                    this.hubConnection = this.signalRChat.getHubConnection() as unknown as SignalR.HubConnection;
                  },500)
                  setTimeout(()=>{
                    resolve(this.sendMessageToUser(receiverId,messageText,true));
                  },1000)

                })

              }

            }
          },error:(err)=>{
            if(resend){
              this.toastrService.error("Mesaj gönderilemedi");
              resolve(false);
            }else{
              this.toastrService.info("Tekrar bağlanıyor");
              this.signalRChat.startWebsocket(this.wsUrl,this.room).then(x=>{
                setTimeout(()=>{
                  console.log("hubconnection ataması")
                  this.hubConnection = this.signalRChat.getHubConnection() as unknown as SignalR.HubConnection;
                },500)
                setTimeout(()=>{
                  console.log("mesaj gonderimi")
                  resolve(this.sendMessageToUser(receiverId,messageText,true));
                },1000)

              })

            }
          }
        });
    })
  }
  receivePrivateMessages = new Observable<ReceivedPrivateMessageModel>((observer)=>{
    this.hubConnection.on("ReceivedPrivateMessage",(privateMessageModel:ReceivedPrivateMessageModel)=>{
      console.log("mesaj");
      observer.next(privateMessageModel);
    })
  })
}
