import { CourseItemModel } from './../../../../../usorta-library/src/lib/models/courseItemModel';
import { CourseService } from 'projects/usorta-library/src/lib/services/course/course.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  queryUrl:string = "courses/coursecatalog";
  constructor(private courseService:CourseService) { }
  courses:CourseItemModel[];
  ngOnInit(): void {
    console.log('asd'+this.queryUrl)
  }

}
