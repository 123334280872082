<div class="courseCatalogMain">
  <div class="courseCatalogHeader">
    <div class="courseCatalogHeaderTitle">
      <h4>{{courseCatalogTitle}}</h4>
    </div>
    <div class="courseCatalogHeaderDescription">
      <font>{{courseCatalogDescription}}</font>
    </div>
    <hr>
  </div>
  <div class="courseCatalogFilter">

  </div>
  <div *ngIf="!courseList" class="courseCatalogList">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">...</span>
    </div>
  </div>
  <div *ngIf="courseList" class="courseCatalogList">
    <app-vertical-course-item *ngFor="let course of courseList" [course]="course"></app-vertical-course-item>
    </div>
</div>
