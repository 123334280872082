import { TenantInfoModel } from './../../../../../../usorta-library/src/lib/models/TenantModels/tenantInfoModel';
import { CourseItemModel } from './../../../../../../usorta-library/src/lib/models/courseItemModel';
import { CourseDetails } from './../../../../../../usorta-library/src/lib/models/courseDetails';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { CmsImageService } from 'projects/usorta-library/src/lib/services/cmsImages/cms-image.service';

@Component({
  selector: 'app-horizontal-course-item',
  templateUrl: './horizontal-course-item.component.html',
  styleUrls: ['./horizontal-course-item.component.css']
})
export class HorizontalCourseItemComponent implements OnInit {
  @Input() course:CourseItemModel;
  @Input() key:string;
  apiUrl = environment.apiUrl;
  constructor(private cmsImageService:CmsImageService) { }
  siteInfo:TenantInfoModel;
  defaultImage:string;

  ngOnInit(): void {
    let tenantInfo = localStorage.getItem('tenantInfo');
    if(tenantInfo){
      this.siteInfo = JSON.parse(tenantInfo).applicationConfiguration;
      this.defaultImage = this.cmsImageService.getUrl(this.siteInfo.defaultCourseImage);
    }
  }
  

}
