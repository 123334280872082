
<div class="formMain form-default-border form-maroon-border">
    <div class="form-title-div">
        <font class="form-title-content">{{'courseManagement.updateSection' | translate}}</font>
    </div>
<div *ngIf="currentSection && sectionsList">
<div class="mb-3">
    <label class="mainLabel">Ana Bölüm</label>
    <select [value]="currentSection.parentId" class="form-select" id="parentId">
        <option [selected]="currentSection.parentId == null" [value]=null>Bu bölümü ana bölüm olarak ayarla</option>
        <option [selected]="currentSection.parentId == section.value" *ngFor="let section of sectionsList" [value]="section.value">{{section.label}}</option>
    </select>
</div>
<div class="mb-3">
    <label for="sectionName" class="form-label mainLabel">{{'courseManagement.subsectionName' | translate}}*</label>
    <input type="text" class="form-control" [value]="currentSection.name" id="sectionName" name="sectionName" placeholder="">
</div>

<div class="mb-3">
    <label for="preIcon" class="form-label mainLabel">{{'coureManagement.preIcon' | translate}}</label>
    <select [value]="currentSection.icon" id="preIcon" class="form-select">
        <option [selected]="currentSection.icon == 0" [value]="0">Section</option>
        <option [selected]="currentSection.icon == 1" [value]="1">Page</option>
        <option [selected]="currentSection.icon == 2" [value]="2">File</option>
        <option [selected]="currentSection.icon == 3" [value]="3">Video</option>
        <option [selected]="currentSection.icon == 4" [value]="4">Image</option>
        <option [selected]="currentSection.icon == 5" [value]="5">Audio</option>
        <option [selected]="currentSection.icon == 6" [value]="6">SlideShow</option>
        <option [selected]="currentSection.icon == 7" [value]="7">Assessment</option>
        <option [selected]="currentSection.icon == 8" [value]="8">Survey</option>
    </select>
</div>
<div class="mb-3">
    <label class="form-label mainLabel"><input type="checkbox" class="optionsClass" [value]="0"/> None</label><br/>
    <label class="form-label mainLabel"><input type="checkbox" class="optionsClass" [value]="1"/> Milestone</label><br/>
    <label class="form-label mainLabel"><input type="checkbox" class="optionsClass" [value]="2"/> Optional</label><br/>
</div>
<div class="mb-3">
    <label class="form-label mainLabel">Sıralama Değeri</label>
    <input [value]="currentSection.orderValue" class="form-control" type="number" id="orderValue" min="0"/>
</div>
<div class="mb-3">
    <label for="preIcon" class="form-label mainLabel">Durum</label>
    <select [value]="currentSection.status" id="status" class="form-select">
        <option [selected]="currentSection.status == 0" [value]="0">Draft</option>
        <option [selected]="currentSection.status == 1" [value]="1">Published</option>
        <option [selected]="currentSection.status == 2" [value]="2">Editing</option>
    </select>
</div>
<div style="text-align: right;">
    <button [disabled]="loading" class="btn  btn-sm btn-primary" (click)="updateSection()">{{'btn.add' | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
</div>

</div>
</div>